import React, { useEffect, useState } from "react";

import { AnimatePresence, motion } from 'framer-motion';
import MenuSVGIcon from '../../assets/images/svg-icons/menu.svg';
import {
  Link,
  Redirect
} from "react-router-dom";
import useOnclickOutside from "react-cool-onclickoutside";
import api from '../../services/api';
import { useSelector, useDispatch } from 'react-redux';
import { logoutAction } from '../../store/modules/auth/actions';
import { setName, setLogo } from '../../store/modules/data/actions';
import { FaLock } from 'react-icons/fa';
import { CgLogOut } from 'react-icons/cg';
import { BiMenuAltRight } from 'react-icons/bi';

export default function Component({notificationsBar, openNotifications}) {
    const token = useSelector(state => state.auth.token);

    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true);

    const dispatch = useDispatch();

    useEffect(() => {
        api.post(api.defaults.baseURL + '/user_logs/notifications/' + token).then(res => {
            if(res){
                setNotifications(res.data);
                setLoading(false);
            }
        });
        openNotifications(false);
    }, [])

    const ref = useOnclickOutside(() => {
        openNotifications(false);
    });

    const notificationBarAnimation ={
        open: {
            opacity: 1,
            x: 0,
            transition: 'linear',
            duration: 0.3,
        },
        closed: {
            opacity: 0,
            x: 320,
            transition: 'linear',
            duration: 0.5,
        },
    }

    const notificationEnterAnimation = {
        in: index => ({
            opacity: 1,
            y: 0,
            transition: { type: 'linear', delay: index * 0.1 },
        }),
        out: index => ({
            opacity: 0,
            y: 20,
            transition: { type: 'linear', delay: index * 0.05 },
        }),
    }

    const [loggedOut, setLoggedOut] = useState(false);

    function logout(){
        dispatch(logoutAction());
        dispatch(setName(null));
        dispatch(setLogo(null));
        setLoggedOut(true);
    }

    return (
        <>
        {
            loggedOut &&
            <Redirect to="/"/>
        }
        <motion.div 
            className="c-sidebar c-sidebar-lg c-sidebar-light c-sidebar-right c-sidebar-overlaid" 
            id="aside"
            initial={false}
            animate={notificationsBar ? "open" : "closed"}
            variants={notificationBarAnimation} 
            ref={ref}
        >
            <div id="notification" role="tabpanel">
                <div>
                    <div className="d-flex justify-content-between p-3 align-items-center">
                        <div>
                            <h2 id="sidebar-user-name" className="mb-0">Microsoft</h2>
                        </div>
                        <button className="c-header-toggler c-className-toggler mfe-md-3 d-flex align-items-center justify-content-center" onClick={() => openNotifications(false)}>
                            <BiMenuAltRight size="30" color="white"/>
                        </button>
                    </div>
                    <div className="d-flex justify-content-center align-items-center pt-4 pb-2 notification-title">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18.931" height="20.808" viewBox="0 0 18.931 20.808" className="mr-2">
                            <g id="Icon_feather-bell" data-name="Icon feather-bell" transform="translate(-3.5 -2)">
                                <path id="Path_212" data-name="Path 212" d="M18.609,8.644a5.644,5.644,0,1,0-11.287,0c0,6.584-2.822,8.465-2.822,8.465H21.431s-2.822-1.881-2.822-8.465" fill="none" stroke="#33323d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                                <path id="Path_213" data-name="Path 213" d="M18.659,31.5a1.881,1.881,0,0,1-3.254,0" transform="translate(-4.067 -10.629)" fill="none" stroke="#33323d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                            </g>
                        </svg> Notifications
                    </div>
                </div>
                {
                    loading ?
                    <div className="text-center">
                        <div className="spinner-border text-primary ml-3 my-3 disable" id="loadingNotifications" role="status"><span className="sr-only">Loading...</span></div>
                    </div>
                    :
                    <div id="notification-items">
                        {
                            notifications.map((notification, index) => (
                                <motion.div
                                    initial="out"
                                    animate="in"
                                    exit="out"
                                    variants={notificationEnterAnimation}
                                    custom={index}
                                    className="message"
                                    key={'notification-'+index}
                                >
                                    <div>
                                        <small className="text-muted notification-date float-center mt-1"> {notification.date} </small>
                                    </div>
                                    <small className="text-muted notification-description"> {notification.name} </small>
                                </motion.div>
                            ))
                        }
                    </div>
                }
                <div id="notification-actions" className="d-flex flex-column align-items-center">
                    <Link to="/dashboard/profile" className="change-password-notification-button d-flex align-items-center sidebarButton" onClick={() => openNotifications(false)}>
                        <FaLock style={{marginRight: 5}}/>
                        Change password
                    </Link>
                    <button className="logout-notification-button d-flex align-items-center sidebarButton" onClick={logout}>
                        <CgLogOut style={{marginRight: 5}}/>
                        Logout
                    </button>
                </div>
            </div>
        </motion.div>
        </>
    );
}