import { combineReducers } from 'redux';
import auth from './auth/reducer';
import data from './data/reducer';
import permissions from './permissions/reducer';
import layout from './layout/reducer';

export default combineReducers({
	auth,
	data,
	permissions,
	layout,
});