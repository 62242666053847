import React, { useEffect, useState } from "react";

import { motion } from 'framer-motion';

export default function Component(props) {

    useEffect(() => {
    }, []);

    const animation = {
        in: {
            opacity: 1,
            y: 0,
            transition: { type: 'linear', delay: props.order * 0.1 },
        },
        out: {
            opacity: 0,
            y: 100,
            transition: { type: 'linear', delay: 0.2 / props.order },
        },
    }

    function nFormatter(num, digits) {
        var si = [
          { value: 1, symbol: "" },
          { value: 1E3, symbol: "k" },
          { value: 1E6, symbol: "M" },
          { value: 1E9, symbol: "G" },
          { value: 1E12, symbol: "T" },
          { value: 1E15, symbol: "P" },
          { value: 1E18, symbol: "E" }
        ];
        var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        var i;
        for (i = si.length - 1; i > 0; i--) {
          if (num >= si[i].value) {
            break;
          }
        }
        return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
    }

    return (
        <motion.div 
            initial="out"
            animate="in"
            exit="out"
            variants={animation} 
            className="card social-stats-card"
            id={props.id}
        >
            <div className="card-header">
                <div className={{
                'facebook': 'social-stats-card-icon social-starts-card-icon-bg-facebook',
                'twitter': 'social-stats-card-icon social-starts-card-icon-bg-twitter',
                'pinterest': 'social-stats-card-icon social-starts-card-icon-bg-pinterest',
                'youtube': 'social-stats-card-icon social-starts-card-icon-bg-youtube',
                'instagram': 'social-stats-card-icon social-starts-card-icon-bg-instagram',
            }[props.data.media]}>
                    <i className={{
                        'facebook': 'fab fa-facebook',
                        'twitter': 'fab fa-twitter',
                        'pinterest': 'fab fa-pinterest',
                        'youtube': 'fab fa-youtube',
                        'instagram': 'fab fa-instagram',
                    }[props.data.media]} aria-hidden="true"></i>
                </div>
            </div>
            <div className="social-stats-card-body">
                <div className="social-stats-card-grid">
                    <div className="social-stats-card-item">
                        <div className="social-stats-card-value" id="qty_twitter_followers">{nFormatter(props.data.data1, 2)}</div>
                        <span className="social-stats-card-text">{props.data.label1}</span>
                    </div>
                    <div className="social-stats-card-item">
                        <div className="social-stats-card-value" id="qty_twitter_posts">{nFormatter(props.data.data2, 2)}</div>
                        <span className="social-stats-card-text">{props.data.label2}</span>
                    </div>
                    <div className="social-stats-card-item">
                        <div className="social-stats-card-value" id="qty_twitter_likes">{nFormatter(props.data.data3, 2)}</div>
                        <span className="social-stats-card-text">{props.data.label3}</span>
                    </div>
                    <div className="social-stats-card-item">
                        <div className="social-stats-card-value" id="qty_twitter_comments">{nFormatter(props.data.data4, 2)}</div>
                        <span className="social-stats-card-text">{props.data.label4}</span>
                    </div>
                </div>
                    <div className={{
                        'facebook': 'social-stats-card-username social-stats-card-username-facebook',
                        'twitter': 'social-stats-card-username social-stats-card-username-twitter',
                        'pinterest': 'social-stats-card-username social-stats-card-username-pinterest',
                        'youtube': 'social-stats-card-username social-stats-card-username-youtube',
                        'instagram': 'social-stats-card-username social-stats-card-username-instagram',
                    }[props.data.media]} aria-hidden="true">All accounts</div>
            </div>
        </motion.div>
    );
}