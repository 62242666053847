import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import api from '../../../services/api';
import { AnimatePresence, motion } from 'framer-motion';
import $ from 'jquery';
import DashboardPageLayout from '../../../layouts/dashboard-page';
import FeedPageIMG from '../../../assets/images/pages/feed-page.png';
import GridPageIMG from '../../../assets/images/pages/grid-page.png';
import InstagramPageIMG from '../../../assets/images/pages/instagram-page.png';
import MansoryPageIMG from '../../../assets/images/pages/mansory-page.png';
import NewsPageIMG from '../../../assets/images/pages/news-page.png';
import SlidePageIMG from '../../../assets/images/pages/slide-page.png';
import Header from '../../../components/header';
import { Heading } from '@chakra-ui/react';

export default function Posts(props){

    const token = useSelector(state => state.auth.token);

    const [feed, setFeed] = useState();
    const [instagram, setInstagram] = useState();
    const [masonry, setMasonry] = useState();
    const [news, setNews] = useState();
    const [slide, setSlide] = useState();
    const [grid, setGrid] = useState();

    useEffect(() => {
        api.post(api.defaults.baseURL + '/grid_pages/get/' + token).then(res => {
            setFeed('https://' + res.data.url + '/feed');
            setGrid('https://' + res.data.url);
            setInstagram('https://' + res.data.url + '/instagram');
            setMasonry('https://' + res.data.url + '/masonry');
            setSlide('https://' + res.data.url + '/slide');
            setNews('https://' + res.data.url + '/news');
        });
    }, []);

    $(document).on('click', '.page-item-link', function() {
        var $temp = $("<input>");
        $("body").append($temp);
        $temp.val($(this).find('.page-item-link-text').text()).select();
        document.execCommand("copy");
        $temp.remove();
    
        $(this).find('.fas').removeClass('fa-link').addClass('fa-check');
    
        var span = $(this).find('.page-item-link-icon');
        setTimeout(() => { 
            $(this).find('.fas').removeClass('fa-check').addClass('fa-link');
        }, 2000);
    });

    return (
        <>
        <Header left={
            <Heading fontWeight="700" fontFamily="poppins">Pages</Heading>
        }/>
        <DashboardPageLayout title={'Your social media pages'} headerColor={'yellow-header'}>
            <div className="pages-grid">
                <div className="page-item " style={{padding: 15}}>
                    <img src={SlidePageIMG} className="img-fluid"/>
                    <div className="d-flex flex-column">
                        <div className="page-item-header">
                            <h2 className="p-0 m-0">Slide Page</h2>
                            <a href={slide} target="_blank">View Page</a>
                        </div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In elementum urna eu arcu iaculis tincidunt. </p>
                        <button type="button" className="page-item-link"><span className="page-item-link-text">{slide}</span><span className="page-item-link-icon"><i className="fas fa-link" aria-hidden="true"></i></span></button>
                    </div>
                </div>
                <div className="page-item " style={{padding: 15}}>
                    <img src={MansoryPageIMG} className="img-fluid"/>
                    <div className="d-flex flex-column">
                        <div className="page-item-header">
                        <h2 className="p-0 m-0">Masonry Page</h2>
                        <a href={masonry} target="_blank">View Page</a>
                        </div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In elementum urna eu arcu iaculis tincidunt. </p>
                        <button type="button" className="page-item-link"><span className="page-item-link-text">{masonry}</span><span className="page-item-link-icon"><i className="fas fa-link" aria-hidden="true"></i></span></button>
                    </div>
                </div>
                <div className="page-item " style={{padding: 15}}>
                    <img src={FeedPageIMG} className="img-fluid"/>
                    <div className="d-flex flex-column">
                        <div className="page-item-header">
                        <h2 className="p-0 m-0">Feed Page</h2>
                        <a href={feed} target="_blank">View Page</a>
                        </div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In elementum urna eu arcu iaculis tincidunt. </p>
                        <button type="button" className="page-item-link"><span className="page-item-link-text">{feed}</span><span className="page-item-link-icon"><i className="fas fa-link" aria-hidden="true"></i></span></button>
                    </div>
                </div>
                <div className="page-item " style={{padding: 15}}>
                    <img src={GridPageIMG} className="img-fluid"/>
                    <div className="d-flex flex-column">
                        <div className="page-item-header">
                            <h2 className="p-0 m-0">Grid Page</h2>
                            <a href={grid} target="_blank">Grid Page</a>
                        </div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In elementum urna eu arcu iaculis tincidunt. </p>
                        <button type="button" className="page-item-link"><span className="page-item-link-text">{grid}</span><span className="page-item-link-icon"><i className="fas fa-link" aria-hidden="true"></i></span></button>
                    </div>
                </div>
                <div className="page-item " style={{padding: 15}}>
                    <img src={InstagramPageIMG} className="img-fluid"/>
                    <div className="d-flex flex-column">
                        <div className="page-item-header">
                        <h2 className="p-0 m-0">Instagram Page</h2>
                        <a href={instagram} target="_blank">View Page</a>
                        </div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In elementum urna eu arcu iaculis tincidunt. </p>
                        <button type="button" className="page-item-link"><span className="page-item-link-text">{instagram}</span><span className="page-item-link-icon"><i className="fas fa-link" aria-hidden="true"></i></span></button>
                    </div>
                </div>
                <div className="page-item " style={{padding: 15}}>
                    <img src={NewsPageIMG} className="img-fluid"/>
                    <div className="d-flex flex-column">
                        <div className="page-item-header">
                        <h2 className="p-0 m-0">News Page</h2>
                        <a href={news} target="_blank">View Page</a>
                        </div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In elementum urna eu arcu iaculis tincidunt. </p>
                        <button type="button" className="page-item-link"><span className="page-item-link-text">{news}</span><span className="page-item-link-icon"><i className="fas fa-link" aria-hidden="true"></i></span></button>
                    </div>
                </div>
            </div>
        </DashboardPageLayout>
        </>
    );
}