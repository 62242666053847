import React, { useEffect, useState } from "react";
//import axios from 'axios';
import { useSelector } from "react-redux";
import api from "../../services/api";
import { motion } from "framer-motion";

export default function PostBlog(props) {
     useEffect(() => {}, []);

     const animation = {
          in: (order) => ({
               opacity: 1,
               y: 0,
               transition: { type: "linear", delay: order * 0.1 },
          }),
          out: (order) => ({
               opacity: 0,
               y: 50,
               transition: { type: "linear", delay: order * 0.01 },
          }),
     };

     return (
          <motion.div
               initial="out"
               animate="in"
               exit="out"
               variants={animation}
               custom={props.order}
               className="social-media-post"
          >
               <div
                    className={
                         {
                              1: "leads-page-card",
                              2: "leads-page-card leads-page-card-approved",
                              3: "leads-page-card leads-page-card-refused",
                              4: "leads-page-card leads-page-card-on-hold",
                         }[props.post.status_post_id]
                    }
                    style={{ paddingBottom: 10 }}
               >
                    <div className="mt-2 post-blog-image">
                         <img src={props.post.picture} />
                         <button
                              className="post-view-button"
                              onClick={() => props.openPost(props.post)}
                         >
                              View post <i className="far fa-eye ml-2"></i>
                         </button>
                    </div>
                    <div className="mt-3 d-flex justify-content-between">
                         <div className="d-flex">
                              <button
                                   onClick={() =>
                                        props.changeStatus(props.post.id, 4)
                                   }
                                   className={
                                        "mr-1 post-blog-button post-hold-button " +
                                        (props.post.status_post_id == 4
                                             ? "post-holded-button"
                                             : "")
                                   }
                                   disabled={
                                        false &&
                                        props.post.status_post_id != 1 &&
                                        props.post.status_post_id != 4
                                   }
                              >
                                   <i
                                        className="fas fa-spinner"
                                        aria-hidden="true"
                                   ></i>
                              </button>
                              <button
                                   onClick={() =>
                                        props.changeStatus(props.post.id, 2)
                                   }
                                   className={
                                        "mr-1 post-blog-button post-approve-button " +
                                        (props.post.status_post_id == 2
                                             ? "post-approved-button"
                                             : "")
                                   }
                                   disabled={
                                        false &&
                                        props.post.status_post_id != 1 &&
                                        props.post.status_post_id != 4
                                   }
                              >
                                   <i
                                        className="fas fa-check"
                                        aria-hidden="true"
                                   ></i>
                              </button>
                              <button
                                   onClick={() =>
                                        props.changeStatus(props.post.id, 3)
                                   }
                                   className={
                                        "post-blog-button post-refuse-button " +
                                        (props.post.status_post_id == 3
                                             ? "post-refused-button"
                                             : "")
                                   }
                                   disabled={
                                        false &&
                                        props.post.status_post_id != 1 &&
                                        props.post.status_post_id != 4
                                   }
                              >
                                   <i className="fas fa-times"></i>
                              </button>
                         </div>
                         <div>
                              <button
                                   className="post-comment-button"
                                   onClick={() => props.openPost(props.post)}
                              >
                                   Comment{" "}
                                   <i className="far fa-comment ml-2"></i>
                              </button>
                         </div>
                    </div>
               </div>
          </motion.div>
     );
}
