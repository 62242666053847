import React, { useEffect, useState } from "react";
import DashboardPageLayout from '../../../layouts/dashboard-page';
import api from '../../../services/api';
import { useSelector, useDispatch } from 'react-redux';
import { motion } from 'framer-motion';
import Lightbox from '../../../components/archiving-light-box';
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from 'react-loading-skeleton';
import Header from '../../../components/header';
import { Heading } from '@chakra-ui/react';

export default function View(props) {

    const [loading, setLoading] = useState(true);

    const token = useSelector(state => state.auth.token);

    const [files, setFiles] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [limitPage, setLimitPage] = useState(1);
    const dispatch = useDispatch();
    
    useEffect(() => {
        fetchData();
    }, []);

    function fetchData(){
        api.post(api.defaults.baseURL + '/vault/list_social/' + token, {
            page: currentPage,
        }).then(res => {
            if(res.data != 0 ){
                setFiles([...files, ...res.data.data]);
                setLoading(false);
                setCurrentPage(currentPage + 1);
                setLimitPage(res.data.page.limit);
            } else {
                setCurrentPage(limitPage + 1);
            }
        });
    }

    const animation = {
        in: order => ({
            opacity: 1,
            y: 0,
            transition: { type: 'linear', delay: 0 },
        }),
        out: order => ({
            opacity: 0,
            y: 20,
            transition: { type: 'linear', delay: 0 },
        }),
    }

    const [openFile, setOpenFile] = useState(false)

    return (
        <>
        <Header left={
            <Heading fontWeight="700" fontFamily="poppins">Social Media Archiving</Heading>
        }/>
        <Lightbox file={openFile} setOpenFile={setOpenFile}/>
        <DashboardPageLayout title={'Your Social Media Compliance is Up to Date'} headerColor={'yellow-header'}>
            
            <InfiniteScroll
                dataLength={files.length}
                next={fetchData}
                hasMore={currentPage < limitPage ? true : false}
                loader={
                    <div className="skeleton-wrapper">
                        <Skeleton width={'100%'} height={'100%'}/>
                    </div>
                }
                className="archiving-grid"
            >
                {
                    loading ?
                    Array.from({ length: 12 }).map(() => (
                        <div className="skeleton-wrapper">
                            <Skeleton width={'100%'} height={'100%'}/>
                        </div>
                    ))
                    :
                    files.map((file, index) => (
                        <motion.div 
                            initial="out"
                            animate="in"
                            exit="out"
                            variants={animation}
                            custom={index}
                            className="archiving-item"
                            onClick={() => setOpenFile(file)}
                            key={'archiving-file-'+index}
                        >
                            <div className="archiving-preview" style={{backgroundImage: `url(${file.preview})`}}></div>
                            <div className="archiving-functions">
                            </div>
                        </motion.div>
                    ))
                }
            </InfiniteScroll>
        </DashboardPageLayout>
        </>
    );
}