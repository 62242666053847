import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import api from '../../../services/api';
import { AnimatePresence, motion } from 'framer-motion';
import DashboardPageLayout from '../../../layouts/dashboard-page';
import Header from '../../../components/header';
import { Heading } from '@chakra-ui/react';

export default function Posts(props){

    const token = useSelector(state => state.auth.token);

    const [searchText, setSearchText] = useState();

    useEffect(() => {
        setActiveService(-1);
    }, [searchText])

    const [activeService, setActiveService] = useState(-1);
    const [services, setServices] = useState([]);

    useEffect(() => {
        api.post(api.defaults.baseURL + '/services/list/' + token).then(res => {
            if(res)
                setServices(res.data);
        });
    }, []);

    return (
        <>
        <Header left={
            <Heading fontWeight="700" fontFamily="poppins">Add-ons</Heading>
        }/>
        <DashboardPageLayout title={'Manage your add-ons'} headerColor={'purple-header'} subtitle={'List of services'}
            subContent={
                <div id="leads-page-search-form">
                    <input type="text" id="leads-page-search" placeholder="Search" onChange={(e) => setSearchText(e.target.value)}/>
                </div>
            }
        >
            <div className="services-grid mt-3">
                <div className="list">
                    {
                        services.map((service, index) => (
                            searchText != null ?
                            (
                                service.name.toUpperCase().includes(searchText.toUpperCase()) &&
                                <Service service={service} setActiveService={setActiveService} activeService={activeService} index={index}/>
                            )
                            :
                            <Service service={service} setActiveService={setActiveService} activeService={activeService} index={index}/>
                        ))
                    }
                </div>
                <div className="info">
                    <AnimatePresence exitBeforeEnter>
                        {
                            activeService == -1 &&
                            <div className="placeholder">
                                <div className="inner">
                                <svg xmlns="http://www.w3.org/2000/svg" width="81.887" height="107.667" viewBox="0 0 81.887 107.667">
                                    <path id="Icon_material-touch-app" data-name="Icon material-touch-app" d="M26.181,37.5V20.17c0-6.393,8.479-13.405,15.555-13.405S56.379,13.777,56.379,20.17V37.5c6.2-3.752,10.255-10.1,10.255-17.326C66.635,8.635,54.5-2.5,41.736-2.5S15.926,8.635,15.926,20.17C15.926,27.4,19.977,33.744,26.181,37.5ZM79.373,63.5,56.094,53.035a7.891,7.891,0,0,0-2.769-.51h-3.9V24.73c0-3.845-3.435-6.949-7.691-6.949s-7.691,3.1-7.691,6.949V74.483L16.457,71.148a9.876,9.876,0,0,0-1.231-.139,6.055,6.055,0,0,0-4.051,1.529L7.125,76.244l25.33,22.885a8.152,8.152,0,0,0,5.435,2.038H72.707a7.246,7.246,0,0,0,7.384-5.93l3.846-24.413a6.313,6.313,0,0,0,.1-.926A6.967,6.967,0,0,0,79.373,63.5Z" transform="translate(-4.152 4.5)" fill="none" stroke="#d4d5dd" stroke-width="4"/>
                                </svg>

                                    <div className="mt-3 text-center">SELECT THE SERVICE <br/>
                                    YOU WANT</div>
                                </div>
                            </div>
                        }
                        {
                            services.map((service, index) => (
                                <ServiceInfo service={service} activeService={activeService} index={index}/>
                            ))
                        }
                    </AnimatePresence>
                </div>
            </div>
            
        </DashboardPageLayout>
        </>
    );
}

function Service(props){
    const animation = {
        in: order => ({
            opacity: 1,
            x: 0,
            transition: { type: 'ease', delay: order * 0.1 },
        }),
        out: order => ({
            opacity: 0,
            x: 20,
            transition: { type: 'ease', delay: order * 0.1 },
        }),
    }

    return (
        <motion.div 
            initial="out"
            animate="in"
            exit="out"
            variants={animation}
            custom={props.index}
            className={`service ${props.index == props.activeService ? 'active' : ''}`} 
            key={'service-list'+props.service.id}
            onMouseEnter={() => props.setActiveService(props.index)}
        >
            <div className="image" style={{backgroundImage: `url(${props.service.picture})`}}>
            </div>
            <div className="content">
                <div className="topbar"></div>
                <h1>{props.service.name}</h1>
                <p>{props.service.description}</p>
            </div>
        </motion.div>
    )
}

function ServiceInfo(props){
    const animation = {
        in: order => ({
            opacity: 1,
            x: 0,
            transition: { type: 'linear' },
        }),
        out: order => ({
            opacity: 0,
            x: 20,
            transition: { type: 'linear' },
        }),
    }

    const priceAnimation = {
        in: {
            opacity: 1,
            y: 0,
            transition: { type: 'ease' },
        },
        out: {
            opacity: 0,
            y: 20,
            transition: { type: 'ease' },
        },
    }

    const [selectedPlan, selectPlan] = useState(0);

    const [period, setPeriod] = useState('year');

    return (
        props.activeService == props.index &&
        <motion.div 
            initial="out"
            animate="in"
            exit="out"
            variants={animation}
            className="service-info">
            <img src={props.service.picture}/>

            <div className="d-flex align-items-center justify-content-center">
                <div className="form-group form-inline period-change">
                    <input className="period-change" type="radio" id="year" name="plan_type" checked={period == 'year'} onClick={() => setPeriod('year')}/> <label for="year" className="period-change">Yearly</label>
                </div>
                <div className="form-group form-inline period-change">
                    <input className="period-change" type="radio" id="month" name="plan_type" checked={period == 'month'} onClick={() => setPeriod('month')}/> <label for="month" className="period-change">Monthly</label>
                </div>
            </div>

            <div className="plans-buttons mt-3">
                {
                    props.service.plans.map((plan, index) => (
                        <button onClick={() => selectPlan(index)} className={selectedPlan == index? 'active' : ''}>{plan.name}</button>
                    ))
                }
            </div>

            {
                props.service.plans &&
                props.service.plans.map((plan, index) => (
                    selectedPlan == index &&
                    <PlanContent plan={plan} key={'plan-content-'+index} period={period}/>
                ))
            }

            <p>{props.service.description}</p>

            <div className="cta">
                <button>Ask a Question</button>
                <button className="fill">Make an appointment</button>
            </div>
        </motion.div>
    )
}

function PlanContent({plan, period}){
    
    const animation = {
        in: order => ({
            opacity: 1,
            x: 0,
            transition: { type: 'ease' },
        }),
        out: order => ({
            opacity: 0,
            x: 20,
            transition: { type: 'ease' },
        }),
    }

    return (
        <motion.div
            initial="out"
            animate="in"
            exit="out"
            variants={animation}
        >

            <div className="text-center">
                <div className="price">
                        {
                            period == 'year' ?
                            <span>$290</span>
                            :
                            <span>$236</span>
                        }
                    /month
                </div>
            </div>

            <div className="plan-qtd">
                <div className="qtd">
                    <svg xmlns="http://www.w3.org/2000/svg" width="6.751" height="11.808" viewBox="0 0 6.751 11.808">
                        <path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward" d="M15.963,12.1,11.494,7.633a.84.84,0,0,1,0-1.192.851.851,0,0,1,1.2,0L17.752,11.5a.842.842,0,0,1,.025,1.164l-5.084,5.094a.844.844,0,1,1-1.2-1.192Z" transform="translate(-11.246 -6.196)"/>
                    </svg>
                    <span className="ml-2"><strong>15 Posts</strong> /month</span>
                </div>
                <div className="qtd">
                    <svg xmlns="http://www.w3.org/2000/svg" width="6.751" height="11.808" viewBox="0 0 6.751 11.808">
                        <path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward" d="M15.963,12.1,11.494,7.633a.84.84,0,0,1,0-1.192.851.851,0,0,1,1.2,0L17.752,11.5a.842.842,0,0,1,.025,1.164l-5.084,5.094a.844.844,0,1,1-1.2-1.192Z" transform="translate(-11.246 -6.196)"/>
                    </svg>
                    <span className="ml-2"><strong>3</strong> Social Media Accounts</span>
                </div>
            </div>
            <div className="benefits">
                {
                    plan.items &&
                    plan.items.map(benefit => (
                        <div className="benefit d-inline-flex align-items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                <g id="Group_343" data-name="Group 343" transform="translate(-1277 -889)">
                                    <circle id="Ellipse_212" data-name="Ellipse 212" cx="10" cy="10" r="10" transform="translate(1277 889)" fill="#ecebf9"/>
                                    <path id="Icon_awesome-check" data-name="Icon awesome-check" d="M4.212,13.643.182,9.613a.62.62,0,0,1,0-.877l.877-.877a.62.62,0,0,1,.877,0L4.65,10.574l5.815-5.815a.62.62,0,0,1,.877,0l.877.877a.62.62,0,0,1,0,.877l-7.13,7.13A.62.62,0,0,1,4.212,13.643Z" transform="translate(1280.6 890)" fill="#9188d8"/>
                                </g>
                            </svg>
                            <span className="ml-1">{benefit.name}</span>
                        </div>
                    ))
                }
                <div className="adds">
                    <div>
                        Add the Ads <strong>+$200</strong>
                    </div>
                    <div>
                        Number of Boost up to 8 posts
                    </div>
                </div>
            </div>
        </motion.div>
    )
}