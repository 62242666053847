import React, { useEffect, useState } from "react";
import {
  Link,
  Redirect
} from "react-router-dom";

import { motion } from 'framer-motion';

export default function Layout(props) {

    const [loading, setLoading] = useState(false);

    useEffect(() => {
    }, []);

    const animation = {
        in: {
            opacity: 1,
            y: 0,
            transition: { type: 'linear', delay: props.order * 0.1 },
            duration: 5,
        },
        out: {
            opacity: 0,
            y: 100,
            transition: { type: 'linear', delay: props.order * 0.02 },
            duration: 5,
        },
    }

    return (
        <motion.div 
            initial="out"
            animate="in"
            exit="out"
            variants={animation} 
            className="card" 
            id={props.id}
        >
            <div className="card-header d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                    {
                        props.icon &&
                        <div className="card-header-icon"
                            className={{
                                'blue': 'card-header-icon card-header-icon-blue',
                                'pink-purple': 'card-header-icon card-header-icon-pink-purple',
                                'pink': 'card-header-icon card-header-icon-pink',
                                'yellow': 'card-header-icon card-header-icon-yellow',
                            }[props.color]}
                        >
                            <i className={props.icon}></i>
                        </div>
                    }
                    <span className="card-header-title" style={{fontSize: props.fontSize ? props.fontSize : ''}}>
                        {props.title}
                    </span>
                </div>
                <div>
                    {
                        props.headerRight
                    }
                </div>
            </div>
            <div className="card-body">
                {props.children}
            </div>
        </motion.div>
    );
}