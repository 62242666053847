import React, { useEffect, useState } from "react";

import { AnimatePresence, motion } from 'framer-motion';
import { FaFileDownload, FaTrash } from 'react-icons/fa';

export default function Component({file, setOpenFile, drive, remove}) {

    function clickedOutside(e){
        e.stopPropagation();
        setOpenFile(false);
    }

    const animation = {
        in: {
            opacity: 1,
            transition: 'linear',
            duration: 1,
        },
        out: {
            opacity: 0,
            transition: 'linear',
            duration: 1,
        },
    }

    return (
        <AnimatePresence>
            {
                file &&
                <motion.div 
                    initial="out"
                    animate="in"
                    exit="out"
                    variants={animation}
                    className="lightbox-wrapper" 
                    onClick={(e) => clickedOutside(e)}
                >
                    {
                        ((!drive && file.preview != null) || (drive && file.link != null)) ?
                        <div>
                            <div className="lightbox-image">
                                <img src={drive ? file.link : file.preview}/>
                            </div>
                            <div className="text-center">
                                {
                                    drive ?

                                    <div>
                                        { 
                                            file.link &&
                                            <a href={file.link} target="_blank" download className="lightbox-function-icon">
                                                <FaFileDownload/>
                                            </a>
                                        }
                                        {
                                            remove &&
                                            <button onClick={() => remove(file)} target="_blank" download className="lightbox-remove-icon">
                                                <FaTrash/>
                                            </button>
                                        }
                                    </div>

                                    :

                                    (file.url &&
                                    <a href={file.url} target="_blank" download className="lightbox-function-icon">
                                        <FaFileDownload/>
                                    </a>)
                                }
                            </div>
                        </div>
                        :
                        <div className="text-center">
                            <div className="text-center">
                                <p>Preview not available for this file</p>    
                                <a href={drive ? file.link : file.url} target="_blank" download className="lightbox-function-icon">
                                    <FaFileDownload/>
                                </a>
                                <p className="download-text">Download file</p>
                            </div>
                            {
                                remove &&
                                <button onClick={() => remove(file)} target="_blank" download className="lightbox-function-icon">
                                    <FaTrash/>
                                </button>
                            }
                        </div>
                    }
                </motion.div>
            }
        </AnimatePresence>
    );
}