import React, { useState } from 'react';
import { motion, AnimatePresence } from "framer-motion";
import NotificationsBar from '../../components/notifications-bar';

import MenuSVGIcon from '../../assets/images/svg-icons/menu.svg';
import { useSelector } from 'react-redux';
import { BiMenuAltRight } from 'react-icons/bi';

export default function Header(props){
    const username = useSelector(state => state.data.name);
    const weather = useSelector(state => state.data.weather);

    const headerAnimation ={
        in: {
            opacity: 1,
            x: 0,
            transition: 'linear',
            duration: 0.3,
        },
        out: {
            opacity: 0,
            x: 50,
            transition: 'linear',
            duration: 0.3,
        },
    }

    const headerContentAnimation = {
        in: {
            opacity: 1,
            x: 0,
            transition: 'linear',
        },
        out: {
            opacity: 0,
            x: 20,
            transition: 'linear',
        },
    }
    
    const [notificationsBar, openNotifications] = useState(false);
    return (
        <>
        <NotificationsBar notificationsBar={notificationsBar} openNotifications={openNotifications}/>
        <header className="c-header c-header-light mb-3">
            <div className="align-items-center justify-content-end">

                <div className="d-flex justify-content-end">
                    {
                        false &&
                        <ul className="c-header-nav mfs-auto">
                            <li className="c-header-nav-item px-3 c-d-legacy-none">
                                <button className="c-className-toggler c-header-nav-btn dark-mode-button" type="button" id="header-tooltip" data-target="body" data-classname="c-dark-theme" data-toggle="c-tooltip" data-placement="bottom" title="Toggle Light/Dark Mode">
                                    <i className="fas fa-moon"></i>
                                </button>
                            </li>
                        </ul>
                    }

                    <ul className="c-header-nav d-none">
                        <button className="c-header-toggler c-className-toggler mfe-md-3" onClick={() => openNotifications(true)}>
                            <BiMenuAltRight size="30" color="white"/>
                        </button>
                    </ul>
                </div>
            </div>
                    <motion.div 
                        className="c-subheader d-block d-lg-flex justify-content-between align-items-center"
                        initial="out"
                        animate="in"
                        exit="out"
                        variants={headerAnimation}
                    >
                        <AnimatePresence exitBeforeEnter>
                            <motion.div
                                initial="out"
                                animate="in"
                                exit="out"
                                variants={headerContentAnimation}
                                key="layout-custom-header"
                            >
                                {props.left}
                            </motion.div>
                        </AnimatePresence>
                        <div className="header-right-div d-flex">
                            {
                                props.right
                            }

                            <button className="c-header-toggler c-className-toggler mfe-md-3 d-flex align-items-center justify-content-center" onClick={() => openNotifications(true)}>
                                <BiMenuAltRight size="30" color="white"/>
                            </button>
                        </div>
                    </motion.div>
                
        </header>
        </>
    )
}