export function setPermissions(permissions) {
    return {
        type: '@permissions/SET',
        payload: { permissions }
    }
}

export function resetPermissions() {
    return {
        type: '@permissions/RESET'
    }
}