import React, { useEffect, useState } from "react";

import { AnimatePresence, motion } from 'framer-motion';
import useOnclickOutside from "react-cool-onclickoutside";
import ProfilePlaceholder from '../../assets/images/profile-placeholder.jpg';
import LeadsActions from '../lead-actions';

export default function Component(props) {

    const [options, setOptions] = useState(false);

    useEffect(() => {

    }, [])

    const animation = {
        in: {
            opacity: 1,
            y: 0,
            transition: { type: 'linear', delay: 0 },
        },
        out: {
            opacity: 0,
            y: 50,
            transition: { type: 'linear', delay: 0 },
        },
    }

    const optionsAnimation = {
        in: {
            opacity: 1,
            x: 0,
            transition: { type: 'linear', delay: 0 },
            duration: 5,
        },
        out: {
            opacity: 0,
            x: 20,
            transition: { type: 'linear', delay: 0 },
            duration: 5,
        },
    }

    const ref = useOnclickOutside(() => {
        setOptions(false);
    });

    return (
        <motion.tr 
            initial="out"
            animate="in"
            exit="out"
            variants={animation} 
        >
            <td>
                <div className="leads-page-table-image-wrapper">
                    <img src={props.lead.image ? props.lead.image : ProfilePlaceholder}/>
                </div>
            </td>
            <td>{props.lead.name}</td>
            <td>{props.lead.email}</td>
            <td>{props.lead.phone}</td>
            <td>{props.lead.gender}</td>
            <td></td>
            <td className="breakable-td">{props.lead.location}</td>
            <td className="breakable-td">{props.lead.organization}</td>
            <td>
                {
                    props.lead.twitter &&
                    <a href={props.lead.twitter} target="_blank" className="leads-page-card-social-media-icon">
                        <i className="fab fa-twitter" aria-hidden="true"></i>
                    </a>
                }
                {
                    props.lead.facebook &&
                    <a href={props.lead.facebook} target="_blank" className="leads-page-card-social-media-icon">
                        <i className="fab fa-facebook" aria-hidden="true"></i>
                    </a>
                }
                {
                    props.lead.linkedin &&
                    <a href={props.lead.linkedin} target="_blank" className="leads-page-card-social-media-icon">
                        <i className="fab fa-linkedin" aria-hidden="true"></i>
                    </a>
                }
                {
                    props.lead.url &&
                    <a href={props.lead.url} target="_blank" className="leads-page-card-social-media-icon">
                        <i className="fas fa-globe"></i>
                    </a>
                }
            </td>
            <td>
                <LeadsActions lead={props.lead} leads={props.leads} setLeads={props.setLeads}/>
            </td>
        </motion.tr>
    );
}