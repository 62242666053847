import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import $ from 'jquery';
import DashboardPageLayout from '../../../layouts/dashboard-page';
import api from '../../../services/api';
import Sha256 from '../../../services/sha256';
import { useSelector, useDispatch } from 'react-redux';
import MultistateInput from '../../../components/multistate-input';
import Header from '../../../components/header';
import { Heading } from '@chakra-ui/react';
import { FaUser, FaBuilding, FaLock } from 'react-icons/fa';

export default function View(props) {
    const token = useSelector(state => state.auth.token);

    const [loading, setLoading] = useState(false);

    const [name, setName] = useState();
    const [editName, setEditName] = useState(false);

    const [email, setEmail] = useState();
    const [editEmail, setEditEmail] = useState(false);

    const [phone, setPhone] = useState();
    const [editPhone, setEditPhone] = useState(false);

    const [company, setCompany] = useState();
    const [editCompany, setEditCompany] = useState(false);

    const [country, setCountry] = useState();
    const [editCountry, setEditCountry] = useState(false);

    const [logo, setLogo] = useState();

    const [password, setPassword] = useState();
    const [newPassword, setNewPassword] = useState();
    const [confirmNewPassword, setConfirmNewPassword] = useState();
    const dispatch = useDispatch();

    useEffect(() => {
        api.get(api.defaults.baseURL + '/client_profile/get/' + token).then(res => {
            if(res){
                const { data } = res;
    
                setName(data.name);
                setEmail(data.email);
                setPhone(data.phone);
                setCompany(data.company);
                setCountry(data.address);
                setLogo(data.logo);

                console.log(data);
    
                setLoading(false);
            }
        });
    }, []);

    function updateData(){
        if(name && email && phone && company && country){
            setEditName(false);
            setEditEmail(false);
            setEditCompany(false);
            setEditPhone(false);
            setEditCountry(false);

            api.post(api.defaults.baseURL + '/client_profile/update/' + token, {
                name,
                email,
                phone,
                company,
                address: country,
            }).then(res => {
            });
        }
    }

    function updatePicture() {
        let formData = new FormData();
        formData.append('file', $('#profile-user-picture-input').prop('files')[0]);
    
        api.post(api.defaults.baseURL + '/client_profile/update_pic/' + token, formData).then(res => {
            if(res.data.code == 0){
                toast.error('Some error ocurred. Check the file.');
            } else {
                $('#profile-image-preview').attr('src', res.data.logo);
                toast.success('Profile picture updated succesfully');
            }
        });
    }

    function updateLogo() {
        let formData = new FormData();
        formData.append('file', $('#profile-user-logo-input').prop('files')[0]);
    
        api.post(api.defaults.baseURL + '/client_profile/update_pic/' + token, formData).then(res => {
            if(res.data.code == 0){
                toast.error('Some error ocurred. Check the file.');
            } else {
                $('#profile-logo-preview').attr('src', res.data.logo);
                toast.success('Profile picture updated succesfully');
            }
        });
    }

    const [updatingPassword, setUpdatingPassword] = useState(false);

    function updatePassword(e) {
        e.preventDefault();
        if (password && newPassword && confirmNewPassword) {
            if (newPassword == confirmNewPassword) {
                
                setUpdatingPassword(true);

                api.post(api.defaults.baseUrl + '/password/change/' + token, {
                    password: Sha256.hash($('#profile-current-password').val()),
                    new_password: Sha256.hash($('#profile-new-password').val())
                }).then(res => {
                    const code = res.data;
                    alert(code)
                    if (code == 0) {
                        setUpdatingPassword(false);
                        toast.error('Incorrect current password.');
                    }
                    if (code == 1) {
                        toast.success('Password updated successfully.');
                        setPassword('');
                        setNewPassword('');
                        setConfirmNewPassword('');
                        setUpdatingPassword(false);
                    }
                });
            }
            else {
                toast.warning('The passwords do not match. Please check the fields.');
            }
        }
        else {
            toast.warning('Please check the fields.');
        }
    }

    function openInput(){
        document.getElementById('profile-user-picture-input').click();
    }

    const animation = {
        in: order => ({
            opacity: 1,
            y: 0,
            transition: { type: 'linear', delay: order * 0.1 },
        }),
        out: order => ({
            opacity: 0,
            y: 20,
            transition: { type: 'linear', delay: order * 0.05 },
        }),
    }

    return (
        !loading &&
        <>
        <Header left={
            <Heading fontWeight="700" fontFamily="poppins">Profile</Heading>
        }/>
        <DashboardPageLayout title={'Manage Your Profile Data'} headerColor={'pink-header'}>
            <div className="row">
                <div className="col-12 col-lg-6 justify-content-between">
                    <Heading fontSize={{base: '25px', '2xl': '35px'}} d="flex" align="center" mb="4"><FaUser style={{marginRight: 10}}/> Personal</Heading>
                    <div className="container-fluid">
                        <div className="row">
                        <div className="col-12 col-lg-4 col-xl-3 ">
                            <div className="profile-user-picture-wrapper d-flex justify-content-center justify-content-lg-left" style={{maxWidth: 200, marginBottom: 30}}>
                                <div className="profile-user-picture">
                                    <img src={
                                        logo ?
                                        logo 
                                        :
                                        "https://www.ajactraining.org/wp-content/uploads/2019/09/image-placeholder.jpg"
                                    } id="profile-image-preview" />
                                </div>
                                <button className="profile-user-picture-button d-flex align-items-center justify-content-center" onClick={openInput}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-refresh-ccw">
                                        <polyline points="1 4 1 10 7 10"></polyline>
                                        <polyline points="23 20 23 14 17 14"></polyline>
                                        <path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path>
                                    </svg>
                                </button>
                                <input type="file" id="profile-user-picture-input" className="d-none" onChange={updatePicture}/>
                            </div>
                        </div>
                        <div className="col-12 col-lg-5 col-xl-6">
                            
                            <MultistateInput
                                label={'Name'}
                                value={name}
                                changeFunction={setName}
                                updateFunction={updateData}
                                order={1}
                            />

                            <MultistateInput
                                label={'Email'}
                                value={email}
                                changeFunction={setEmail}
                                updateFunction={updateData}
                                order={2}
                            />

                            <MultistateInput
                                label={'Phone'}
                                value={phone}
                                changeFunction={setPhone}
                                updateFunction={updateData}
                                order={3}
                            />
                            
                            <MultistateInput
                                label={'Country'}
                                value={country}
                                changeFunction={setCountry}
                                updateFunction={updateData}
                                order={5}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 col-lg-6 d-none" style={{opacity: 0.9, pointerEvents: 'none'}}>
                <div className="card profile-date-card">
                    <div className="card-body">
                        <form>
                            <div className="container-fluid">
                            <div className="row">
                                <div className="form-group col-12 col-lg-4 pr-lg-2">
                                    <label for="profile-input-date-format" className="profile-date-label">Date Format</label>
                                    <input type="text" className="profile-date-input" id="profile-input-date-format" />
                                </div>
                                <div className="form-group col-12 col-lg-4 pr-lg-2">
                                    <label for="profile-input-date-format" className="profile-date-label">Time Format</label>
                                    <input type="text" className="profile-date-input" className="profile-input-time-format" />
                                </div>
                                <div className="form-group col-12 col-lg-4">
                                    <label for="profile-input-date-format" className="profile-date-label">Fuse Time</label>
                                    <input type="text" className="profile-date-input" id="profile-input-fuse-time" />
                                </div>
                            </div>
                            </div>
                            <button className="profile-date-submit-button">EDIT PROFILE</button>
                        </form>
                    </div>
                </div>
            </div>
            
            <div className="col-12 col-lg-6">
                <Heading fontSize={{base: '25px', '2xl': '35px'}} d="flex" align="center" mb="4"><FaBuilding style={{marginRight: 10}}/> Company</Heading>
                <div className="profile-user-picture-wrapper d-inline-block" style={{marginBottom: 30}}>
                    <div className="profile-logo-picture">
                        <img src={
                            logo ?
                            logo 
                            :
                            "https://www.ajactraining.org/wp-content/uploads/2019/09/image-placeholder.jpg"
                        } id="profile-logo-preview" />
                    </div>
                    <button className="profile-user-logo-button d-flex align-items-center justify-content-center" onClick={openInput}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-refresh-ccw">
                            <polyline points="1 4 1 10 7 10"></polyline>
                            <polyline points="23 20 23 14 17 14"></polyline>
                            <path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path>
                        </svg>
                    </button>
                    <input type="file" id="profile-user-logo-input" className="d-none" onChange={updateLogo}/>
                </div>

                <MultistateInput
                    label={'Company'}
                    value={company}
                    changeFunction={setCompany}
                    updateFunction={updateData}
                    order={4}
                />
            </div>
            </div>
            <div className="profile-change-password mt-5">
                <Heading fontSize={{base: '25px', '2xl': '35px'}} d="flex" align="center" mb="4"><FaLock style={{marginRight: 10}}/> Change Password</Heading>
                <form className="mt-3" onSubmit={updatePassword}>
                    <input type="password" id="profile-current-password" placeholder="Actual password" className="profile-password-input" value={password} onChange={(e) => setPassword(e.target.value)}/>
                    <input type="password" id="profile-new-password" placeholder="Password" className="profile-password-input" value={newPassword} onChange={(e) => setNewPassword(e.target.value)}/>
                    <input type="password" id="profile-confirm-password" placeholder="Confirm password" className="profile-password-input" value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)}/>
                    <button type="submit" className="dashboard-button primary-button password-confirm-button">Confirm</button>
                </form>
            </div>
        </DashboardPageLayout>
        </>
    );
}