import React, { useEffect, useState } from "react";
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';

import { motion } from 'framer-motion';

export default function Component(props) {

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        var reports = [];
        props.metrics.map((metric, i) => {
            reports.push({
                day: props.days[i],
                metric: metric
            })
        })
        setData([...reports]);
        setLoading(false);
    }, [])

    const animation = {
        in: {
            opacity: 1,
            x: 0,
            transition: { type: 'linear', delay: props.order * 0.1 },
            duration: 5,
        },
        out: {
            opacity: 0,
            x: 100,
            transition: { type: 'linear', delay: 0.5 / props.order },
            duration: 5,
        },
    }

    useEffect(() => {
    }, []);

    function nFormatter(num, digits) {
        var si = [
            { value: 1, symbol: "" },
            { value: 1E3, symbol: "k" },
            { value: 1E6, symbol: "M" },
            { value: 1E9, symbol: "G" },
            { value: 1E12, symbol: "T" },
            { value: 1E15, symbol: "P" },
            { value: 1E18, symbol: "E" }
        ];
        var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        var i;
        for (i = si.length - 1; i > 0; i--) {
            if (num >= si[i].value) {
                break;
            }
        }
        return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
    }

    return (
        !loading &&
        <motion.div 
            initial="out"
            animate="in"
            exit="out"
            variants={animation} 
            className={{
                'purple-blue': 'card home-stats-card media-stats-card-purple-blue',
                'pink-purple': 'card home-stats-card media-stats-card-pink-purple',
                'pink': 'card home-stats-card media-stats-card-pink',
                'yellow': 'card home-stats-card media-stats-card-yellow',
            }[props.color]}
        >
            <div className="card-body card-body pb-0 d-flex flex-column justify-content-between align-items-start">
                <div className="metrics">
                    <div className="value" id="qty_followers">{nFormatter(parseInt(props.metrics), 2)}</div>
                    <div>Total {props.metric}</div>
                </div>
                <ResponsiveContainer width={'100%'} height={90}>
                    <LineChart 
                        data={data}
                        margin={{
                            top: 20, right: 5, left: 5, bottom: 10,
                        }}
                    >
                        <Tooltip/>
                        <Line dataKey="metric" stroke="white" strokeWidth={2} />
                        <XAxis dataKey="day" stroke="#9A9A9A" axisLine={false} tick={false}/>
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </motion.div>
    );
}