import React, { useEffect, useState } from 'react';
import Routes from './Routes';
import './assets/style/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/js/dist/carousel';
import 'react-toastify/dist/ReactToastify.css';

import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';
import { BrowserRouter as Router, Redirect, useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import history from './services/history';
import { toast } from 'react-toastify';
import api from './services/api';
import { useDispatch  } from 'react-redux';
import { logoutAction } from './store/modules/auth/actions';
import { ChakraProvider } from "@chakra-ui/react"
import SocialProof from './components/social-proof'

function App() {

	return (
		<>
		<ChakraProvider>
			<ToastContainer />
			<Provider store={ store }>
				<SocialProof/>
				<PersistGate persistor={ persistor }>
					<Router>
						<Routes />
					</Router>
				</PersistGate>
			</Provider>
		</ChakraProvider>
		</>
	);
}

export default App;
