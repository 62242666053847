import React, { useEffect, useState } from "react";

import api from '../../../services/api';

import { useSelector } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import Skeleton from 'react-loading-skeleton';

export default function HomeLatestPosts (props) { 

    const [posts, setPosts] = useState([]);

    const [selectedSocialMedia, setSelectedSocialMedia] = useState(false);

    const [loading, setLoading] = useState(true);

    const token = useSelector(state => state.auth.token);

    const animation = {
        in: index => ({
            opacity: 1,
            y: 0,
            transition: { type: 'linear', delay: index * 0.1 },
        }),
        out: index => ({
            opacity: 0,
            y: 20,
            transition: { type: 'linear', delay: index * 0.05 },
        }),
    }

    useEffect(() => {
        api.post(api.defaults.baseURL + '/dashboard/list_posts/' + token, {
            latest: true,
        }).then(res => {
            if(res.data != null){
                console.log('res aqui')
                console.log(res.data);
                setSelectedSocialMedia('all');
    
                setPosts(res.data);
                //setLoading(false);
            }
        });
    }, []);

    return (
        <div>
            <div className="feed-page">
                {

                }
                <div className="feed-page-title d-flex justify-content-center align-items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24.253" height="24.253" viewBox="0 0 24.253 24.253" style={{marginRight: 5}}>
                        <path id="Icon_ionic-md-grid" data-name="Icon ionic-md-grid" d="M26.328,4.5H6.925A2.434,2.434,0,0,0,4.5,6.925v19.4a2.434,2.434,0,0,0,2.425,2.425h19.4a2.434,2.434,0,0,0,2.425-2.425V6.925A2.434,2.434,0,0,0,26.328,4.5ZM11.776,26.328H6.925V21.477h4.851Zm0-7.276H6.925V14.2h4.851Zm0-7.276H6.925V6.925h4.851Zm7.276,14.552H14.2V21.477h4.851Zm0-7.276H14.2V14.2h4.851Zm0-7.276H14.2V6.925h4.851Zm7.276,14.552H21.477V21.477h4.851Zm0-7.276H21.477V14.2h4.851Zm0-7.276H21.477V6.925h4.851Z" transform="translate(-4.5 -4.5)" fill="#0676ed"/>
                    </svg>
                    Feed Page 
                </div>
                <div className="latest-posts-content-wrapper">
                    <div className="latest-posts-feed">

                        <div className="latests-posts-feed-squares" style={{height: '100%', transition: '0.5s'}}>
                            {
                                loading ?
                                Array.from({ length: 6 }).map(() => (
                                    <div className="latest-posts-feed-square">
                                        <Skeleton width={'100%'} height={'100%'}/>
                                    </div>
                                ))
                                :
                                <AnimatePresence>
                                {
                                    selectedSocialMedia &&
                                    posts[selectedSocialMedia].map((post, index) => (
                                        <motion.div
                                            initial="out"
                                            animate="in"
                                            exit="out"
                                            variants={animation} 
                                            custom={index} 
                                            key={'latest-post-' + index} 
                                            className="latest-posts-feed-square">
                                            <a href={post.link} target="_blank" className="latest-posts-feed-square-content" alt={post.description}>
                                                <img src={post.image ? post.image : '/images/image-placeholder.png'}/>
                                            </a>
                                        </motion.div>
                                    ))
                                }
                            </AnimatePresence>
                            }
                        </div>

                    </div>
                    {
                        (!loading && posts) &&
                        <div className="d-flex justify-content-center mt-3">
                            {
                                posts.facebook != undefined &&
                                <button onClick={() => setSelectedSocialMedia('facebook')} className={selectedSocialMedia == 'facebook' ? "icon-button icon-button-facebook icon-button icon-button-facebook-active" : "icon-button icon-button-facebook" }>
                                    <i className="fab fa-facebook-f"></i>
                                </button>
                            }
                            {
                                posts.twitter != undefined &&
                                <button onClick={() => setSelectedSocialMedia('twitter')} className={selectedSocialMedia == 'twitter' ? "icon-button icon-button-twitter icon-button icon-button-twitter-active" : "icon-button icon-button-twitter" }>
                                    <i className="fab fa-twitter"></i>
                                </button>
                            }
                            {
                                posts.youtube != undefined &&
                                <button onClick={() => setSelectedSocialMedia('youtube')} className={selectedSocialMedia == 'youtube' ? "icon-button icon-button-youtube icon-button icon-button-youtube-active" : "icon-button icon-button-youtube" }>
                                    <i className="fab fa-youtube"></i>
                                </button>
                            }
                            {
                                posts.instagram != undefined &&
                                <button onClick={() => setSelectedSocialMedia('instagram')} className={selectedSocialMedia == 'instagram' ? "icon-button icon-button-instagram icon-button icon-button-instagram-active" : "icon-button icon-button-instagram" }>
                                    <i className="fab fa-instagram"></i>
                                </button>
                            }
                            {
                                posts.pinterest != undefined &&
                                <button onClick={() => setSelectedSocialMedia('pinterest')} className={selectedSocialMedia == 'pinterest' ? "icon-button icon-button-pinterest icon-button icon-button-pinterest-active" : "icon-button icon-button-pinterest" }>
                                    <i className="fab fa-pinterest"></i>
                                </button>
                            }
                        </div>
                    }
                </div>
                <br/>
                <div className="feed-page-title d-flex justify-content-center align-items-center mb-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="26.107" height="25.707" viewBox="0 0 26.107 25.707" style={{marginRight: 5}}>
                        <g id="Group_77" data-name="Group 77" transform="translate(-110.2 -494.707)">
                        <path id="Icon_ionic-md-open" data-name="Icon ionic-md-open" d="M23.449,23.448H6.868V6.869h7.4V4.5h-7.4A2.375,2.375,0,0,0,4.5,6.869v16.58a2.375,2.375,0,0,0,2.368,2.369H23.449a2.375,2.375,0,0,0,2.368-2.369v-7.4H23.449Z" transform="translate(105.7 494.596)" fill="#0676ed"/>
                        <g id="Icon_feather-plus" data-name="Icon feather-plus" transform="translate(125.346 495.707)">
                            <path id="Path_98" data-name="Path 98" d="M18,7.5v9.279" transform="translate(-13.019 -7.5)" fill="none" stroke="#0676ed" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                            <path id="Path_99" data-name="Path 99" d="M7.5,18h9.961" transform="translate(-7.5 -13.36)" fill="none" stroke="#0676ed" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                        </g>
                        </g>
                    </svg>
                        Post Page                                
                </div>

                    
                <div className="latest-posts-content-wrapper">
                    <div className="latest-posts-post">
                        <div id="latest-posts-post-carousel" className="carousel slide" data-ride="carousel">
                            <div className="carousel-inner">
                                {
                                    loading ?
                                    <div className="carousel-item active mb-2">
                                        <Skeleton width={'100%'} height={'300px'}/>
                                    </div>
                                    :
                                    posts['all'].map((post, index) => (
                                        <div className={index == 0 ? 'carousel-item active' : 'carousel-item'} key={'latest-posts-item-'+post.id+index}>
                                            <div className="latests-posts-post-wrapper">
                                                {
                                                    {
                                                        'facebook':
                                                            <div className="latest-posts-post-social">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="19.818" height="19.818" viewBox="0 0 19.818 19.818">
                                                                    <path id="Icon_awesome-facebook-square" data-name="Icon awesome-facebook-square" d="M17.694,2.25H2.123A2.123,2.123,0,0,0,0,4.373V19.944a2.123,2.123,0,0,0,2.123,2.123H8.195V15.33H5.408V12.159H8.195V9.742c0-2.749,1.637-4.268,4.144-4.268a16.883,16.883,0,0,1,2.456.214v2.7H13.411A1.586,1.586,0,0,0,11.623,10.1v2.059h3.043l-.487,3.171H11.623v6.738h6.071a2.123,2.123,0,0,0,2.123-2.123V4.373A2.123,2.123,0,0,0,17.694,2.25Z" transform="translate(0 -2.25)" fill="#415a93"/>
                                                                </svg>
                                                                <span className="ml-2">Facebook</span>  
                                                            </div>,
                                                        'twitter':
                                                            <div className="latest-posts-post-social">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="21.742" height="17.658" viewBox="0 0 21.742 17.658">
                                                                <path id="Icon_awesome-twitter" data-name="Icon awesome-twitter" d="M19.507,7.782c.014.193.014.386.014.579A12.591,12.591,0,0,1,6.843,21.039a12.592,12.592,0,0,1-6.843-2,9.218,9.218,0,0,0,1.076.055,8.924,8.924,0,0,0,5.532-1.9A4.464,4.464,0,0,1,2.442,14.1a5.619,5.619,0,0,0,.842.069,4.713,4.713,0,0,0,1.173-.152A4.457,4.457,0,0,1,.883,9.644V9.589a4.488,4.488,0,0,0,2.014.566,4.463,4.463,0,0,1-1.38-5.96,12.666,12.666,0,0,0,9.188,4.663,5.03,5.03,0,0,1-.11-1.021,4.46,4.46,0,0,1,7.712-3.049,8.773,8.773,0,0,0,2.828-1.076,4.444,4.444,0,0,1-1.959,2.456,8.933,8.933,0,0,0,2.566-.69,9.579,9.579,0,0,1-2.235,2.3Z" transform="translate(0 -3.381)" fill="#0676ed"/>
                                                                </svg>                                              
                                                                <span className="ml-2">Twitter</span>  
                                                            </div>,
                                                        'instagram':
                                                            <div className="latest-posts-post-social">
                                                                <img src="assets/img/svg-icons/latest posts/instagram-icon.svg"/>                                          
                                                                <span className="ml-2">Instagram</span>  
                                                            </div>,
                                                        'pinterest':
                                                            <div className="latest-posts-post-social">
                                                                <span style={{color: '#CB2027', fontSize: 20, lineHeight: 15}}>
                                                                <i className="fab fa-pinterest-square"></i>
                                                                </span>
                                                                <span className="ml-2">Pinterest</span>  
                                                            </div>,
                                                        'youtube':
                                                            <div className="latest-posts-post-social">
                                                                <img src="assets/img/svg-icons/latest posts/youtube-icon.svg"/> 
                                                                <span className="ml-2">Youtube</span>  
                                                            </div>
                                                    }[post['social media']]
                                                }
                                                <img className="latest-posts-post-image" src={post.image ? post.image : '/images/image-placeholder.png'} alt={post.description}/>
                                            </div>
                            
                                            <div className="d-flex justify-content-between align-items-center">
                                                <a style={{opacity: 0}} className="page-button latest-back-post-button" href="#latest-posts-post-carousel" data-target="#latest-posts-post-carousel" role="button" data-slide="prev">
                                                    <i className="fas fa-arrow-left"></i>
                                                </a>
                                                    <span className="latest-posts-post-legend">{post.description}</span>
                                                <a style={{opacity: 0}} className="page-button latest-next-post-button" href="#latest-posts-post-carousel" data-target="#latest-posts-post-carousel" role="button" data-slide="next">
                                                    <i className="fas fa-arrow-right"></i>
                                                </a>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                            
                    <div className="d-flex justify-content-between align-items-center">
                        <a className="page-button latest-back-post-button" href="#latest-posts-post-carousel" data-target="#latest-posts-post-carousel" role="button" data-slide="prev">
                            <i className="fas fa-arrow-left"></i>
                        </a>
                        <a className="page-button latest-next-post-button" href="#latest-posts-post-carousel" data-target="#latest-posts-post-carousel" role="button" data-slide="next">
                            <i className="fas fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}