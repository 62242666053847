import React, { useEffect, useState } from "react";

import FacebookIcon from '../../../assets/images/icon-facebook.png';
import InstagramIcon from '../../../assets/images/icon-instagram.png';
import PinterestIcon from '../../../assets/images/icon-pinterest.png';
import TwitterIcon from '../../../assets/images/icon-twitter.png';
import YoutubeIcon from '../../../assets/images/icon-youtube.png';

import api from '../../../services/api';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';

import Card from '../../card';

import RectSVG from '../../../assets/images/rect.svg';

import Skeleton from 'react-loading-skeleton';

import { IoGridSharp } from 'react-icons/io5';

import { Avatar, Heading, Text, Grid, Progress, Box } from '@chakra-ui/react'

export default function HomeLatestPosts (props) { 
    const token = useSelector(state => state.auth.token);

    const [posts, setPosts] = useState([]);
    const [option, setOption] = useState(false);

    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        if(props.bestPosts){
            setPosts(props.bestPosts);
            setOption('all');
            return setLoading(false);
        }

        api.get(api.defaults.baseURL + '/dashboard/list_posts/' + token).then(res => {
            if(res){
                const { data } = res;
    
                setPosts(data);
    
                if(data.all)
                    setOption('all');
    
                setLoading(false);
            }
        });
    }, []);

    const animation = {
        in: index => ({
            opacity: 1,
            y: 0,
            transition: { type: 'linear', delay: index * 0.1 },
        }),
        out: index => ({
            opacity: 0,
            y: 20,
            transition: { type: 'linear', delay: index * 0.05 },
        }),
    }

    return (
        <Card order={7} title={'The Best Posts'} id="bests" headerRight={
            <div className="d-flex justify-content-center justify-content-lg-end mt-3 mt-lg-0 locked-content">
                {
                    loading ?
                        <Skeleton width={200} height={32}/>
                    :
                    <>
                    <button className={`p-0 icon-button icon-button-all reports-icon-button-all ${option == 'all' && 'icon-button-all-active'}`} onClick={() => setOption('all')}>
                        <IoGridSharp size="13"/>
                    </button>
                    {
                        posts.facebook && 
                        <button className={`icon-button icon-button-facebook reports-icon-button-facebook ${option == 'facebook' && 'icon-button-facebook-active'}`} onClick={() => setOption('facebook')}>
                            <i className="fab fa-facebook-f"></i>
                        </button>
                    }
                    {
                        posts.twitter && 
                        <button className={`icon-button icon-button-twitter ${option == 'twitter' && 'icon-button-twitter-active'}`} onClick={() => setOption('twitter')}>
                            <i className="fab fa-twitter"></i>
                        </button>
                    }
                    {
                        posts.youtube &&
                        <button className={`icon-button icon-button-youtube ${option == 'youtube' && 'icon-button-youtube-active'}`} onClick={() => setOption('youtube')}>
                            <i className="fab fa-youtube"></i>
                        </button>
                    }
                    {
                        posts.instagram &&
                        <button className={`icon-button icon-button-instagram ${option == 'instagram' && 'icon-button-instagram-active'}`} onClick={() => setOption('instagram')}>
                            <i className="fab fa-instagram"></i>
                        </button>
                    }
                    {
                        posts.pinterest &&
                        <button className={`icon-button icon-button-pinterest ${option == 'pinterest' && 'icon-button-pinterest-active'}`} onClick={() => setOption('pinterest')}>
                            <i className="fab fa-pinterest"></i>
                        </button>
                    }
                    </>
                }
            </div>
        }>
        <div className="table-wrapper" id="best-posts" style={{height: '100%', overflowY: 'scroll'}}>
            {
                loading ?
                Array.from({ length: 6 }).map(() => (
                    <div style={{marginBottom: 5, paddingRight: 10}}>
                        <Skeleton width={'100%'} height={60}/>
                    </div>
                ))
                :
                option &&
                posts[option].map((post, index) => (
                    <motion.div 
                        initial="out"
                        animate="in"
                        exit="out"
                        variants={animation} 
                        custom={index} 
                        key={index + post.link}
                    >
                        <Grid templateColumns={{base: "50px 1fr 0.8fr 0.5fr", '2xl': '50px 1.5fr 0.8fr 0.5fr'}} columnGap="10px" alignItems="center" pr="4" mb="3">
                            <Avatar ml="auto" mr="auto" size="sm" src={post.image}/>
                            <Box>
                                <Heading fontFamily="poppins" fontSize={{base: '14px', '2xl': '16px'}} fontWeight="400" color="#0676ED" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis" maxW={{base: '150px', '2xl': '250px'}}>{post.description}</Heading>
                                <Text fontFamily="poppins" fontSize="12px" color="gray.400">{post.post_date}</Text>
                            </Box>
                            <Box>
                                <Text fontFamily="poppins" fontSize={{base: '12px', '2xl': '14px'}} textAlign="center">{(post.engagement * 100).toFixed(2)}% Engagement</Text>
                                <Progress colorScheme="blue" borderRadius="10px" size="xs" value={20} mt="1" />
                            </Box>
                            <Box textAlign="right">
                                <a href={post.link} target="_blank" className="mt-2 dashboard-button primary-button small-button">See post</a>
                            </Box>
                        </Grid>
                    </motion.div>
                ))
            }
            </div>
        </Card>
    );
}