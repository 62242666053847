import React, { useEffect, useState } from "react";
import {
  Link,
} from "react-router-dom";

import Header from '../../../components/header';
import { Heading } from '@chakra-ui/react';
import { FaCreditCard, FaChevronDown, FaPen, FaCheck } from 'react-icons/fa';

import DashboardPageLayout from '../../../layouts/dashboard-page';
import Modal from '../../../components/modal';

import api from '../../../services/api';

import { useSelector, useDispatch } from 'react-redux';

import { AnimatePresence, motion, AnimateSharedLayout } from 'framer-motion';

import CardChipSVG from '../../../assets/images/billing/card-chip.svg';
import MasterCardSVG from '../../../assets/images/billing/mastercard.svg';
import { setHeader } from '../../../store/modules/layout/actions';

export default function View(props) {
    const token = useSelector(state => state.auth.token);

    const [name, setName] = useState('');
    const [email, setEmail] = useState();
    const [phone, setPhone] = useState();
    const [company, setCompany] = useState();
    const [country, setCountry] = useState();
    const [logo, setLogo] = useState();

    const [loading, setLoading] = useState(false);

    const [payments, setPayments] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        api.get(api.defaults.baseURL + '/client_profile/get/' + token).then(res => {
            if(res){
                const { data } = res;
    
                setName(data.name);
                setEmail(data.email);
                setPhone(data.phone);
                setCompany(data.company);
                setCountry(data.address);
                setLogo(data.logo);
    
                setLoading(false);
            }
        });    
    }, []);

    const animation = {
        in: order => ({
            opacity: 1,
            y: 0,
            transition: { type: 'linear', delay: order * 0.1 },
        }),
        out: order => ({
            opacity: 0,
            y: 20,
            transition: { type: 'linear', delay: order * 0.05 },
        }),
    }

    return (
        <>
        <Header left={
            <Heading fontWeight="700" fontFamily="poppins">Billing Details</Heading>
        }/>
        <DashboardPageLayout notBoxed>
            <motion.div className="billing-grid">
                <div id="billing-header">
                    <div className="billing-header-card">
                        <div className="billing-plan">
                            FREE
                        </div>
                        <p className="billing-p">Current subscription plan</p>
                        <div className="billing-footer">
                            <h2>$0<span className="cents">.00</span></h2>
                            <Link to='/dashboard/plans' className="change-plan-button">CHANGE PLAN +</Link>
                        </div>
                    </div>
                    <div className="next-payment-card">
                        <div className="next-payment-header">
                            <h3><span className="bar">---</span> Next Payment</h3>
                            <div className="payment-icon">
                                <FaCreditCard size={25}/>
                            </div>
                        </div>
                        <h2>$0<span className="cents">.00</span></h2>
                        <div className="next-footer">
                            <p>on February 5, 2021</p>
                            <button className="manage-button">
                                MANAGE PAYMENTS +
                            </button>
                        </div>
                    </div>
                </div>
                <div id="billing-body">
                    <Heading size="lg" mb="3" fontWeight="600" fontFamily="poppins">$ Payment History</Heading>
                    <div className="table-wrapper">
                        <table>
                            <thead>
                                <th>Amount</th>
                                <th>Status</th>
                                <th>Recepient</th>
                                <th>Date</th>
                                <th>Payment method</th>
                                <th>Download</th>
                                <th></th>
                            </thead>
                            <tbody>
                                <AnimateSharedLayout>
                                    {
                                        payments.map(payment => (
                                            <Tr/>
                                        ))
                                    }
                                </AnimateSharedLayout>
                            </tbody>
                        </table>
                        {
                            payments.length == 0 &&
                            <div className="p-5 text-center">
                                No payments recorded
                            </div>
                        }
                    </div>
                </div>
                <div id="billing-aside">
                    <div className="aside-card payment-information">
                        <div className="header">
                            <div className="title">
                                <FaCreditCard size={25}/>
                                <h2>Payment</h2>
                            </div>
                            <div>
                                <motion.button whileTap={{ scale: 0.8 }}><FaPen size={12}/></motion.button>
                                {/* <motion.button className="checked" whileTap={{ scale: 0.8 }}><FaCheck size={15}/></motion.button> */}
                            </div>
                        </div>
                        <div className="content">
                            <div className="credit-card">
                                <div className="header">
                                    <img src={CardChipSVG}/>
                                    <img src={MasterCardSVG}/>
                                </div>
                                <p className="card-number">**** **** **** 0000</p>
                                <div className="footer">
                                    <p className="text">{name ? name.toUpperCase() : ''}</p>
                                    <div className="text-left">
                                        <span className="date-label">DATE</span>
                                        <div className="text">00/2000</div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-data">
                                <p><span>Mastercard</span> 0000</p>
                                <p><span>Exp. Date</span> 00/2000</p>
                            </div>
                        </div>
                    </div>

                    <div className="aside-card aside-profile">
                        <div className="header">
                            <div className="image" style={{backgroundImage: `url(${logo})`}}></div>
                            <h3 className="name">{name}</h3>
                            <p className="email">{email}</p>
                        </div>
                        <div>
                            <p><strong>Company:</strong> {company}</p>
                            <p><strong>Address:</strong> {country}</p>
                            <p><strong>Phone:</strong> {phone}</p>
                        </div>
                        <div className="billing-period">
                            <div className="mb-3">
                                <p><strong>Billing Period Start</strong></p>
                                <p>January 12th 2021, 11:28:29 pm</p>
                            </div>
                            <div>
                                <p><strong>Billing Period End</strong></p>
                                <p>February 12th 2021, 11:28:29 pm</p>
                            </div>
                        </div>

                    </div>
                </div>
            </motion.div>
        </DashboardPageLayout>
        </>
    );
}

const Tr = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const animation = {
        in: order => ({
            opacity: 1,
            y: 0,
            transition: { type: 'ease', delay: 0 },
        }),
        out: order => ({
            opacity: 0,
            y: -20,
            transition: { type: 'ease', delay: 0 },
        }),
    }

    const chevronAnimation = {
        open: {
            rotate: 180,
            transition: 'ease',
        },
        close: {
            rotate: 0,
            transition: 'ease',
        },
    }

    return (
        <>
        <motion.tr layout>
            <td>$90.00</td>
            <td><span class="status pending">Pending</span></td>
            <td>Gabriel Banks</td>
            <td>Jan 20, 2021</td>
            <td>Visa 5432</td>
            <td><a href="#" className="download">Download PDF</a></td>
            <td>
                <motion.button
                    className="payment-collapse-button" 
                    whileTap={{ scale: 0.9 }} 
                    onClick={toggle}
                >
                    <motion.div
                        animate={isOpen ? "open" : "close"}
                        variants={chevronAnimation}
                    >
                        <FaChevronDown/>
                    </motion.div>
                </motion.button>
            </td>
        </motion.tr>
        <AnimatePresence>
            {
                isOpen &&
                <motion.tr
                    initial="out"
                    animate="in"
                    exit="out"
                    variants={animation} 
                    layout
                >
                    <td colSpan={7} style={{paddingTop: 0}}>
                        <div className="collapse-td">
                            <div className="td-plan">
                                BILLING PLAN
                            </div>

                            <div>
                                <strong>Status</strong> Completed
                            </div>

                            <div>
                                <strong>Invoice Date</strong> Apr 10, 2020
                            </div>

                            <div>
                                <strong>Date Paid</strong> Apr 10, 2020
                            </div>

                            <div className="amount">
                                <strong>Amount due</strong> Apr 10, 2020
                            </div>
                        </div>
                    </td>
                </motion.tr>
            }
        </AnimatePresence>
        </>
    )
}