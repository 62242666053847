import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import api from '../../../services/api';
import { AnimatePresence, motion } from 'framer-motion';
import DashboardPageLayout from '../../../layouts/dashboard-page';
import $ from 'jquery';
import Modal from '../../../components/modal';
import { CustomInput } from 'reactstrap';
import Header from '../../../components/header';
import { Heading } from '@chakra-ui/react';

export default function Roles(props){

    const token = useSelector(state => state.auth.token);

    const [roles, setRoles] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        // api.get(api.defaults.baseURL + '/roles/list/' + token).then(res => {
        //     setRoles(res.data)
        //     console.log(res.data)
        // })
    }, [])

    function scrollLeft(){
        let leftPos = $('#roles').scrollLeft();
        $("#roles").animate({
            scrollLeft: leftPos - 255,
        }, 800); 
    }

    function scrollRight(){
        let leftPos = $('#roles').scrollLeft();
        $("#roles").animate({
            scrollLeft: leftPos + 255
        }, 800);
    }

    const [newRole, setNewRole] = useState();

    function createRole(){
        api.post(api.defaults.baseURL + '/roles/create/' + token, {name: newRole}).then(res => {
            setRoles([...res.data]);
        })
    }

    return (
        <>
        <Header left={
            <Heading fontWeight="700" fontFamily="poppins">Roles</Heading>
        }/>
        <DashboardPageLayout title={'User Roles'} headerColor={'yellow-header'} notBoxed={true}>
            <div className="roles-grid">
                <div className="controls">
                    <motion.button whileTap={{scale: 0.9}} onClick={() => scrollLeft()} className="d-flex align-items-center justify-content-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15.752" height="15.353" viewBox="0 0 15.752 15.353">
                            <path id="Icon_awesome-arrow-right" data-name="Icon awesome-arrow-right" d="M6.7,3.676l.78-.78a.84.84,0,0,1,1.192,0L15.5,9.726a.84.84,0,0,1,0,1.192L8.67,17.752a.84.84,0,0,1-1.192,0l-.78-.78a.845.845,0,0,1,.014-1.206l4.236-4.036H.844A.842.842,0,0,1,0,10.886V9.761a.842.842,0,0,1,.844-.844h10.1L6.711,4.881A.839.839,0,0,1,6.7,3.676Z" transform="translate(15.752 18) rotate(180)" fill="#0676ed"/>
                        </svg>
                    </motion.button>
                    <motion.button whileTap={{scale: 0.9}} onClick={() => scrollRight()} className="d-flex align-items-center justify-content-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15.752" height="15.353" viewBox="0 0 15.752 15.353">
                            <path id="Icon_awesome-arrow-right" data-name="Icon awesome-arrow-right" d="M6.7,3.676l.78-.78a.84.84,0,0,1,1.192,0L15.5,9.726a.84.84,0,0,1,0,1.192L8.67,17.752a.84.84,0,0,1-1.192,0l-.78-.78a.845.845,0,0,1,.014-1.206l4.236-4.036H.844A.842.842,0,0,1,0,10.886V9.761a.842.842,0,0,1,.844-.844h10.1L6.711,4.881A.839.839,0,0,1,6.7,3.676Z" transform="translate(0 -2.647)" fill="#0676ed"/>
                        </svg>
                    </motion.button>
                </div>
                <div className="roles" id="roles">
                    {
                        roles.map(role => (
                            <Role role={role} qtd={1} roles={roles} setRoles={setRoles}/>
                        ))
                    }
                </div>
                <div className="add">
                    <Modal
                        buttonClass='add-role'
                        buttonText={
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <g id="Icon_feather-plus" data-name="Icon feather-plus" transform="translate(-6 -6)">
                                    <path id="Path_217" data-name="Path 217" d="M18,7.5v21" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                    <path id="Path_218" data-name="Path 218" d="M7.5,18h21" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                </g>
                            </svg>
                        }
                        title='Create User Role'
                        form
                        submit={createRole}
                    >
                        <div className="text-center">
                            <label className="label mb-1">Role name</label>
                            <input value={newRole} onChange={(e) => setNewRole(e.target.value)} type="text" className="form-control ml-auto mr-auto text-center" style={{width: '100%'}}/>
                        </div>
                    </Modal>
                </div>
            </div>
            <div className="roles-table">
                <div className="outer">
                    <div className="inner">
                        <table>
                            <tr>
                                <th className="fix"></th>
                                <th>Profile</th>
                                <th>Logs</th>
                                <th>Projects</th>
                                <th>Social Media</th>
                                <th>Profile</th>
                                <th>Logs</th>
                                <th>Projects</th>
                                <th>Social Media</th>
                                <th>Profile</th>
                                <th>Logs</th>
                                <th>Projects</th>
                                <th>Social Media</th>
                                <th>Profile</th>
                                <th>Logs</th>
                                <th>Projects</th>
                                <th>Social Media</th>
                            </tr>
                            {
                                roles.map(role => (
                                    <tr>
                                        <th className="fix">{role.name}</th>
                                        {
                                            role.permissions.map(permission => {
                                                <td><Checkbox checked={permission.permission == 1 ? true : false}/></td>
                                            })
                                        }
                                    </tr>
                                ))
                            }
                        </table>
                    </div>
                </div>
            </div>
        </DashboardPageLayout>
        </>
    );
}

function Checkbox(props){
    return(
        <div className="permission-input"><CustomInput type="checkbox" id="exampleCustomRadio" checked={props.checked}/></div>
    )
}

function Role(props){

    const [name, setName] = useState();
    const [role, setRole] = useState();
    const token = useSelector(state => state.auth.token);

    useEffect(() => {
        setName(props.role.name)
        setRole(props.role)
    }, [props])

    function remove(e, _callback){
        e.preventDefault();
        api.post(api.defaults.baseURL + '/roles/remove/' + token, {id: props.role.id}).then(res => {
            _callback();
            props.setRoles(props.roles.filter(r => r.id != props.role.id));
        });
    }

    return (
        <Modal
            buttonClass="role"
            buttonText={
                <>
                <div className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                        <path id="Icon_open-person" data-name="Icon open-person" d="M14,0C10.15,0,7,3.92,7,8.75s3.15,8.75,7,8.75,7-3.92,7-8.75S17.85,0,14,0ZM6.685,17.5A7.007,7.007,0,0,0,0,24.5V28H28V24.5a6.983,6.983,0,0,0-6.685-7A9.83,9.83,0,0,1,14,21,9.83,9.83,0,0,1,6.685,17.5Z" fill="#027bea"/>
                    </svg>
                    <span>{props.role.name}</span>
                </div>
                <h1>{name}</h1>
                </>
            }
            title={'Edit Role'}
            form
            submitText={'Save'}
            remove={remove}
        >
        <div className="text-center">
            <label className="label mb-1">Role name</label>
            <input value={name} onChange={(e) => setName(e.target.value)} type="text" className="form-control ml-auto mr-auto text-center" style={{width: '100%'}}/>
        </div>
        </Modal>
    )
}