import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import api from '../../../services/api';
import DashboardPageLayout from '../../../layouts/dashboard-page';
import { Link, useLocation } from 'react-router-dom';
import Header from '../../../components/header';

import {
    DragDropContext,
    Droppable,
    Draggable,
    resetServerContext
  } from 'react-beautiful-dnd'

  import {
    Box,
    Heading,
    Text,
  } from '@chakra-ui/react'

export default function Posts(props){
    const permissions = useSelector(state => state.permissions);
    const token = useSelector(state => state.auth.token);
    const [loading, setLoading] = useState(false);

    const [admin, setAdmin] = useState(true);
    const [searchText, setSearchText] = useState();

    const [tasks, setTasks] = useState([]);
    const location = useLocation();

    useEffect(() => {
        setLoading(true);
        console.log(permissions)
        let ep = permissions.kanban_client ? '/kanban_client/list/' : '/kanban/list/'

        api.post(api.defaults.baseURL + ep + token).then(res => {
            if(res){
                setTasks(res.data);
            }
            setLoading(false);
        })
    }, []);

    return (
        <>
        <Header left={
            <Heading fontWeight="700" fontFamily="poppins">Tasks Kanban</Heading>
        } right={
            <div className="d-flex align-items-center mr-2">
                <div className="leads-page-selector">
                    <Link to="/dashboard/tasks" className={`leads-page-selector-button ${location.pathname === '/dashboard/tasks' && 'leads-page-selector-button-active'}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19">
                            <g id="Group_391" data-name="Group 391" transform="translate(-659 -366)">
                                <rect id="Rectangle_329" data-name="Rectangle 329" width="5" height="20" rx="1" transform="translate(659 378) rotate(-90)" fill="#d0d2e1"/>
                                <rect id="Rectangle_459" data-name="Rectangle 459" width="5" height="20" rx="1" transform="translate(659 385) rotate(-90)" fill="#d0d2e1"/>
                                <rect id="Rectangle_330" data-name="Rectangle 330" width="5" height="20" rx="1" transform="translate(659 371) rotate(-90)" fill="#d0d2e1"/>
                            </g>
                        </svg>
                    </Link>
                    <Link to="/dashboard/tasks/kanban" className={`leads-page-selector-button ${location.pathname === '/dashboard/tasks/kanban' && 'leads-page-selector-button-active'}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18">
                            <g id="Group_392" data-name="Group 392" transform="translate(-663 -366)">
                                <rect id="Rectangle_329" data-name="Rectangle 329" width="7" height="18" rx="1" transform="translate(663 366)" fill="#d0d2e1"/>
                                <rect id="Rectangle_330" data-name="Rectangle 330" width="7" height="13" rx="1" transform="translate(672 366)" fill="#d0d2e1"/>
                            </g>
                        </svg>
                    </Link>
                </div>

                <form id="leads-page-search-form">
                    <input type="text" id="leads-page-search" placeholder="Search" onChange={(e) => setSearchText(e.target.value)}/>
                </form>
            </div>
        }/>
        <DashboardPageLayout title={'Tasks'} subtitle={'Tasks to do'} notBoxed={true}>
            <div id="kanban-page">
                {
                    false &&
                    <div className="d-flex justify-content-between p-3 disable" id="kanban-form">
                        <div className="form-inline">
                            <label for="titleInput">Title:</label>
                            <input className="form-control form-control-sm" type="text" name="title" id="titleInput" autocomplete="off"/>
                            <label for="descriptionInput">Description:</label>
                            <input className="form-control form-control-sm" type="text" name="description" id="descriptionInput" autocomplete="off"/>
                            <label for="descriptionInput">Picture:</label>
                            <input className="form-control form-control-sm" type="file" name="picture" id="kanbanPictureInput" autocomplete="off"/>
                            <div>
                                <button className="dashboard-button primary-button" id="add">Add</button>
                                <div className="spinner-border text-primary ml-3 disable" id="addKanbanLoading" role="status"><span className="sr-only">Loading...</span></div>
                            </div>
                        </div>
                        <div className="form-inline">
                            <label for="selectClientId" className="mr-1">Client:</label>
                            <select id="selectClientId" className="form-control form-control-sm">
                                <option value="1">Joey Gilbert</option>
                                <option value="1">Joey Gilbert</option>
                                <option value="1">Joey Gilbert</option>
                                <option value="1">Joey Gilbert</option>
                            </select>
                        </div>
                    </div>
                }
                {
                    /*
                    loading ?
                    <div className="p-5 w-100 text-center" id="kanbanLoading">
                        <div className="spinner-border text-primary ml-3 my-3" role="status"><span className="sr-only">Loading...</span></div>
                    </div>
                    :
                    <div className="boards overflow-auto p-0 disable" id="boardsContainer">
                        <div className="board">
                            <h3 className="text-center blue-bg">TO DO</h3>
                            <div className="dropzone" id="blue">
                                {
                                    tasks.map(task => (
                                        task.status_kanban_id === '1' &&
                                        <div id='cardid' className="kanbanCard" draggable="true" key={'task-'+task.id}>
                                            <div className="content">
                                                <h4 className="task-status-area">TO DO - <span className="task-date">08/01</span></h4>
                                                <div className="d-flex">
                                                    <div>
                                                        <h4 className="title">{task.title}</h4>
                                                        <p className="description">{task.description}</p>
                                                    </div>
                                                </div>               
                                            </div>
                                            {admin && <button className="invisibleBtn editBtn"> <i className="fas fa-edit"></i> </button> }
                                            {admin && <button className="invisibleBtn deleteBtn"> <i className="fas fa-minus-circle"></i> </button>}
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
    
                        <div className="board">
                            <h3 className="text-center yellow-bg">DOING</h3>
                            <div className="dropzone" id="yellow">
                                {
                                    tasks.map(task => (
                                        task.status_kanban_id === '2' &&
                                        <div id='cardid' className="kanbanCard" draggable="true" key={'task-'+task.id}>
                                            <div className="content">
                                                <h4 className="task-status-area">TO DO - <span className="task-date">08/01</span></h4>
                                                <div className="d-flex">
                                                    <div>
                                                        <h4 className="title">{task.title}</h4>
                                                        <p className="description">{task.description}</p>
                                                    </div>
                                                </div>               
                                            </div>
                                            {admin && <button className="invisibleBtn editBtn"> <i className="fas fa-edit"></i> </button> }
                                            {admin && <button className="invisibleBtn deleteBtn"> <i className="fas fa-minus-circle"></i> </button>}
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
    
                        <div className="board">
                            <h3 className="text-center green-bg">DONE</h3>
                            <div className="dropzone" id="green">
                                {
                                    tasks.map(task => (
                                        task.status_kanban_id === '3' &&
                                        <div id='cardid' className="kanbanCard" draggable="true" key={'task-'+task.id}>
                                            <div className="content">
                                                <h4 className="task-status-area">TO DO - <span className="task-date">08/01</span></h4>
                                                <div className="d-flex">
                                                    <div>
                                                        <h4 className="title">{task.title}</h4>
                                                        <p className="description">{task.description}</p>
                                                    </div>
                                                </div>               
                                            </div>
                                            {admin && <button className="invisibleBtn editBtn"> <i className="fas fa-edit"></i> </button> }
                                            {admin && <button className="invisibleBtn deleteBtn"> <i className="fas fa-minus-circle"></i> </button>}
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>*/
                }

                {
                    !loading &&
                    <Boards admin={admin} items={tasks}/>
                }
            </div>
        </DashboardPageLayout>
        </>
    );
}

const Boards = (props) => {
    const token = useSelector(state => state.auth.token);
    const permissions = useSelector(state => state.permissions);

    resetServerContext()

    const itemsFromBackend = [
        {
            id: '1232312',
            content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        },
        {
            id: '123432',
            content:
            'Duis pulvinar, tellus non scelerisque venenatis, diam augue pellentesque odio'
        }
    ]

    const [columns, setColumns] = useState(null)

    useEffect(() => {
        const todo = [];
        const doing = [];
        const done = [];

        props.items.map(item => {
            if(item.status_kanban_id == '1'){
                todo.push(item);
            } else if(item.status_kanban_id == '2'){
                doing.push(item);
            } else {
                done.push(item);
            }
        })
        
        setColumns({
            [1]: {
                name: 'Todo',
                color: '#6DDAF7',
                items: todo,
            },
            [2]: {
                name: 'Doing',
                color: '#F5D202',
                items: doing
            },
            [3]: {
                name: 'Done',
                color: '#0CBC20',
                items: done
            }
        })
    }, []);
    
    const onDragEnd = (result, columns, setColumns) => {
        if(permissions.kanban){
            if (!result.destination || !props.admin) return
            const { source, destination } = result
            if (source.droppableId != destination.droppableId) {
              const sourceColumn = columns[source.droppableId]
              const destColumn = columns[destination.droppableId]
              const sourceItems = [...sourceColumn.items]
              const destItems = [...destColumn.items]
              const [removed] = sourceItems.splice(source.index, 1)
              destItems.splice(destination.index, 0, removed)
              setColumns({
                ...columns,
                [source.droppableId]: {
                  ...sourceColumn,
                  items: sourceItems
                },
                [destination.droppableId]: {
                  ...destColumn,
                  items: destItems
                }
              })
              console.log(columns[source.droppableId].items[source.index])
              console.log(destination)
              api.post(api.defaults.baseURL + `/kanban/update_status/` + token, {
                    id: columns[source.droppableId].items[source.index].id,
                    status_kanban_id: destination.droppableId
              })
            } else {
              const column = columns[source.droppableId]
              const copiedItems = [...column.items]
              const [removed] = copiedItems.splice(source.index, 1)
              copiedItems.splice(destination.index, 0, removed)
              setColumns({
                ...columns,
                [source.droppableId]: {
                  ...column,
                  items: copiedItems
                }
              })
            }
        }
      }
    
    return (
        columns &&
        <Box d="flex" height="calc(100vh - 120px)" p="25px">
            <DragDropContext
                onDragEnd={result => onDragEnd(result, columns, setColumns)}
            >
                {Object.entries(columns).map(([id, column]) => {
                return (
                    <Box
                    bg="#ECEDF6"
                    px="10px"
                    pt="10px"
                    pb="30px"
                    mr="10px"
                    w="33%"
                    borderRadius="3px"
                    >
                <Box textAlign="center" p="10px" bg={column.color} mb="20px" borderRadius="5px">
                    <Heading size="md" color="white">
                        {column.name}
                    </Heading>
                </Box>
                <Droppable droppableId={id} key={id}>
                    {(provided, snapshot) => {
                    return (
                        <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                            background: 'transparent',
                            height: '90%',
                            overflowY: 'scroll'
                        }}
                        >
                        {column.items.map((item, index) => {
                            return (
                            <Draggable
                                key={item.id}
                                draggableId={item.id}
                                index={index}
                              >
                                {(provided, snapshot) => {
                                return (
                                    <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                        userSelect: 'none',
                                        padding: 16,
                                        margin: '0 0 8px 0',
                                        minHeight: '50px',
                                        backgroundColor: snapshot.isDragging
                                        ? '#F7FAFC'
                                        : 'white',
                                        color: 'black',
                                        borderRadius: '5px',
                                        ...provided.draggableProps.style,
                                        cursor: 'pointer'
                                    }}
                                    >
                                    <Box d="flex">
                                        <Box>
                                        <Box
                                            mb="10px"
                                            d="inline-block"
                                            borderRadius="1px"
                                            px="5px"
                                            py="4px"
                                            fontSize="10px"
                                            bg="blue.100"
                                        >
                                            24/10/2021
                                        </Box>
                                        <Text fontSize="md">
                                            {item.title}
                                        </Text>
                                        </Box>
                                    </Box>
                                    </div>
                                )
                                }}
                            </Draggable>
                            )
                        })}
                        {provided.placeholder}
                        </div>
                    )
                    }}
                </Droppable>
                </Box>
            )
            })}
        </DragDropContext>
    </Box>
    )
}