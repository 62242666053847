import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from 'framer-motion';

export default function Component(props) {

    const [edit, setEdit] = useState(false);

    useEffect(() => {
    }, []);

    function update(e){
        if(e)
            e.preventDefault();
        props.updateFunction();
        setEdit(false);
    }

    const animation = {
        in: order => ({
            opacity: 1,
            y: 0,
            transition: { type: 'linear', delay: order * 0.1 },
        }),
        out: order => ({
            opacity: 0,
            y: 20,
            transition: { type: 'linear', delay: order * 0.05 },
        }),
    }

    const inputAnimation = {
        in: {
            opacity: 1,
            x: 0,
            transition: 'linear',
        },
        out: {
            opacity: 0,
            x: 20,
            transition: 'linear',
        },
    }

    return (
        <form onSubmit={update}>
            <motion.div
                initial="out"
                animate="in"
                exit="out"
                variants={animation} 
                custom={props.order ? props.order : 1}
                className="form-group mb-3"
            >
                {
                    props.label &&
                    <label 
                        className="profile-label" 
                        for="profile-username-input"
                    >
                        {props.label}
                    </label>
                }
                <div className="profile-data-wrapper">
                    <AnimatePresence>
                        {
                            edit &&
                            <motion.div
                                initial="out"
                                animate="in"
                                exit="out"
                                variants={inputAnimation} 
                                className="profile-input-wrapper"
                            >
                                <input className="profile-input" type="text" name="username" id="profile-username-input" value={props.value} onChange={(e) => props.changeFunction(e.target.value)} autoFocus />
                                <div className="profile-input-button profile-input-button-save" data-target="username" onClick={() => update()}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                    </svg>
                                </div>
                            </motion.div>
                        }
                    </AnimatePresence>
                    <AnimatePresence>
                        {
                            !edit &&
                            <motion.div 
                                initial="out"
                                animate="in"
                                exit="out"
                                variants={inputAnimation} 
                                className={props.wrapperClass ? props.wrapperClass : 'profile-placeholder-wrapper'}
                            >
                                <span className={props.valueClass ? props.valueClass : 'profile-placeholder'} id="profile-username-placeholder">{props.value}</span>
                                <div className="profile-edit-button" data-placeholder-id="profile-username-placeholder-wrapper" data-input-id="profile-username-input" onClick={() => setEdit(true)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17.645" height="17.485" viewBox="0 0 17.645 17.485">
                                        <g id="Icon_feather-edit" data-name="Icon feather-edit" transform="translate(-2 -1.818)">
                                            <path id="Path_147" data-name="Path 147" d="M9.926,6H4.539A1.539,1.539,0,0,0,3,7.539V18.314a1.539,1.539,0,0,0,1.539,1.539H15.314a1.539,1.539,0,0,0,1.539-1.539V12.926" transform="translate(0 -1.549)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                                            <path id="Path_148" data-name="Path 148" d="M20.081,3.3A1.633,1.633,0,0,1,22.39,5.6l-7.311,7.311L12,13.686l.77-3.078Z" transform="translate(-4.382)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                                        </g>
                                    </svg>
                                </div>
                            </motion.div>
                        }
                    </AnimatePresence>
                </div>
            </motion.div>
        </form>
    );
}