import React, { useEffect, useState, createContext } from "react";
import {
  Link,
  Redirect
} from "react-router-dom";

import { Container, Row, Col  } from 'reactstrap';

import DashboardLayout from '../../../layouts/dashboard';

import Card from '../../../components/card';
import LatestPosts from '../../../components/home/latest_posts';
import HomePages from '../../../components/home/pages';
import Reports from '../../../components/home/reports';
import Deliveries from '../../../components/home/deliveries';
import MediaStatsCard from '../../../components/media-stats-card';
import SocialCard from '../../../components/home/social-card';
import BestPosts from '../../../components/home/best-posts';
import MyDrive from '../../../components/home/my-drive';

import api from '../../../services/api';
import { useSelector } from 'react-redux';

import { motion } from 'framer-motion';

import { useDispatch } from 'react-redux';
import { setName, setWeather, setLogo } from '../../../store/modules/data/actions';
import { setHeader } from '../../../store/modules/layout/actions';
import Header from '../../../components/header';
import { Heading, Select, Box } from '@chakra-ui/react';

export default function View(props) {

    const [loading, setLoading] = useState(true);
    const token = useSelector(state => state.auth.token);

    const [metricsTotal, setMetricsTotal] = useState({});

    const [socialMediaDatas, setSocialMediaDatas] = useState([]);
    const [totalReports, setTotalReports] = useState({
        followers: [],
        likes: [],
        shares: [],
        comments: [],
        days: [],
    });

    const dispatch = useDispatch();
    const permissions = useSelector(state => state.permissions);
    const username = useSelector(state => state.data.name);
    const weather = useSelector(state => state.data.weather);

    useEffect(() => {
        if(!permissions.admins)
            api.post(api.defaults.baseURL + '/dashboard/dashboard/' + token).then(res => {

                if(res){
                    const { data } = res;
        
                    dispatch(setName(data.company.name));
                    dispatch(setLogo(data.company.logo));
        
                    setMetricsTotal({
                        followers: data.folowers,
                        likes: data.likes,
                        shares: data.shares,
                        comments: data.comments,
                    });
        
                    setTotalReports({
                        followers: data.totalReports[0].values,
                        likes: data.totalReports[1].values,
                        shares: data.totalReports[2].values,
                        comments: data.totalReports[3].values,
                        days: data.totalReports[0].days
                    });
                    dispatch(setWeather({
                        location: data.company.address,
                        temperature: data.company.temperature,
                        description: data.company.temperature_description,
                        icon: data.company.temperature_icon,
                        timezone: data.company.zone,
                    }))
        
                    var socialMediasArr = [];
        
                    if((data.facebook_followers && data.facebook_shares && data.facebook_likes && data.facebook_comments))
                        socialMediasArr.push({
                            media: 'facebook',
                            data1: data.facebook_comments,
                            label1: 'Comments',
                            data2: data.facebook_followers,
                            label2: 'Followers',
                            data3: data.facebook_likes,
                            label3: 'Likes',
                            data4: data.facebook_shares,
                            label4: 'Shares',
                        })
                        
                    if((data.instagram_comments && data.instagram_followers && data.instagram_likes && data.instagram_posts))
                        socialMediasArr.push({
                            media: 'instagram',
                            data1: data.instagram_comments,
                            label1: 'Comments',
                            data2: data.instagram_followers,
                            label2: 'Followers',
                            data3: data.instagram_likes,
                            label3: 'Likes',
                            data4: data.instagram_posts,
                            label4: 'Posts',
                        })
        
                    if((data.twitter_followers && data.twitter_posts && data.twitter_likes && data.twitter_comments))
                        socialMediasArr.push({
                            media: 'twitter',
                            data1: data.twitter_comments,
                            label1: 'Comments',
                            data2: data.twitter_followers,
                            label2: 'Followers',
                            data3: data.twitter_likes,
                            label3: 'Likes',
                            data4: data.twitter_posts,
                            label4: 'Posts',
                        })
        
                    if((data.pinterest_followers && data.pinterest_posts && data.pinterest_likes && data.pinterest_comments))
                        socialMediasArr.push({
                            media: 'pinterest',
                            data1: data.pinterest_comments,
                            label1: 'Comments',
                            data2: data.pinterest_followers,
                            label2: 'Followers',
                            data3: data.pinterest_likes,
                            label3: 'Likes',
                            data4: data.pinterest_posts,
                            label4: 'Shares',
                        })
        
                    if((data.youtube_followers && data.youtube_posts && data.youtube_likes && data.youtube_comments))
                        socialMediasArr.push({
                            media: 'youtube',
                            data1: data.youtube_comments,
                            label1: 'Comments',
                            data2: data.youtube_followers,
                            label2: 'Followers',
                            data3: data.youtube_likes,
                            label3: 'Likes',
                            data4: data.youtube_posts,
                            label4: 'Videos',
                        })
        
                    setSocialMediaDatas(socialMediasArr);
                
                    setLoading(false);
                }
            });
    }, []);

    return (
        loading ?
        <motion.div></motion.div>
        :
        <>
            <Header
                left={
                    <span>
                        <h1>Hi, <strong>{username}</strong>. Welcome back!</h1>
                    </span>
                }
                right={
                    <Box d="flex" align="center" mt="7px" mr="3">
                        <Box d="flex" align="center" ml="auto" width="250px" maxWidth="100%">
                            <Select placeholder="Dashboard Selected" bg="white" border="0" height="45px">
                                <option value="option1">Option 1</option>
                                <option value="option2">Option 2</option>
                                <option value="option3">Option 3</option>
                            </Select>
                        </Box>
                        <div id="weather-infos" className="mr-3 d-none">
                            <div id="weather-title">
                                <span id="city">{weather.location}</span>, <span id="time">{new Date().toLocaleTimeString("en-US", { hour: '2-digit', minute: '2-digit', timeZone: weather.timezone })}</span>
                            </div>
                            <div id="weather-subtitle">
                                <span id="weather-temperature" className="mr-2"></span>
                                <img src={weather.icon} id="weather-image" className="weather-image"/>
                                <span id="wather-text">{weather.description}</span>
                            </div>
                        </div>
                    </Box>
                }
            />
            <Container fluid>
                <Row>
                    <Col className="p-0" lg={3}>
                        <MediaStatsCard order={1} color={'purple-blue'} metrics={totalReports.followers} days={totalReports.days} metric={'followers'} total={metricsTotal.followers}/>
                    </Col>
                    <Col className="p-0" lg={3}>
                        <MediaStatsCard order={2} color={'pink-purple'} metrics={totalReports.likes} days={totalReports.days} metric={'likes'} total={metricsTotal.likes}/>
                    </Col>
                    <Col className="p-0" lg={3}>
                        <MediaStatsCard order={3} color={'pink'} metrics={totalReports.shares} days={totalReports.days} metric={'shares'} total={metricsTotal.shares}/>
                    </Col>
                    <Col className="p-0" lg={3}>
                        <MediaStatsCard order={4} color={'yellow'} metrics={totalReports.comments} days={totalReports.days} metric={'comments'} total={metricsTotal.comments}/>
                    </Col>
                </Row>
            </Container>
            
            <div id={{
                1: 'home-top-grid1',
                2: 'home-top-grid2',
                3: 'home-top-grid3',
                4: 'home-top-grid4',
                5: 'home-top-grid5',
                6: 'home-top-grid6'
            }[socialMediaDatas.length]}>
                {
                    socialMediaDatas.map((media, index) => (
                        <SocialCard order={index + 1} data={media} id={`stats${index + 1}`} key={'social-card-'+index}/>
                    ))
                }
                <BestPosts/>
                <Card order={8} title={'My Drive'} icon={'fas fa-copy'} color={'blue'} id="my-drive">
                    <MyDrive length={socialMediaDatas.length}/>
                </Card>
            </div>

            <div id="home-grid2">
                <Card order={8} title={'Last Deliveries'} icon={'fas fa-search'} color={'pink-purple'} id="home-logs">
                    <Deliveries/>
                </Card>

                <Reports/>
                
                <Card order={10} title={'Latest Posts'} icon={'fas fa-search'} color={'blue'} id="latest">
                    <LatestPosts/>
                </Card>
                <Card order={11} title={'Pages'} icon={'fas fa-search'} color={'yellow'} id="home-pages" headerRight={
                    <div className="d-flex locked-content" style={{fontSize: 14}}>
                        <button className="page-button" id="pages-left-arrow">
                            <i className="fas fa-arrow-left"></i>
                        </button>
                        <button className="page-button" id="pages-right-arrow">
                            <i className="fas fa-arrow-right"></i>
                        </button>
                    </div>
                }>
                    <HomePages/>
                </Card>
            </div>
        </>
    );
}