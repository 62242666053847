import React, { useState } from "react";
import {
  useLocation
} from "react-router-dom";

import { AnimatePresence } from "framer-motion";
import Menu from '../../components/menu';

import { useSelector } from 'react-redux';

export default function Layout(props) {

    const location = useLocation();

    const [openMenu, setOpenMenu] = useState(true);

    return (
        <AnimatePresence exitBeforeEnter>
            {
                location.pathname == '/' ?
                props.children
                :
                <div className="c-app">
                    <Menu openMenu={openMenu} setOpenMenu={setOpenMenu}/>
    
                    <div className="c-wrapper">
                        <div>
                            <button  onClick={() => setOpenMenu(true)} className="c-header-toggler c-className-toggler d-lg-none mfe-auto" type="button" data-target="#sidebar" data-classname="c-sidebar-show">
                                <i className="fas fa-bars"></i>
                            </button>
                        </div>
            
    
                        <div className="c-body">
                            {props.children}
                        </div>
                    </div>
                </div>
            }
        </AnimatePresence>
    );
}