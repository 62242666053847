import React, { useEffect, useState } from "react";
import PostBlog from "../../../components/post";
import { useSelector, useDispatch } from "react-redux";
import api from "../../../services/api";
import { AnimatePresence } from "framer-motion";
import DashboardPageLayout from "../../../layouts/dashboard-page";
import { Modal } from "reactstrap";
import Header from "../../../components/header";
import { Heading, Select, Box } from "@chakra-ui/react";
import PostModal from "../../../components/PostModal";

export default function Posts(props) {
     const [loading, setLoading] = useState(false);
     const [posts, setPosts] = useState([]);

     const token = useSelector((state) => state.auth.token);
     const dispatch = useDispatch();

     const [categories, setCategories] = useState([]);
     const [category, setCategory] = useState(false)

     useEffect(() => {
          setLoading(true);

          api.post(api.defaults.baseURL + "/post_blog/list/" + token).then(
               (res) => {
                    if (res != 0) {
                         setPosts(res.data.posts);
                         setCategories(res.data.categories);
                    }
                    setLoading(false);
               }
          );
     }, []);

     const [postModal, setPostModal] = useState(false);

     const togglePostModal = () => {
          setPostModal(!postModal);
     };

     const [openedPost, setOpenedPost] = useState({});

     const openPost = (post) => {
          setOpenedPost(post);
          togglePostModal();
     };

     function changeStatus(id, status) {
          api.post(api.defaults.baseURL + "/post_blog/update_status/" + token, {
               id: id,
               status_post_id: status,
          }).then((res) => {});

          var aux = posts;
          for (let i = 0; i < aux.length; i++) {
               if (aux[i].id == id) {
                    aux[i].status_post_id = status;
               }
          }

          setPosts([...aux]);
     }

     return (
          <>
               <Header
                    left={
                         <Heading fontWeight="700" fontFamily="poppins">
                              Social Media Posts
                         </Heading>
                    }
               />
               <DashboardPageLayout
                    title={"Approve or disapprove posts for your social medias"}
                    headerColor={"pink-header"}
               >
                    <Box
                         d="flex"
                         align="center"
                         ml="auto"
                         mb="6"
                         maxWidth="350px"
                    >
                         <Select
                              placeholder="Select a category"
                              bg="gray.100"
                              border="0"
                              height="45px"
                              bg="#ECEDF6"
                              onChange={(e) => setCategory(e.target.value)}
                         >
                              {
                                   categories.map(cat => (
                                        <option value={cat.id}>{cat.name}</option>
                                   ))
                              }
                         </Select>
                    </Box>
                    <div className="post-blog-grid">
                         <AnimatePresence>
                              {posts.map((post, index) => (
                                   ((post.post_category_id == category) || !category) &&
                                   <PostBlog
                                        post={post}
                                        changeStatus={changeStatus}
                                        order={index}
                                        openPost={openPost}
                                        key={"post-blog-" + index}
                                   />
                              ))}
                         </AnimatePresence>
                    </div>
                    <PostModal
                         open={postModal}
                         toggle={togglePostModal}
                         post={openedPost}
                         setPosts={setPosts}
                         posts={posts}
                         changeStatus={changeStatus}
                    />
               </DashboardPageLayout>
          </>
     );
}
