import React, { useEffect, useState } from "react";

import api from '../../../services/api';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function HomeLatestPosts (props) { 
    const token = useSelector(state => state.auth.token);

    const [files, setFiles] = useState([]);
    const [uploadFiles, setUploadFiles] = useState([]);

    const [loading, setLoading] = useState(true);

    const [submiting, setSubmiting] = useState(false);

    const username = useSelector(state => state.data.name);

    useEffect(() => {
        api.post(api.defaults.baseURL + '/vault/list/' + token).then(res => {
            if(res){
                let images = res.data;
                let count = 0;
    
                let imagesArr = [];
    
                console.log(images);
    
                let arr = [];
                for(let i = 0; i < images.length; i++){
                    if(count < 10){
                        var format = images[i].link.split('.')[2];
            
                        var image;
            
                        if(format == 'pdf'){
                            image = '/images/pdf-file.svg';
                        } else if(format == 'docx'){
                            image = '/images/docx-file.svg';
                        } else {
                            image = images[i].link;
                        }
            
                        arr.push(image);
                        count++;
                    } else {
                        imagesArr.push(arr);
                        arr = [];
                        count = 0;
                    }
                }
    
                setFiles(imagesArr);
    
                setLoading(false);
            }
        });
    }, []);

    useEffect(() => {
        console.log(uploadFiles);
    }, [uploadFiles])

    const animation = {
        in: index => ({
            opacity: 1,
            y: 0,
            transition: { type: 'linear', delay: (props.length + 1) * 0.1 },
        }),
        out: index => ({
            opacity: 0,
            y: 20,
            transition: { type: 'linear', delay: (props.length + 1) * 0.05 },
        }),
    }

    function submit(){
        const formData = new FormData();
        formData.append('file', document.getElementById('dropped-image-input').files[0]);

        if(document.getElementById('dropped-image-input').files.length > 0){
            setSubmiting(true);
            api.post(api.defaults.baseURL + '/vault/upload/' + token, formData).then(res => {
                console.log(res);
                toast.success('File uploaded successfull');
                setSubmiting(false);
                setUploadFiles([]);
            });
        }
    }

    return (
        !loading &&
        <motion.div 
            initial="out"
            animate="in"
            exit="out"
            variants={animation} 
        >
            <div className="my-drive h-100">
                <div className="container-fluid h-100" style={{transform: 'translateY(-20px)'}}>
                <div className="row d-flex align-items-stretch h-100">
                    {
                        files.length > 0 ?
                        <>
                            <div className={props.length%2 == 0 ? 'col-12 col-lg-6' : 'col-12' }>
                                <div className="drive-photos">
                                    <div id="drive-photos-carousel" className="carousel slide" data-ride="carousel">
                                                <div className="carousel-inner" id="drive-photos-carousel-inner">
                                        {
                                            files.map((list, index) => (
                                                <div className={`carousel-item ${index == 0 ? 'active' : ''}`}>
                                                    <div className="drive-photos-grid">
                                                        {
                                                            list.map(file => (
                                                                <div class="drive-photo">
                                                                    <a href="#" class="drive-photo-content">
                                                                        <img src={file}/>
                                                                    </a>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            ))
                                        }
                                        </div>
                                    </div>
                                </div>
                                <div className="drive-photos-control" style={{paddingRight: '0.5%', paddingLeft: '0.5%'}}>
                                    <a className="page-button" href="#drive-photos-carousel" role="button" data-slide="prev">
                                        <i className="fas fa-arrow-left"></i>
                                    </a>
                                    <span id="my-drive-company-name" style={{textTransform: 'uppercase'}}>{ username ? username.toUpperCase() : ''}</span>
                                    <a className="page-button" href="#drive-photos-carousel" role="button" data-slide="next">
                                        <i className="fas fa-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </>
                        :
                        <div className={props.length%2 == 0 ? "my-drive-upload-box col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center" : "my-drive-upload-box col-12 d-flex flex-column justify-content-center align-items-center" }>
                            <div className="mb-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="22.542" height="22.542" viewBox="0 0 22.542 22.542">
                                    <path id="Icon_ionic-md-image" data-name="Icon ionic-md-image" d="M27.042,24.538V7a2.512,2.512,0,0,0-2.5-2.5H7A2.512,2.512,0,0,0,4.5,7V24.538a2.512,2.512,0,0,0,2.5,2.5H24.538A2.512,2.512,0,0,0,27.042,24.538ZM11.388,17.65l3.131,3.757L18.9,15.771l5.636,7.514H7Z" transform="translate(-4.5 -4.5)" fill="#cac9d4"/>
                                </svg>
                            </div>
                            <h2 id="upload-image-text" style={{fontSize: 17}}>No files found</h2>
                        </div>
                    }
                    {
                        props.length % 2 == 0 &&
                        <div className="col-12 col-lg-6">
                            <div className="my-drive-upload">
                                <button className="my-drive-upload-box" onClick={() => document.getElementById('dropped-image-input').click()}>
                                    <div className="mb-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="22.542" height="22.542" viewBox="0 0 22.542 22.542">
                                            <path id="Icon_ionic-md-image" data-name="Icon ionic-md-image" d="M27.042,24.538V7a2.512,2.512,0,0,0-2.5-2.5H7A2.512,2.512,0,0,0,4.5,7V24.538a2.512,2.512,0,0,0,2.5,2.5H24.538A2.512,2.512,0,0,0,27.042,24.538ZM11.388,17.65l3.131,3.757L18.9,15.771l5.636,7.514H7Z" transform="translate(-4.5 -4.5)" fill="#cac9d4"/>
                                        </svg>
                                    </div>
                                    <h2 id="upload-image-text" style={{fontSize: 17}}>
                                        {
                                            uploadFiles.length > 0 ?
                                            `${uploadFiles.length} file(s) selected`
                                            :
                                            'Upload Image'
                                        }
                                    </h2>
                                </button>
                                {
                                    submiting ?
                                    <div className="text-center">
                                        <div className="spinner-border text-primary my-3 disable" id="myDriveUploadingIndicator" role="status"><span className="sr-only">Loading...</span></div>
                                    </div>
                                    :
                                    <button className="my-drive-upload-button" onClick={submit}>Submit</button>
                                }
                                <input type="file" className="d-none" id="dropped-image-input" onChange={(e) => setUploadFiles([...e.target.files])}/>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
        </motion.div>
    );
}