const INITIAL_STATE = {
    admins: false,
    billing: false,
    calendar: false,
    campaigns: false,
    client_profile: false,
    client_user: false,
    clients: false,
    competitors: false,
    configs: false,
    dashboard: false,
    facebooks: false,
    grid_pages: false,
    grids: false,
    instagrams: false,
    integrations: false,
    kanban: false,
    kanban_client: false,
    logs: false,
    my_logs: false,
    my_projects: false,
    pages_domain: false,
    password: false,
    persona: false,
    pdf_vault: false,
    pinterests: false,
    post_blog: false,
    projects: false,
    services: false,
    social_media: false,
    social_proof: false,
    social_medias: false,
    social_vault: false,
    twitters: false,
    user_leads: false,
    users: false,
    vault: false,
    youtubes: false,
};

export default function permissions(state = INITIAL_STATE, action) {
    switch (action.type) {
        case '@permissions/SET': {
            return {
                ...state,
                admins: parseInt(action.payload.permissions.admins) ? true : false,
                billing: parseInt(action.payload.permissions.billing) ? true : false,
                calendar: parseInt(action.payload.permissions.calendar) ? true : false,
                campaigns: parseInt(action.payload.permissions.campaigns) ? true : false,
                client_profile: parseInt(action.payload.permissions.client_profile) ? true : false,
                client_user: parseInt(action.payload.permissions.client_user) ? true : false,
                clients: parseInt(action.payload.permissions.clients) ? true : false,
                competitors: parseInt(action.payload.permissions.competitors) ? true : false,
                configs: parseInt(action.payload.permissions.configs) ? true : false,
                dashboard: parseInt(action.payload.permissions.dashboard) ? true : false,
                facebooks: parseInt(action.payload.permissions.facebooks) ? true : false,
                grid_pages: parseInt(action.payload.permissions.grid_pages) ? true : false,
                grids: parseInt(action.payload.permissions.grids) ? true : false,
                instagrams: parseInt(action.payload.permissions.instagrams) ? true : false,
                integrations: parseInt(action.payload.permissions.integrations) ? true : false,
                kanban: parseInt(action.payload.permissions.kanban) ? true : false,
                kanban_client: parseInt(action.payload.permissions.kanban_client) ? true : false,
                logs: parseInt(action.payload.permissions.logs) ? true : false,
                my_logs: parseInt(action.payload.permissions.my_logs) ? true : false,
                my_projects: parseInt(action.payload.permissions.my_projects) ? true : false,
                pages_domain: parseInt(action.payload.permissions.pages_domain) ? true : false,
                password: parseInt(action.payload.permissions.password) ? true : false,
                persona: parseInt(action.payload.permissions.persona) ? true : false,
                pdf_vault: parseInt(action.payload.permissions.pdf_vault) ? true : false,
                pinterests: parseInt(action.payload.permissions.pinterests) ? true : false,
                post_blog: parseInt(action.payload.permissions.post_blog) ? true : false,
                projects: parseInt(action.payload.permissions.projects) ? true : false,
                services: parseInt(action.payload.permissions.services) ? true : false,
                social_vault: parseInt(action.payload.permissions.social_vault) ? true : false,
                social_media: parseInt(action.payload.permissions.social_media) ? true : false,
                social_medias: parseInt(action.payload.permissions.social_medias) ? true : false,
                social_proof: parseInt(action.payload.permissions.social_proof) ? true : false,
                twitters: parseInt(action.payload.permissions.twitters) ? true : false,
                user_leads: parseInt(action.payload.permissions.user_leads) ? true : false,
                users: parseInt(action.payload.permissions.users) ? true : false,
                vault: parseInt(action.payload.permissions.vault) ? true : false,
                youtubes: parseInt(action.payload.permissions.youtubes) ? true : false,
            }
        }
        case '@permissions/RESET': {
            return {
                ...state,
                admins: false,
                calendar: false,
                campaigns: false,
                client_profile: false,
                client_user: false,
                clients: false,
                competitors: false,
                configs: false,
                dashboard: false,
                facebooks: false,
                grid_pages: false,
                grids: false,
                instagrams: false,
                integrations: false,
                kanban: false,
                kanban_client: false,
                logs: false,
                my_logs: false,
                my_projects: false,
                pages_domain: false,
                password: false,
                pdf_vault: false,
                pinterests: false,
                post_blog: false,
                projects: false,
                social_media: false,
                social_medias: false,
                twitters: false,
                user_leads: false,
                users: false,
                vault: false,
                youtubes: false,
            }
        }
        default:
            return state;
    }
}