const INITIAL_STATE = {
    header: null,
};

export default function layout(state = INITIAL_STATE, action) {
    switch (action.type) {
        case '@layout/SETHEADER': {
            return {
                ...state,
                header: action.payload.header
            }
        }
        case '@layout/RESETHEADER': {
            return {
                ...state,
                header: null
            }
        }
        default:
            return state;
    }
}