import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { motion } from 'framer-motion';

const Component = (props) => {

    const [size, setSize] = useState();

    useEffect(() => {
      if(props.size == 'lg'){
          setSize('modal-lg');
      } else if(props.size == 'xl'){
          setSize('modal-xl');
      }
    }, [])

    const [modal, setModal] = useState(false);

    const [loading, setLoading] = useState(false);

    const toggle = () => {
        setModal(!modal);
        if(props.toggle)
            props.toggle();
    }

    const submit = (e) => {
        setLoading(true);
        props.submit(e, function(){
            setLoading(false);
            toggle();
        });
    }

    const remove = (e) => {
        props.remove(e, function(){
            setLoading(false);
            toggle();
        })
    }

  return (
    <>
        <motion.button className={props.buttonClass} onClick={toggle} whileTap={{scale: 0.8}}>{props.buttonText}</motion.button>
        <Modal isOpen={modal} toggle={toggle} className={size}>
            <div className="modal-header justify-content-start justify-content-lg-center">
                <div className="d-flex justify-content-center">
                    {props.icon}
                    <h2 className="ml-2 mb-0">
                        <span>{props.title}</span>
                    </h2>
                </div>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" id="modal-header-close-button" onClick={toggle}>
                    <span aria-hidden="true" className="modal-close-button">
                        <svg xmlns="http://www.w3.org/2000/svg" width="27.269" height="27.269" viewBox="0 0 27.269 27.269">
                            <path id="Icon_awesome-plus" data-name="Icon awesome-plus" d="M17.905,9.825h-6.2v-6.2A1.377,1.377,0,0,0,10.33,2.25H8.953A1.377,1.377,0,0,0,7.575,3.627v6.2h-6.2A1.377,1.377,0,0,0,0,11.2V12.58a1.377,1.377,0,0,0,1.377,1.377h6.2v6.2a1.377,1.377,0,0,0,1.377,1.377H10.33a1.377,1.377,0,0,0,1.377-1.377v-6.2h6.2a1.377,1.377,0,0,0,1.377-1.377V11.2A1.377,1.377,0,0,0,17.905,9.825Z" transform="translate(-1.591 12.044) rotate(-45)" fill="#c15dd3"/>
                        </svg>
                    </span>
                </button>
            </div>
            {
                props.form ?
                <form onSubmit={submit}>
                    <ModalBody>
                        {props.children}
                    </ModalBody>
                    <ModalFooter>
                        {
                            loading ?
                            <div className="d-flex align-items-center justify-content-center w-100">
                                <div className="spinner-border text-primary ml-3 my-3 disable" id="add-lead-modal-spinner" role="status"><span className="sr-only">Loading...</span></div>
                            </div>
                            :
                            <div className="d-flex align-items-center justify-content-center w-100">
                                {
                                    props.remove &&
                                    <button type="button" onClick={remove} className="dashboard-button danger-button modal-function-button mx-1">Delete</button>
                                }
                                <button type="submit" className="dashboard-button primary-button modal-function-button mx-1">{props.submitText ? props.submitText : 'Submit'}</button>
                            </div>
                        }
                    </ModalFooter>
                </form>
                :
                <ModalBody>
                    {props.children}
                </ModalBody>
            }
        </Modal>
    </>
  );
}
export default Component;