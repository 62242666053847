import React, { useEffect, useState } from "react";

import { AnimatePresence, motion } from 'framer-motion';
import useOnclickOutside from "react-cool-onclickoutside";
import api from '../../services/api';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Modal from '../modal';
import { FaInstagram } from 'react-icons/fa';

export default function Component(props) {

    const [options, setOptions] = useState(false);

    const token = useSelector(state => state.auth.token);

    const [modal, setModal] = useState(false);

    function toggle(){
        if(modal){
            setOptions(false);
        }
        setModal(!modal);
    }
    
    useEffect(() => {

    }, [])

    const optionsAnimation = {
        in: {
            opacity: 1,
            x: 0,
            transition: { type: 'linear', delay: 0 },
            duration: 5,
        },
        out: {
            opacity: 0,
            x: 20,
            transition: { type: 'linear', delay: 0 },
            duration: 5,
        },
    }

    const ref = useOnclickOutside(() => {
        if(!modal)
            setOptions(false);
    });
    
    const [name, setName] = useState(props.lead.name);
    const [email, setEmail] = useState(props.lead.email);
    const [twitter, setTwitter] = useState(props.lead.twitter);
    const [facebook, setFacebook] = useState(props.lead.facebook);
    const [instagram, setInstagram] = useState(props.lead.instagram);
    const [linkedin, setLinkedin] = useState(props.lead.linkedin);

    function edit(e, _callback){
        e.preventDefault();
        api.post(api.defaults.baseURL + '/user_leads/update/' + token, {
            id: props.lead.id,
            name,
            email,
            twitter,
            facebook,
            linkedin,
            instagram,
        }).then(res => {
            const aux = props.leads;
            for(let i = 0; i < aux.length; i++){
                if(aux[i].id == props.lead.id){
                    aux[i].name = name;
                    aux[i].email = email;
                    aux[i].twitter = twitter;
                    aux[i].facebook = facebook;
                    aux[i].linkedin = linkedin;
                    aux[i].instagram = instagram;
                }
            }
            props.setLeads([...props.leads]);
            _callback();
        });
    }

    function remove(id){
        props.setLeads(props.leads.filter(lead => lead.id != id));
        setOptions(false);
        api.post(api.defaults.baseURL + '/user_leads/delete/' + token, {
            id: id
        }).then(res => {
            toast.success('Lead removed with success');
        });
    }

    function removeImage(id){
        let aux = props.leads;
        for(let i = 0; i < aux.length; i++){
            if(aux[i].id === id){
                aux[i].image = null;
            }
        }
        props.setLeads([...aux]);

        setOptions(false);
        api.post(api.defaults.baseURL + '/user_leads/remove_image/' + token, {
            id: id
        }).then(res => {
            toast.success('Lead image removed with success');
        });
    }

    return (
        <div className="leads-actions-wrappper" ref={ref}>
            <button className="leads-page-card-menu-button" onClick={() => setOptions(!options)}>
                <i className="fas fa-ellipsis-v" aria-hidden="true"></i>
            </button>
            <AnimatePresence>
                {
                    options &&
                    <motion.div 
                        initial="out"
                        animate="in"
                        exit="out"
                        variants={optionsAnimation}
                        className="leads-action-div disable" 
                        id="leads-page-action-id-1630"
                    >
                        <Modal
                            buttonClass={'leads-action-div-button leads-edit-button'}
                            buttonText={'Edit'}
                            title={'Edit lead'}
                            size={'lg'}
                            icon={
                                <div></div>
                            }
                            submit={edit}
                            toggle={toggle}
                            form
                        >
                            <div className="row">
                                <div className="col-12 col-lg-6 d-flex align-items-center mb-3">
                                    <div className="add-lead-form-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                            <path id="Icon_material-person" data-name="Icon material-person" d="M21,21a7.5,7.5,0,1,0-7.5-7.5A7.5,7.5,0,0,0,21,21Zm0,3.75c-5.006,0-15,2.512-15,7.5V36H36V32.25C36,27.262,26.006,24.75,21,24.75Z" transform="translate(-6 -6)" fill="#33323d"></path>
                                        </svg>
                                    </div>
                                    <input type="text" className="add-lead-form-input add-lead-form-input-required" value={name} onChange={(e) => setName(e.target.value)} placeholder="John Doe"/>
                                </div>
                                <div className="col-12 col-lg-6 d-flex align-items-center mb-3">
                                    <div className="add-lead-form-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="24" viewBox="0 0 30 24">
                                            <path id="Icon_material-email" data-name="Icon material-email" d="M30,6H6A3,3,0,0,0,3.015,9L3,27a3.009,3.009,0,0,0,3,3H30a3.009,3.009,0,0,0,3-3V9A3.009,3.009,0,0,0,30,6Zm0,6L18,19.5,6,12V9l12,7.5L30,9Z" transform="translate(-3 -6)" fill="#33323d"></path>
                                        </svg>
                                    </div>
                                    <input type="text" className="add-lead-form-input add-lead-form-input-required" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="johndoe@c180.agency"/>
                                </div>
                                <div className="col-12 col-lg-6 d-flex align-items-center mb-3">
                                    <div className="add-lead-form-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="29.256" viewBox="0 0 36 29.256">
                                            <path id="Icon_metro-twitter" data-name="Icon metro-twitter" d="M38.57,8.28a14.767,14.767,0,0,1-4.242,1.163,7.408,7.408,0,0,0,3.247-4.086,14.786,14.786,0,0,1-4.69,1.792A7.393,7.393,0,0,0,20.3,13.886,20.968,20.968,0,0,1,5.077,6.169,7.394,7.394,0,0,0,7.363,16.03a7.356,7.356,0,0,1-3.345-.924c0,.031,0,.062,0,.093a7.39,7.39,0,0,0,5.925,7.242,7.4,7.4,0,0,1-3.335.127,7.393,7.393,0,0,0,6.9,5.129,14.818,14.818,0,0,1-9.173,3.162,14.98,14.98,0,0,1-1.762-.1,20.9,20.9,0,0,0,11.322,3.318c13.585,0,21.014-11.254,21.014-21.014q0-.48-.021-.956A15,15,0,0,0,38.57,8.28Z" transform="translate(-2.571 -4.817)" fill="#00aced"></path>
                                        </svg>
                                    </div>
                                    <input type="text" className="add-lead-form-input" value={twitter} onChange={(e) => setTwitter(e.target.value)} placeholder="twitter.com/johndoe"/>
                                </div>
                                <div className="col-12 col-lg-6 d-flex align-items-center mb-3">
                                    <div className="add-lead-form-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.557" height="28.915" viewBox="0 0 15.557 28.915">
                                            <path id="Icon_zocial-facebook" data-name="Icon zocial-facebook" d="M13.257,15.528V10.006h4.454V7.229a7.267,7.267,0,0,1,1.951-5.118A6.206,6.206,0,0,1,24.389,0h4.424V5.523H24.389A.974.974,0,0,0,23.61,6a1.937,1.937,0,0,0-.347,1.171V10h5.551v5.522H23.262V28.915H17.71V15.528Z" transform="translate(-13.257)" fill="#3b5998"></path>
                                        </svg>
                                    </div>
                                    <input type="text" className="add-lead-form-input" value={facebook} onChange={(e) => setFacebook(e.target.value)} placeholder="facebook.com/johndoe"/>
                                </div>
                                <div className="col-12 col-lg-6 d-flex align-items-center mb-3">
                                    <div className="add-lead-form-icon">
                                        <FaInstagram size={35}/>
                                    </div>
                                    <input type="text" className="add-lead-form-input" value={instagram} onChange={(e) => setInstagram(e.target.value)} placeholder="instagram.com/johndoe"/>
                                </div>
                                <div className="col-12 col-lg-6 d-flex align-items-center mb-3">
                                    <div className="add-lead-form-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="23.505" height="23.505" viewBox="0 0 23.505 23.505">
                                            <path id="Icon_awesome-linkedin-in" data-name="Icon awesome-linkedin-in" d="M5.261,23.505H.388V7.813H5.261ZM2.822,5.672A2.836,2.836,0,1,1,5.644,2.823,2.846,2.846,0,0,1,2.822,5.672ZM23.5,23.505H18.637V15.866c0-1.821-.037-4.155-2.534-4.155-2.534,0-2.922,1.978-2.922,4.024v7.77H8.314V7.813h4.674V9.953h.068a5.121,5.121,0,0,1,4.611-2.534c4.932,0,5.839,3.248,5.839,7.466v8.62Z" transform="translate(0 -0.001)" fill="#0a66c2"></path>
                                        </svg>
                                    </div>
                                    <input type="text" className="add-lead-form-input" value={linkedin} onChange={(e) => setLinkedin(e.target.value)} placeholder="linkedin.com/in/johndoe"/>
                                </div>
                                </div>
                        </Modal>
                        <button className="leads-action-div-button leads-delete-button" onClick={() => remove(props.lead.id)}>Delete</button>
                        {
                            props.lead.image &&
                            <button className="leads-action-div-button leads-delete-image-button" onClick={() => removeImage(props.lead.id)}>Remove image</button>
                        }
                    </motion.div>
                }
            </AnimatePresence>
        </div>
    );
}