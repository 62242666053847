import React, { useEffect } from "react";

import { motion } from 'framer-motion';
import { Heading } from  '@chakra-ui/react';

export default function Layout(props) {

    useEffect(() => {
    }, []);

    const animation = {
        in: {
            opacity: 1,
            transition: { type: "spring", damping: 20}
        },
        out: {
            opacity: 0,
            transition: 'linear',
            duration: 0.3,
        },
    }

    return (
        props.notBoxed ? 
        <motion.div
            initial="out"
            animate="in"
            exit="out"
            variants={animation} 
        >
            {props.children}
        </motion.div>
        :
        <motion.div
            initial="out"
            animate="in"
            exit="out"
            variants={animation} 
        >
            <div className={'view-header ' + (props.headerColor ? props.headerColor : 'yellow-header')}>
                <h2 id="title_modal">{props.title}</h2>
            </div>
            <div className="card custom-page-card" style={{transition: '0.5s'}}>
                <div id="profile-page-content">
                    {
                        (props.subtitle || props.subContent) &&
                        <div className="d-flex justify-content-between">
                            <Heading size="lg">{props.subtitle}</Heading>
                            <div>
                                {props.subContent}
                            </div>
                        </div>
                    }
                    {props.children}
                </div>
            </div>
        </motion.div>
    );
}