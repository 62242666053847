import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useLocation,
  useHistory,
} from "react-router-dom";

import { useDispatch, useSelector } from 'react-redux';
import { loginAction, logoutAction } from './store/modules/auth/actions';
import { resetHeader } from './store/modules/layout/actions';
import { setName, setLogo } from './store/modules/data/actions';
import { toast } from 'react-toastify';

import api from './services/api';

import LoginView from './views/login';
import DashboardHomeView from './views/dashboard/home';
import DashboardProfileView from './views/dashboard/profile';
import DashboardSMArchivingView from './views/dashboard/social-media-archiving';
import DashboardArchivingCalendarView from './views/dashboard/calendars';
import DashboardSocialMedias from './views/dashboard/social-medias';
import DashboardPostBlog from './views/dashboard/post-blog';
import DashboardUsers from './views/dashboard/users';
import DashboardCompetitors from './views/dashboard/competitors';
import DashboardKanban from './views/dashboard/kanban';
import DashboardLeads from './views/dashboard/leads';
import DashboardPages from './views/dashboard/pages';
import DashboardBilling from './views/dashboard/billing';
import DashboardDrive from './views/dashboard/my-drive';
import DashboardAddOns from './views/dashboard/add-ons';
import DashboardAddOn from './views/dashboard/add-on';
import DashboardRoles from './views/dashboard/roles';
import DashboardPlans from './views/dashboard/plans';
import DashboardTasksBoard from './views/dashboard/tasks-board';
import DashboardCompetitorComparison from './views/dashboard/competitors/comparison';
import DashboardPersona from './views/dashboard/persona-generator';

import { AnimatePresence, motion } from 'framer-motion';

import DashboardLayout from './layouts/dashboard';

export default function Routes() {
  const location = useLocation();

  const permissions = useSelector(state => state.permissions);
  const token = useSelector(state => state.auth.token);

  const [redirect, setRedirect] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if(token){
      api.post(api.defaults.baseURL + '/application/role/' + token).then(res => {
        console.log(res);
        if(res){
          if(res.data.role == '6'){
            setRedirect(true);
          } else {
            setRedirect(false);
          }
        }
      });
    }
  }, [token])

    return (
      <DashboardLayout>
        <AnimatePresence exitBeforeEnter>
          <Switch location={location} key={location.pathname}>
              <Route path={"/"} exact component={LoginView}/>
              {
                permissions.dashboard &&
                <PrivateRoute path="/dashboard" exact component={DashboardHomeView}/>
              }
              {
                permissions.client_profile &&
                <PrivateRoute path="/dashboard/profile" exact component={DashboardProfileView}/>
              }
              {
                permissions.social_vault &&
                <PrivateRoute path="/dashboard/social-media-archiving" exact component={DashboardSMArchivingView}/>
              }
              {
                permissions.social_medias &&
                <PrivateRoute path="/dashboard/social-medias" exact component={DashboardSocialMedias}/>
              }
              {
                permissions.calendar &&
                <PrivateRoute path="/dashboard/calendars" exact component={DashboardArchivingCalendarView}/>
              }
              {
                permissions.post_blog &&
                <PrivateRoute path="/dashboard/social-media-posts" exact component={redirect ? DashboardPlans : DashboardPostBlog}/>
              }
              {
                permissions.client_user &&
                <PrivateRoute path="/dashboard/users" exact component={redirect ? DashboardPlans : DashboardUsers}/>
              }
              {
                permissions.competitors &&
                <PrivateRoute path="/dashboard/competitors" exact component={redirect ? DashboardPlans : DashboardCompetitorComparison}/>
              }
              {
                (permissions.kanban_client || permissions.kanban) &&
                <PrivateRoute path="/dashboard/tasks/kanban" exact component={redirect ? DashboardPlans : DashboardKanban}/>
              }
              {
                permissions.user_leads &&
                <PrivateRoute path="/dashboard/leads" exact component={redirect ? DashboardPlans : DashboardLeads}/>
              }
              {
                permissions.grid_pages &&
                <PrivateRoute path="/dashboard/pages" exact component={redirect ? DashboardPlans : DashboardPages}/>
              }
              <PrivateRoute path="/dashboard/billing" exact component={DashboardBilling}/>
              <PrivateRoute path="/dashboard/my-drive" exact component={redirect ? DashboardPlans : DashboardDrive}/>
              <PrivateRoute path="/dashboard/add-ons" exact component={DashboardAddOns}/>
              <PrivateRoute path="/dashboard/add-ons/:id" exact component={DashboardAddOn}/>
              <PrivateRoute path="/dashboard/roles" exact component={DashboardRoles}/>
              <PrivateRoute path="/dashboard/plans" exact component={DashboardPlans}/>
              <PrivateRoute path="/dashboard/tasks" exact component={DashboardTasksBoard}/>
              <PrivateRoute path="/dashboard/persona-generator" exact component={DashboardPersona}/>
          </Switch>
        </AnimatePresence>
      </DashboardLayout>
    );
}

const PrivateRoute = ({ component: Component, redirect, ...rest }) => {
    const [authenticated, setAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
  
    const dispatch = useDispatch();
  
    const token = useSelector(state => state.auth.token);

    const history = useHistory();
  
    useEffect(() => {
      if(token){
        setAuthenticated(true);
        setLoading(false);
        api.post(`user/verify`, {
            session: token
        }).then(res => {
          setAuthenticated(true);
          setLoading(false);
        }).catch(err => {
          setLoading(false);
          dispatch(logoutAction());
          setAuthenticated(false);
        });
      } else {
        setLoading(false);
        dispatch(logoutAction());
        setAuthenticated(false);
      }

      api.interceptors.response.use(response => {
        return response;
      }, error => {
        if(error.response){
          if (error.response.status === 401) {
            setAuthenticated(false);
            dispatch(logoutAction());
            dispatch(setName(null));
            dispatch(setLogo(null));
            toast.error('Session expired. Please login again.');
            history.push('/');
            return setAuthenticated(false);
          }
        } else {
          setAuthenticated(false);
          dispatch(logoutAction());
          dispatch(setName(null));
          dispatch(setLogo(null));
          toast.error('Please login again.');
          history.push('/');
          return setAuthenticated(false);
        }
      });
  
    }, [])
  
    return (
      loading ?
      <div></div>
      :
      <Route
        {...rest}
        render={
          props => authenticated ? (
            <Component {...props} />
          ) : (
            <Redirect 
              to={{
                pathname: "/",
                state: { from: props.location }
              }}
            />
          )
        }
      />
    )
  }