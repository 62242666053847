import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from 'framer-motion';
import Header from '../../../components/header';
import DashboardPageLayout from '../../../layouts/dashboard-page';
import Scheduler from '../../../components/scheduler';
import { useDispatch } from 'react-redux';
import { setHeader } from '../../../store/modules/layout/actions';
import PostModal from '../../../components/PostModal';
import { 
    Heading,
    Text,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure
} from '@chakra-ui/react';

export default function View(props) {

    const [option, setOption] = useState('archiving');
    const dispatch = useDispatch();

    const animation = {
        in: {
            opacity: 1,
            transition: { type: 'linear' },
        },
        out: {
            opacity: 0,
            transition: { type: 'linear' },
        },
    }

    const { isOpen, onOpen, onClose } = useDisclosure()
    const [modalContent, setModalContent] = useState({})

    const openHoliday = (event) => {
        let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        let date = new Date(event.start_date)
        setModalContent({
            header: `${date.toLocaleDateString("en-US", options)}`,
            body: event.text
        })
        onOpen()
    }

    const [openedPost, setOpenedPost] = useState({})

    const openPost = (event) => {
        console.log(event)
        setOpenedPost(event)
        onOpen()
    }

    return (
        <>
        <Header left={
            <Heading fontWeight="700" fontFamily="poppins">Calendars</Heading>
        }/>
        <DashboardPageLayout title={'Calendars'} headerColor={'pink-header'} notBoxed={true}>

            <div className="tabs">
                <button className={`tab ${option == 'archiving' && 'active'}`} onClick={() => setOption('archiving')}>Archiving</button>
                <button className={`tab ${option == 'holidays' && 'active'}`} onClick={() => setOption('holidays')}>Holidays</button>
                <button className={`tab ${option == 'posts' && 'active'}`} onClick={() => setOption('posts')}>Posts</button>
            </div>

            <AnimatePresence>
                {
                    {
                        'archiving':
                        <motion.div
                            initial="out"
                            animate="in"
                            exit="out"
                            variants={animation} 
                            key='social-media-calendar'
                        >
                            <Scheduler id='social-media-calendar' endPoint={'/calendar/list/'} readOnly openParam={'url'}/>
                        </motion.div>,
                        'holidays':
                        <motion.div
                            initial="out"
                            animate="in"
                            exit="out"
                            variants={animation} 
                            key='holidays-calendar'
                        >
                            <Scheduler id='holidays-calendar' endPoint={'/calendar/list_social/'} readOnly openAction={openHoliday}/>
                            <Modal isOpen={isOpen} onClose={onClose} size="2xl">
                                <ModalOverlay />
                                <ModalContent>
                                    <ModalHeader>
                                        {modalContent.header}
                                    </ModalHeader>
                                    <ModalCloseButton />
                                    <ModalBody>
                                        <Text>{modalContent.body}</Text>
                                    </ModalBody>

                                    <ModalFooter>
                                        <Button variant="ghost" onClick={onClose}>Close</Button>
                                    </ModalFooter>
                                </ModalContent>
                            </Modal>
                        </motion.div>,
                        'posts':
                        <motion.div
                            initial="out"
                            animate="in"
                            exit="out"
                            variants={animation} 
                            key='posts-calendar'
                        >
                            <Scheduler id='posts-calendar' endPoint={'/calendar/list_post/'} readOnly openAction={openPost}/>
                            <PostModal open={isOpen} toggle={onClose} post={openedPost}/>
                        </motion.div>
                    }[option]
                }
            </AnimatePresence>
        </DashboardPageLayout>
        </>
    );
}