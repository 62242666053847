import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import api from "../../services/api";
import { Modal } from "reactstrap";
import {
     Avatar,
     Heading,
     Text,
     Box,
     Flex,
     Textarea,
     Button,
     Form,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';

export default function PostModal(props) {
     const token = useSelector((state) => state.auth.token);
     const logo = useSelector(state => state.data.logo)

     //const [comment, setComment] = useState("");
     //const [commentSaved, setCommentSaved] = useState(true);

     // useEffect(() => {
     //      if (props.posts) setComment(props.post.comments);
     // }, [props.post]);

     // function changeComment(value) {
     //      setComment(value);
     //      setCommentSaved(false);
     // }

     // function saveComment(e) {
     //      e.preventDefault();

     //      setCommentSaved(true);

     //      api.post(
     //           api.defaults.baseURL + "/post_blog/update_comment/" + token,
     //           {
     //                id: props.post.id,
     //                comments: comment,
     //           }
     //      ).then((res) => {
     //           if (props.posts) {
     //                const index = props.posts.findIndex(
     //                     (post) => post.id === props.post.id
     //                );
     //                const aux = props.posts;
     //                aux[index].comments = comment;
     //                props.setPosts([...aux]);
     //           }
     //      });
     // }

     const [commenting, setCommenting] = useState(false)
     const [commentContent, setCommentContent] = useState()
     function comment(e){
          e.preventDefault();
          setCommenting(true);

          api.post(api.defaults.baseURL + "/post_blog/create_comment/" + token, {
               post_blog_id: props.post.id,
               content: commentContent
          }).then(res => {
               let aux = props.posts;
               for(let i = 0; i < aux.length; i++){
                    if(aux[i].id == props.post.id){
                         aux[i].comments.push(res.data)
                    }
               }
               props.setPosts([...aux])
               setCommenting(false);
               setCommentContent('')
          })
     }

     return (
          props.post && (
               <Modal
                    isOpen={props.open}
                    toggle={props.toggle}
                    className={"modal-lg"}
               >
                    <div className="modal-header justify-content-center">
                         <div className="d-flex justify-content-center align-items-center">
                              <i className="far fa-eye ml-2"></i>
                              <h2 className="ml-2 mb-0">
                                   <span id="confirm-modal-title">
                                        View post
                                   </span>
                              </h2>
                         </div>
                         <button
                              type="button"
                              className="close"
                              id="modal-header-close-button"
                              onClick={props.toggle}
                         >
                              <span
                                   aria-hidden="true"
                                   className="modal-close-button"
                              >
                                   <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="27.269"
                                        height="27.269"
                                        viewBox="0 0 27.269 27.269"
                                   >
                                        <path
                                             id="Icon_awesome-plus"
                                             data-name="Icon awesome-plus"
                                             d="M17.905,9.825h-6.2v-6.2A1.377,1.377,0,0,0,10.33,2.25H8.953A1.377,1.377,0,0,0,7.575,3.627v6.2h-6.2A1.377,1.377,0,0,0,0,11.2V12.58a1.377,1.377,0,0,0,1.377,1.377h6.2v6.2a1.377,1.377,0,0,0,1.377,1.377H10.33a1.377,1.377,0,0,0,1.377-1.377v-6.2h6.2a1.377,1.377,0,0,0,1.377-1.377V11.2A1.377,1.377,0,0,0,17.905,9.825Z"
                                             transform="translate(-1.591 12.044) rotate(-45)"
                                             fill="#c15dd3"
                                        />
                                   </svg>
                              </span>
                         </button>
                    </div>
                    <div className="modal-body text-center">
                         <div className="row">
                              <div className="col-12 col-lg-5">
                                   <div className="post-view-image">
                                        <img src={props.post.picture} />
                                   </div>
                              </div>
                              <div className="col-12 col-lg-7">
                                   <div className="post-view-data">
                                        <Heading size="lg" mb="0">
                                             {props.post.title}
                                        </Heading>
                                        <p
                                             style={{
                                                  fontSize: "0.9em",
                                                  marginBottom: 10,
                                             }}
                                        >
                                             Date to publish:{" "}
                                             {props.post.date_publish}
                                        </p>
                                        <p className="mb-3">
                                             {props.post.content}
                                        </p>

                                        {props.changeStatus ? (
                                             <div className="d-flex mt-2 mb-3">
                                                  {props.post.status_post_id !=
                                                       3 &&
                                                       props.post
                                                            .status_post_id !=
                                                            2 && (
                                                            <button
                                                                 onClick={() =>
                                                                      props.changeStatus(
                                                                           props
                                                                                .post
                                                                                .id,
                                                                           4
                                                                      )
                                                                 }
                                                                 className={
                                                                      "mr-1 post-blog-button post-hold-button post-button-view " +
                                                                      (props
                                                                           .post
                                                                           .status_post_id ==
                                                                      4
                                                                           ? "post-holded-button"
                                                                           : "")
                                                                 }
                                                                 disabled={
                                                                      props.post
                                                                           .status_post_id ==
                                                                           2 ||
                                                                      props.post
                                                                           .status_post_id ==
                                                                           3
                                                                 }
                                                            >
                                                                 <i
                                                                      className="fas fa-spinner mr-2"
                                                                      aria-hidden="true"
                                                                 ></i>
                                                                 <span className="button-text">
                                                                      On hold
                                                                 </span>
                                                            </button>
                                                       )}
                                                  {props.post.status_post_id !=
                                                       3 && (
                                                       <button
                                                            onClick={() =>
                                                                 props.changeStatus(
                                                                      props.post
                                                                           .id,
                                                                      2
                                                                 )
                                                            }
                                                            className={
                                                                 "mr-1 post-blog-button post-approve-button post-button-view " +
                                                                 (props.post
                                                                      .status_post_id ==
                                                                 2
                                                                      ? "post-approved-button"
                                                                      : "")
                                                            }
                                                            disabled={
                                                                 props.post
                                                                      .status_post_id ==
                                                                 2
                                                            }
                                                       >
                                                            <i
                                                                 className="fas fa-check mr-2"
                                                                 aria-hidden="true"
                                                            ></i>
                                                            <span className="button-text">
                                                                 Approved
                                                            </span>
                                                       </button>
                                                  )}
                                                  {props.post.status_post_id !=
                                                       2 && (
                                                       <button
                                                            onClick={() =>
                                                                 props.changeStatus(
                                                                      props.post
                                                                           .id,
                                                                      3
                                                                 )
                                                            }
                                                            className={
                                                                 "post-blog-button post-refuse-button post-button-view " +
                                                                 (props.post
                                                                      .status_post_id ==
                                                                 3
                                                                      ? "post-refused-button"
                                                                      : "")
                                                            }
                                                            disabled={
                                                                 props.post
                                                                      .status_post_id ==
                                                                 3
                                                            }
                                                       >
                                                            <i className="fas fa-times mr-2"></i>
                                                            <span className="button-text">
                                                                 Disapproved
                                                            </span>
                                                       </button>
                                                  )}
                                             </div>
                                        ) : (
                                             <Link
                                                  to="/dashboard/social-media-posts"
                                                  onClick={props.toggle}
                                             >
                                                  <button className="dashboard-button primary-button">
                                                       Manage my posts
                                                  </button>
                                             </Link>
                                        )}

                                        <Heading size="md" mt="5" mb="2">
                                             Social Medias
                                        </Heading>
                                        <div>
                                             {props.post.facebook != 0 && (
                                                  <div className="post-social-media">
                                                       {" "}
                                                       <i className="fab fa-facebook-f"></i>{" "}
                                                  </div>
                                             )}
                                             {props.post.twitter != 0 && (
                                                  <div className="post-social-media">
                                                       {" "}
                                                       <i className="fab fa-twitter"></i>{" "}
                                                  </div>
                                             )}
                                             {props.post.instagram != 0 && (
                                                  <div className="post-social-media">
                                                       {" "}
                                                       <i className="fab fa-instagram"></i>{" "}
                                                  </div>
                                             )}
                                             {props.post.pinterest != 0 && (
                                                  <div className="post-social-media">
                                                       {" "}
                                                       <i className="fab fa-pinterest"></i>{" "}
                                                  </div>
                                             )}
                                             {props.post.youtube != 0 && (
                                                  <div className="post-social-media">
                                                       {" "}
                                                       <i className="fab fa-youtube"></i>{" "}
                                                  </div>
                                             )}
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div className="post-comments">
                              <div className="post-comments-title">
                                   <i className="far fa-comment ml-2"></i>{" "}
                                   Comments
                              </div>

                              <Box>
                                   {
                                        props.post.comments && 
                                        props.post.comments.map(comment => (
                                             <Comment comment={comment} posts={props.posts} setPosts={props.setPosts} post={props.post}/>
                                        ))
                                   }
                              </Box>

                              <form
                                   style={{
                                        display: "block",
                                        textAlign: "right",
                                   }}
                                   onSubmit={comment}
                              >
                                   <Flex w="100%" mt="7">
                                        <Avatar mr="2" src={logo}/>
                                        <Textarea value={commentContent} onChange={(e) => setCommentContent(e.target.value)}/>
                                   </Flex>
                                   <Button
                                        bgColor="#0670E1"
                                        _hover={{ bg: "#0061c7" }}
                                        color="white"
                                        fontFamily="poppins"
                                        fontWeight="400"
                                        fontSize="sm"
                                        mt="2"
                                        isLoading={commenting}
                                        type="submit"
                                   >
                                        Comment
                                   </Button>
                              </form>
                         </div>
                    </div>
               </Modal>
          )
     );
}

const Comment = ({comment, post, setPosts, posts}) => {
     const token = useSelector(state => state.auth.token)

     const [edit, setEdit] = useState(false)

     const [editComment, setEditComment] = useState(comment.content)
     const [saving, setSaving] = useState(false)

     function deleteComment(id){
          let aux = posts;
          for(let i = 0; i < aux.length; i++){
               if(aux[i].id == post.id){
                    aux[i].comments = aux[i].comments.filter(comment => comment.id != id)
               }
          }
          setPosts([...aux])
          api.post(api.defaults.baseURL + "/post_blog/delete_comment/" + token, {
               id
          })
     }

     function save(e){
          e.preventDefault()
          setSaving(true)

          api.post(api.defaults.baseURL + "/post_blog/update_comment/" + token, {
               id: comment.id,
               content: editComment
          }).then(res => {
               setSaving(false)
               let aux = posts;
               for(let i = 0; i < aux.length; i++){
                    if(aux[i].id == post.id){
                         for(let j = 0; j < aux[i].comments.length; j++){
                              if(aux[i].comments[j].id == comment.id){
                                   aux[i].comments[j].content = editComment
                              }
                         }
                    }
               }
               setPosts([...aux])
               setEdit(false)
          })
     }

     function cancel(){
          setEdit(false)
          setEditComment(comment.content)
     }

     return (
     <Flex
          align="flex-start"
          justifyContent="start"
          mt="4"
          mb="4"
          w="100%"
     >
          <Avatar mr="3" size="md" src={comment.picture} />
          <Box textAlign="left" w="100%">
               <Flex justifyContent="space-between" align="flex-start">
                    <Box w="100%">
                         <Heading size="sm">
                              {comment.user}
                         </Heading>
                         <Text fontSize="12px!important">
                              {comment.date}
                         </Text>
                    </Box>
                    <Flex>     
                         <Button onClick={() => setEdit(!edit)} bg="transparent" _hover={{bg: "gray.100"}} borderRadius="100px" w="40px" h="40px" p="0">
                              <AiOutlineEdit/>
                         </Button>
                         <Button onClick={() => deleteComment(comment.id)} bg="transparent" _hover={{bg: "gray.100"}} borderRadius="100px" w="40px" h="40px" p="0">
                              <AiOutlineDelete/>
                         </Button>
                    </Flex>
               </Flex>
               {
                    edit ?
                    <form onSubmit={save}>
                         <Textarea required mt="2" fontSize="16px!important" w="100%" value={editComment} onChange={(e) => setEditComment(e.target.value)}/>
                         <Box textAlign="right">
                              <Button
                                   fontFamily="poppins"
                                   fontWeight="400"
                                   fontSize="sm"
                                   mt="2"
                                   mr="1"
                                   variant="ghost"
                                   onClick={cancel}
                                   size="sm"
                              >
                                   Cancel
                              </Button>
                              <Button
                                   bgColor="#0670E1"
                                   _hover={{ bg: "#0061c7" }}
                                   color="white"
                                   fontFamily="poppins"
                                   fontWeight="400"
                                   fontSize="sm"
                                   mt="2"
                                   isLoading={saving}
                                   type="submit"
                                   size="sm"
                              >
                                   Save
                              </Button>
                         </Box>
                    </form>
                    :
                    <Text fontSize="16px!important">
                         {comment.content}
                    </Text>
               }
          </Box>
     </Flex>
     )
}
