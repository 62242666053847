import React, { useEffect, useState } from "react";
import { CustomInput } from 'reactstrap';
import Header from '../../../components/header';
import { Heading } from '@chakra-ui/react';
import DashboardPageLayout from '../../../layouts/dashboard-page';
import api from '../../../services/api';
import { useSelector, useDispatch } from 'react-redux';
import FacebookIcon from '../../../assets/images/social-medias-icons/facebook.png';
import InstagramIcon from '../../../assets/images/social-medias-icons/instagram.png';
import PinterestIcon from '../../../assets/images/social-medias-icons/pinterest.png';
import TwitterIcon from '../../../assets/images/social-medias-icons/twitter.png';
import YoutubeIcon from '../../../assets/images/social-medias-icons/youtube.png';

import { AnimatePresence, motion } from 'framer-motion';

import Modal from '../../../components/modal';

export default function View(props) {
    const token = useSelector(state => state.auth.token);

    const [loading, setLoading] = useState(false);

    const [socialMedias, setSocialMedias] = useState([]);

    const [addModal, setAddModal] = useState(false);
    const toggle = () => setAddModal(!addModal);

    useEffect(() => {
        api.get(api.defaults.baseURL + '/social_medias/my_list/' + token).then(res => {
            if(res){
                const { data } = res;
    
                setSocialMedias(data);
    
                setLoading(false);
            }
        });
    }, []);

    const animation = {
        in: index => ({
            opacity: 1,
            y: 0,
            transition: { type: 'linear', delay: index * 0.1 },
        }),
        out: index => ({
            opacity: 0,
            y: 20,
            transition: { type: 'linear', delay: index * 0.05 },
        }),
    }

    const [selectedSocialMedia, setSelectedSocialMedia] = useState(1);
    const [newUsername, setNewUsername] = useState();

    function addSocialMedia(e, _callback){
        e.preventDefault();
        api.post(api.defaults.baseURL + '/social_medias/create/' + token, {
            name: newUsername,
            social_media_id: selectedSocialMedia,
            competitor: 0,
        }).then(res => {
            setSocialMedias([...socialMedias, res.data]);
            _callback();
        });
    }

    function remove(id, socialMedia){
        setSocialMedias(socialMedias.filter(socialMedia => socialMedia.id != id));
        api.post(api.defaults.baseURL + '/social_medias/delete/' + token, { 
            id,
            social_media: socialMedia 
        });
    }

    function changeGrid(socialMedia){

        let value = socialMedia.social_grid == '1' ? 0 : 1;

        api.post(api.defaults.baseURL + '/social_medias/update_grid/' + token, { 
            grid: socialMedia.social_grid == '1' ? 0 : 1,
            social_media: socialMedia.social_media,
            id: socialMedia.id 
        });

        let aux = socialMedias;
        for(let i = 0; i < aux.length; i++){
            if(aux[i].id == socialMedia.id)
                aux[i].social_grid = value;
        }
        setSocialMedias([...aux]);
    }

    return (
        !loading &&
        <>
        <Header left={
            <Heading fontWeight="700" fontFamily="poppins">Social Medias</Heading>
        }/>
        <DashboardPageLayout 
            title={'Manage Your Social Medias'} 
            subtitle={'Add Social Media'} 
            headerColor={'purple-header'} 
            subContent={
                <Modal
                    buttonClass={'dashboard-button primary-button mb-3'}
                    buttonText={'Add +'}
                    toggle={toggle}
                    title={'Add Social Media'}
                    size={'lg'}
                    form
                    submit={addSocialMedia}
                >
                    <div className="text-center">
                        <p className="mb-4">Choose source and then set up what content you want to load from it</p>
                        <div className="d-block text-center d-lg-flex align-items-center justify-content-center">
                            <motion.button 
                                onClick={() => setSelectedSocialMedia(1)} 
                                className={
                                    'social-media-modal-option social-media-modal-option-facebook ' + 
                                    (selectedSocialMedia == '1' ? 'social-media-modal-option-facebook-active' : '')
                                }
                                type="button"
                                whileTap={{ scale: 0.95 }}
                            >
                                <div className="social-media-modal-option-icon">
                                    <i className="fab fa-facebook-square"></i>
                                </div>
                                <h3>Facebook</h3>
                            </motion.button>
                            <motion.button 
                                onClick={() => setSelectedSocialMedia(3)} 
                                className={
                                    'social-media-modal-option social-media-modal-option-twitter ' + 
                                    (selectedSocialMedia == '3' ? 'social-media-modal-option-twitter-active' : '')
                                }
                                type="button"
                                whileTap={{ scale: 0.95 }}
                            >
                                <div className="social-media-modal-option-icon">
                                    <i className="fab fa-twitter"></i>
                                </div>
                                <h3>Twitter</h3>
                            </motion.button>
                            <motion.button 
                                onClick={() => setSelectedSocialMedia(4)} 
                                className={
                                    'social-media-modal-option social-media-modal-option-pinterest ' + 
                                    (selectedSocialMedia == '4' ? 'social-media-modal-option-pinterest-active' : '')
                                }
                                type="button"
                                whileTap={{ scale: 0.95 }}
                            >
                                <div className="social-media-modal-option-icon">
                                    <i className="fab fa-pinterest"></i>
                                </div>
                                <h3>Pinterest</h3>
                            </motion.button>
                            <motion.button 
                                onClick={() => setSelectedSocialMedia(5)} 
                                className={
                                    'social-media-modal-option social-media-modal-option-youtube ' + 
                                    (selectedSocialMedia == '5' ? 'social-media-modal-option-youtube-active' : '')
                                }
                                type="button"
                                whileTap={{ scale: 0.95 }}
                            >
                                <div className="social-media-modal-option-icon">
                                    <i className="fab fa-youtube"></i>
                                </div>
                                <h3>Youtube</h3>
                            </motion.button>
                            <motion.button 
                                onClick={() => setSelectedSocialMedia(2)} 
                                className={
                                    'social-media-modal-option social-media-modal-option-instagram ' + 
                                    (selectedSocialMedia == '2' ? 'social-media-modal-option-instagram-active' : '')
                                }
                                type="button"
                                whileTap={{ scale: 0.95 }}
                            >
                                <div className="social-media-modal-option-icon">
                                    <i className="fab fa-instagram"></i>
                                </div>
                                <h3>Instagram</h3>
                            </motion.button>
                        </div>
                        <div>
                            <div className="d-flex align-items-center justify-content-center w-100 mt-3">
                                <label className="label mx-2 mb-0">Username</label>
                                <input value={newUsername} onChange={(e) => setNewUsername(e.target.value)} type="text" className="form-control mx-2" style={{maxWidth: 200}}/>
                            </div>
                        </div>
                    </div>
                </Modal>
            }
        >
            <div className="social-media-grid">
                {
                    socialMedias.map((media, index) => (
                        <div className="social-media-list-card">
                            <div 
                                className={{
                                    'facebook': 'social-media facebook',
                                    'twitter': 'social-media twitter',
                                    'instagram': 'social-media instagram',
                                    'pinterest': 'social-media pinterest',
                                    'youtube': 'social-media youtube',
                                }[media.social_media]}
                            >
                                {{
                                    'facebook': <i class="fab fa-facebook-square"></i>,
                                    'twitter': <i class="fab fa-twitter"></i>,
                                    'instagram': <i class="fab fa-instagram"></i>,
                                    'pinterest': <i class="fab fa-pinterest"></i>,
                                    'youtube': <i class="fab fa-youtube"></i>,
                                }[media.social_media]}

                                {{
                                    'facebook': 'Facebook',
                                    'twitter': 'Twitter',
                                    'instagram': 'Instagram',
                                    'pinterest': 'Pinterest',
                                    'youtube': 'Youtube',
                                }[media.social_media]}
                            </div>
                            <div className="content">
                                <span>Name</span>
                                <h2>{media.name}</h2>
                                <span>Social Grid</span>
                                <CustomInput type="switch" id={'show-at-home-'+index} checked={media.social_grid == '1' ? true : false} onChange={() => changeGrid(media)}/>
                            </div>

                            <div className="buttons">
                                <EditModal socialMedia={media} socialMedias={socialMedias} setSocialMedias={setSocialMedias}/>
                                <motion.button className="smbutton ml-2" whileTap={{scale: 0.8}} onClick={() => remove(media.id,media.social_media)}>
                                    <i class="fas fa-trash-alt"></i>
                                </motion.button>
                            </div>
                        </div>
                    ))
                }
            </div>

            {
                /*
                    <table className="table table-list-social-medias">
                        <thead>
                            <tr>
                                <th className="table-list-social-medias-radius-left" style={{width: '20%', whiteSpace: 'nowrap'}}>Social Media</th>
                                <th style={{width: '15%', whiteSpace: 'nowrap'}}>Name</th>
                                <th className="text-center" style={{width: '10%', whiteSpace: 'nowrap'}}>Show at home</th>
                                <th className="table-list-social-medias-button-column table-list-social-medias-radius-right"></th>
                            </tr>
                        </thead>
                        <tbody id="social-medias-table-body">
                            <AnimatePresence>
                            {
                                socialMedias.map((media, index) => (
                                    <motion.tr 
                                        initial="out"
                                        animate="in"
                                        exit="out"
                                        variants={animation} 
                                        custom={index}
                                        key={'social-medias-table-item-' + index}
                                    >
                                        <td>
                                            <img 
                                                style={{verticalAlign: 'middle', marginRight: 10, height: 'auto', width: 30}} 
                                                src={{
                                                    'facebook': FacebookIcon,
                                                    'twitter': TwitterIcon,
                                                    'instagram': InstagramIcon,
                                                    'pinterest': PinterestIcon,
                                                    'youtube': YoutubeIcon,
                                                }[media.social_media]}
                                            /> 
                                            {{
                                                'facebook': 'Facebook',
                                                'twitter': 'Twitter',
                                                'instagram': 'Instagram',
                                                'pinterest': 'Pinterest',
                                                'youtube': 'Youtube',
                                            }[media.social_media]}
                                        </td>
                                        <td>{media.name}</td>
                                        <td className="text-center">
                                            <CustomInput type="switch" id={'show-at-home-'+index} checked={media.social_grid == '1' ? true : false} onChange={() => changeGrid(media)}/>
                                        </td>
                                        <td className="text-right">
                                            <EditModal socialMedia={media} socialMedias={socialMedias} setSocialMedias={setSocialMedias}/>
                                            <button className="dashboard-button danger-button" onClick={() => remove(media.id,media.social_media)}>Delete</button>
                                        </td>
                                    </motion.tr>
                                ))
                            }
                            </AnimatePresence>
                        </tbody>
                    </table>
                */
            }
        </DashboardPageLayout>
        </>
    );
}

const EditModal = (props) => {
    const token = useSelector(state => state.auth.token);

    const [selectedSocialMedia, setSelectedSocialMedia] = useState();
    const [username, setUsername] = useState();

    useEffect(() => {
        setSelectedSocialMedia(props.socialMedia.social_media);
        setUsername(props.socialMedia.name);
    }, [props.socialMedia])

    function submit(e, _callback){
        e.preventDefault();
        api.post(api.defaults.baseURL + '/social_medias/update/' + token, {
            id: props.socialMedia.id,
            name: username,
            social_media: selectedSocialMedia,
            competitor: 0,
        }).then(res => {
            let aux = props.socialMedias;
            for(let i = 0; i < aux.length; i++){
                if(aux[i].id == props.socialMedia.id){
                    aux[i].name = username;
                    aux[i].social_media = selectedSocialMedia;
                }
            }
            props.setSocialMedias([...aux]);
            _callback();
        });
    }

    return (
        <Modal
            buttonClass={'smbutton'}
            buttonText={<i class="fas fa-edit"></i>}
            title={'Edit Social Media'}
            size={'lg'}
            form
            submit={submit}
        >
            <div className="text-center">
                <p className="mb-4">Choose source and then set up what content you want to load from it</p>
                <div className="d-block text-center d-lg-flex align-items-center justify-content-center">
                    <motion.button 
                        onClick={() => setSelectedSocialMedia('facebook')} 
                        className={
                            'social-media-modal-option social-media-modal-option-facebook ' + 
                            ((selectedSocialMedia == 'facebook' || selectedSocialMedia == 1)  ? 'social-media-modal-option-facebook-active' : '')
                        }
                        type="button"
                        whileTap={{ scale: 0.95 }}
                    >
                        <div className="social-media-modal-option-icon">
                            <i className="fab fa-facebook-square"></i>
                        </div>
                        <h3>Facebook</h3>
                    </motion.button>
                    <motion.button 
                        onClick={() => setSelectedSocialMedia('twitter')} 
                        className={
                            'social-media-modal-option social-media-modal-option-twitter ' + 
                            ((selectedSocialMedia == 'twitter' || selectedSocialMedia == 3) ? 'social-media-modal-option-twitter-active' : '')
                        }
                        type="button"
                        whileTap={{ scale: 0.95 }}
                    >
                        <div className="social-media-modal-option-icon">
                            <i className="fab fa-twitter"></i>
                        </div>
                        <h3>Twitter</h3>
                    </motion.button>
                    <motion.button 
                        onClick={() => setSelectedSocialMedia('pinterest')} 
                        className={
                            'social-media-modal-option social-media-modal-option-pinterest ' + 
                            ((selectedSocialMedia == 'pinterest' || selectedSocialMedia == 4)  ? 'social-media-modal-option-pinterest-active' : '')
                        }
                        type="button"
                        whileTap={{ scale: 0.95 }}
                    >
                        <div className="social-media-modal-option-icon">
                            <i className="fab fa-pinterest"></i>
                        </div>
                        <h3>Pinterest</h3>
                    </motion.button>
                    <motion.button 
                        onClick={() => setSelectedSocialMedia('youtube')} 
                        className={
                            'social-media-modal-option social-media-modal-option-youtube ' + 
                            ((selectedSocialMedia == 'youtube' || selectedSocialMedia == 5)  ? 'social-media-modal-option-youtube-active' : '')
                        }
                        type="button"
                        whileTap={{ scale: 0.95 }}
                    >
                        <div className="social-media-modal-option-icon">
                            <i className="fab fa-youtube"></i>
                        </div>
                        <h3>Youtube</h3>
                    </motion.button>
                    <motion.button 
                        onClick={() => setSelectedSocialMedia('instagram')} 
                        className={
                            'social-media-modal-option social-media-modal-option-instagram ' + 
                            ((selectedSocialMedia == 'instagram' || selectedSocialMedia == 2)  ? 'social-media-modal-option-instagram-active' : '')
                        }
                        type="button"
                        whileTap={{ scale: 0.95 }}
                    >
                        <div className="social-media-modal-option-icon">
                            <i className="fab fa-instagram"></i>
                        </div>
                        <h3>Instagram</h3>
                    </motion.button>
                </div>
                <div>
                    <div className="d-flex align-items-center justify-content-center w-100 mt-3">
                        <label className="label mx-2 mb-0">Username</label>
                        <input value={username} onChange={(e) => setUsername(e.target.value)} type="text" className="form-control mx-2" style={{maxWidth: 200}}/>
                    </div>
                </div>
            </div>
        </Modal>
    )
}