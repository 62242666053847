import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import api from '../../../services/api';
import { motion } from 'framer-motion';
import DashboardPageLayout from '../../../layouts/dashboard-page';
import Header from '../../../components/header';
import { Heading } from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';

export default function Posts(props){

    const token = useSelector(state => state.auth.token);
    const [loading, setLoading] = useState(false);

    const [admin, setAdmin] = useState(false);

    const [tasks, setTasks] = useState([]);

    const [option, setOption] = useState('active');

    const [searchText, setSearchText] = useState();
    const [viewType, setViewType] = useState('tabs')
    const permissions = useSelector(state => state.permissions);

    const location = useLocation();
    useEffect(() => {
        setLoading(true);

        let ep = permissions.kanban ? '/kanban/list/' : '/kanban_client/list/'

        api.post(api.defaults.baseURL + ep + token).then(res => {
            if(res){
                setTasks(res.data);
            }
            setLoading(false);
        })
    }, []);

    return (
        <>
        <Header left={
            <Heading fontWeight="700" fontFamily="poppins">Tasks Kanban</Heading>
        } right={
            <div className="d-flex align-items-center mr-2">
                <div className="leads-page-selector">
                    <Link to="/dashboard/tasks" className={`leads-page-selector-button ${location.pathname === '/dashboard/tasks' && 'leads-page-selector-button-active'}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19">
                            <g id="Group_391" data-name="Group 391" transform="translate(-659 -366)">
                                <rect id="Rectangle_329" data-name="Rectangle 329" width="5" height="20" rx="1" transform="translate(659 378) rotate(-90)" fill="#d0d2e1"/>
                                <rect id="Rectangle_459" data-name="Rectangle 459" width="5" height="20" rx="1" transform="translate(659 385) rotate(-90)" fill="#d0d2e1"/>
                                <rect id="Rectangle_330" data-name="Rectangle 330" width="5" height="20" rx="1" transform="translate(659 371) rotate(-90)" fill="#d0d2e1"/>
                            </g>
                        </svg>
                    </Link>
                    <Link to="/dashboard/tasks/kanban" className={`leads-page-selector-button ${location.pathname === '/dashboard/tasks/kanban' && 'leads-page-selector-button-active'}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18">
                            <g id="Group_392" data-name="Group 392" transform="translate(-663 -366)">
                                <rect id="Rectangle_329" data-name="Rectangle 329" width="7" height="18" rx="1" transform="translate(663 366)" fill="#d0d2e1"/>
                                <rect id="Rectangle_330" data-name="Rectangle 330" width="7" height="13" rx="1" transform="translate(672 366)" fill="#d0d2e1"/>
                            </g>
                        </svg>
                    </Link>
                </div>

                <form id="leads-page-search-form">
                    <input type="text" id="leads-page-search" placeholder="Search" onChange={(e) => setSearchText(e.target.value)}/>
                </form>
            </div>
        }/>
        <DashboardPageLayout title={'Tasks'} notBoxed={true}>
            <div className="tasks-board">
                <div className="grid">
                    <div className="right">
                        <div className="header">
                            <svg xmlns="http://www.w3.org/2000/svg" width="61" height="61" viewBox="0 0 61 61">
                                <defs>
                                    <filter id="Rectangle_444" x="0" y="0" width="61" height="61" filterUnits="userSpaceOnUse">
                                        <feOffset dy="1" input="SourceAlpha"/>
                                        <feGaussianBlur stdDeviation="3" result="blur"/>
                                        <feFlood flood-opacity="0.071"/>
                                        <feComposite operator="in" in2="blur"/>
                                        <feComposite in="SourceGraphic"/>
                                    </filter>
                                </defs>
                                <g id="Group_393" data-name="Group 393" transform="translate(-1413 -167)">
                                    <g transform="matrix(1, 0, 0, 1, 1413, 167)" filter="url(#Rectangle_444)">
                                        <rect id="Rectangle_444-2" data-name="Rectangle 444" width="43" height="43" rx="5" transform="translate(9 8)" fill="#5951ff"/>
                                    </g>
                                    <path id="Icon_awesome-calendar-alt" data-name="Icon awesome-calendar-alt" d="M0,17.255A1.782,1.782,0,0,0,1.777,19.04H14.81a1.782,1.782,0,0,0,1.777-1.785V7.14H0ZM11.848,9.966a.447.447,0,0,1,.444-.446h1.481a.447.447,0,0,1,.444.446v1.488a.447.447,0,0,1-.444.446H12.292a.447.447,0,0,1-.444-.446Zm0,4.76a.447.447,0,0,1,.444-.446h1.481a.447.447,0,0,1,.444.446v1.488a.447.447,0,0,1-.444.446H12.292a.447.447,0,0,1-.444-.446ZM7.109,9.966a.447.447,0,0,1,.444-.446H9.034a.447.447,0,0,1,.444.446v1.488a.447.447,0,0,1-.444.446H7.553a.447.447,0,0,1-.444-.446Zm0,4.76a.447.447,0,0,1,.444-.446H9.034a.447.447,0,0,1,.444.446v1.488a.447.447,0,0,1-.444.446H7.553a.447.447,0,0,1-.444-.446ZM2.37,9.966a.447.447,0,0,1,.444-.446H4.295a.447.447,0,0,1,.444.446v1.488a.447.447,0,0,1-.444.446H2.814a.447.447,0,0,1-.444-.446Zm0,4.76a.447.447,0,0,1,.444-.446H4.295a.447.447,0,0,1,.444.446v1.488a.447.447,0,0,1-.444.446H2.814a.447.447,0,0,1-.444-.446ZM14.81,2.38H13.033V.6a.6.6,0,0,0-.592-.6H11.256a.6.6,0,0,0-.592.6V2.38H5.924V.6A.6.6,0,0,0,5.332,0H4.147a.6.6,0,0,0-.592.6V2.38H1.777A1.782,1.782,0,0,0,0,4.165V5.95H16.587V4.165A1.782,1.782,0,0,0,14.81,2.38Z" transform="translate(1435.622 186.574)" fill="#fff"/>
                                </g>
                            </svg>

                            <h1>Calendar</h1>
                        </div>

                        <div className="days">
                            <CalendarDay/>
                            <CalendarDay/>
                            <CalendarDay/>
                            <CalendarDay/>
                            <CalendarDay/>
                            <CalendarDay/>
                            <CalendarDay/>
                            <CalendarDay/>
                            <CalendarDay/>
                            <CalendarDay/>
                            <CalendarDay/>
                            <CalendarDay/>
                            <CalendarDay/>
                            <CalendarDay/>
                            <CalendarDay/>
                            <CalendarDay/>
                        </div>
                    </div>
                    <div className="left">
                        <div className="tabs">
                            <button className={`tab ${option == 'active' && 'active'}`} onClick={() => setOption('active')}>Active Tasks 1</button>
                            <button className={`tab ${option == 'completed' && 'active'}`} onClick={() => setOption('completed')}>Completed 3</button>
                            <button className={`tab ${option == 'archived' && 'active'}`} onClick={() => setOption('archived')}>Archived 4</button>
                            <button className={`tab ${option == 'closed' && 'active'}`} onClick={() => setOption('closed')}>Closed 0</button>
                        </div>

                        {
                            option == 'active' &&
                            <div className="board">
                                <Task index={1}/>
                                <Task index={2}/>
                                <Task index={3}/>
                            </div>
                        }

                        {
                            option == 'completed' &&
                            <div className="board">
                                <Task index={1}/>
                                <Task index={2}/>
                                <Task index={3}/>
                                <Task index={4}/>
                                <Task index={5}/>
                            </div>
                        }

                        {
                            option == 'archived' &&
                            <div className="board">
                                <Task index={1}/>
                                <Task index={2}/>
                                <Task index={3}/>
                                <Task index={4}/>
                            </div>
                        }

                        {
                            option == 'closed' &&
                            <div className="board">
                                <Task index={1}/>
                                <Task index={2}/>
                                <Task index={3}/>
                            </div>
                        }
                    </div>
                </div>
            </div>

        </DashboardPageLayout>
        </>
    );
}

const Task = (props) => {

    const animation = {
        in: order => ({
            opacity: 1,
            y: 0,
            transition: { type: 'linear', delay: order * 0.1 },
        }),
        out: order => ({
            opacity: 0,
            y: 20,
            transition: { type: 'linear', delay: order * 0.05 },
        }),
    }

    return (
        <motion.div
            initial="out"
            animate="in"
            exit="out"
            custom={props.index}
            variants={animation} 
            className="task yellow"
        >
            <div className="column first-column">
                <div className="header">
                    <h2 className="name">
                        Rafael Rolim
                    </h2>
                    <span className="role">Admin</span>
                </div>
                <div>
                    <h1>
                        Create Landing Page
                    </h1>
                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elit.</p>
                </div>
            </div>
            <div className="column second-column">
                <div className="category">
                    WEBSITE
                </div>
                <p><span className="delivery">Delivery:</span> <span className="time">within 5 days</span></p>
            </div>
            <div className="column third-column">
                <div className="status">
                    <span className="icon"></span> DOING
                </div>
            </div>
            <div className="column last-column">

                <motion.button whileTap={{scale: 0.8}} className="options">
                    <span className="icon"></span>
                    <span className="icon"></span>
                    <span className="icon"></span>
                </motion.button>
            </div>
        </motion.div>
    )
}

const CalendarDay = (props) => {
    return (
        <div className="day">
            <h2>January 15</h2>
            <div className="tasks">
                <CalendarDayTask index={1}/>
                <CalendarDayTask index={2}/>
                <CalendarDayTask index={3}/>
                <CalendarDayTask index={4}/>
            </div>
        </div>
    )
} 

const CalendarDayTask = (props) => {
    const animation = {
        in: order => ({
            opacity: 1,
            y: 0,
            transition: { type: 'linear', delay: order * 0.1 },
        }),
        out: order => ({
            opacity: 0,
            y: 20,
            transition: { type: 'linear', delay: order * 0.05 },
        }),
    }

    return (
        <motion.div
            initial="out"
            animate="in"
            exit="out"
            custom={props.index}
            variants={animation} 
            className="task"
        >
            <div className="group">
                <div className="hour yellow">
                    19:00
                </div>

                <div className="description yellow">
                    <span>Website</span>
                    <h3>Creative</h3>
                </div>
            </div>

            <motion.button whileTap={{scale: 0.8}}>
                SEE MORE
            </motion.button>
        </motion.div>
    )
}