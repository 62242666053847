import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import Header from '../../../components/header';
import { Heading } from '@chakra-ui/react';
import DashboardPageLayout from '../../../layouts/dashboard-page';
import api from '../../../services/api';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import Lightbox from '../../../components/archiving-light-box';
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from 'react-loading-skeleton';

export default function View(props) {

    const [loading, setLoading] = useState(true);

    const token = useSelector(state => state.auth.token);

    const [files, setFiles] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [limitPage, setLimitPage] = useState(1);
    const [uploadFiles, setUploadFiles] = useState([]);
    const [submiting, setSubmiting] = useState(false);
    
    useEffect(() => {
        fetchData();
    }, []);

    function fetchData(){
        api.post(api.defaults.baseURL + '/vault/list/' + token, {
            page: currentPage,
        }).then(res => {
            if(res){
                setFiles([...files, ...res.data]);
                setLoading(false);
                setCurrentPage(currentPage + 1);
                //setLimitPage(res.data.page.limit);
            } else {
                setCurrentPage(limitPage + 1);
            }
        });
    }

    function submit(){
        const formData = new FormData();
        formData.append('file', document.getElementById('dropped-image-input').files[0]);

        if(document.getElementById('dropped-image-input').files.length > 0){
            setSubmiting(true);
            api.post(api.defaults.baseURL + '/vault/upload/' + token, formData).then(res => {
                setFiles([res.data, ...files]);
                toast.success('File uploaded successfull');
                setSubmiting(false);
                setUploadFiles([]);
            });
        }
    }

    const animation = {
        in: order => ({
            opacity: 1,
            y: 0,
            transition: { type: 'linear', delay: 0 },
        }),
        out: order => ({
            opacity: 0,
            y: 20,
            transition: { type: 'linear', delay: 0 },
        }),
    }

    const [openFile, setOpenFile] = useState(false);

    function remove(file){
        setFiles(files.filter(f => f.id != file.id));
        api.post(api.defaults.baseURL + '/vault/delete/' + token, {
            id: file.id
        });
    }

    return (
        <>
        <Header left={
            <Heading fontWeight="700" fontFamily="poppins">My Drive</Heading>
        }/>
        <Lightbox file={openFile} setOpenFile={setOpenFile} drive={true} remove={remove}/>
        <DashboardPageLayout title={'My Drive'} subtitle={'Manage your files'} subContent={
            <div className="d-flex align-items-center">
                <button className="select-file-drive" onClick={() => document.getElementById('dropped-image-input').click()}>
                    {
                        uploadFiles.length > 0 ?
                        `${uploadFiles.length} file(s) selected`
                        :
                        'Select a file'
                    }
                </button>
                <div className="submit-drive-wrapper">
                    {
                        submiting ?
                        <div className="text-center">
                            <div className="spinner-border text-primary disable" id="myDriveUploadingIndicator" role="status"><span className="sr-only">Loading...</span></div>
                        </div>
                        :
                        <button className="dashboard-button primary-button" onClick={submit}>SUBMIT</button>
                    }
                </div>
                <input type="file" className="d-none" id="dropped-image-input" onChange={(e) => setUploadFiles([...e.target.files])}/>
            </div>
            }
        >
            <InfiniteScroll
                dataLength={files.length}
                next={fetchData}
                hasMore={currentPage < limitPage ? true : false}
                loader={
                    <div className="skeleton-wrapper">
                        <Skeleton width={'100%'} height={'100%'}/>
                    </div>
                }
                className="archiving-grid mt-4"
            >
                {
                    loading ?
                    Array.from({ length: 12 }).map(() => (
                        <div className="skeleton-wrapper">
                            <Skeleton width={'100%'} height={'100%'}/>
                        </div>
                    ))
                    :
                    files.map((file, index) => (
                        <motion.div 
                            initial="out"
                            animate="in"
                            exit="out"
                            variants={animation}
                            custom={index}
                            className="archiving-item"
                            onClick={() => setOpenFile(file)}
                            key={'archiving-file-'+index}
                        >
                            <div className="archiving-preview" style={{backgroundImage: `url(${file.link})`}}></div>
                            <div className="archiving-functions">
                            </div>
                        </motion.div>
                    ))
                }
            </InfiniteScroll>
        </DashboardPageLayout>
        </>
    );
}