import React, { useState, useEffect } from 'react';
import DashboardPageLayout from '../../../layouts/dashboard-page';
import Header from '../../../components/header';
import FacebookSVG from '../../../assets/icons/FacebookSVG.svg';
import InstagramSVG from '../../../assets/icons/InstagramSVG.svg';
import YoutubeSVG from '../../../assets/icons/YoutubeSVG.svg';
import TwitterSVG from '../../../assets/icons/TwitterSVG.svg';
import VeryLowSVG from '../../../assets/icons/very-low.svg';
import LowSVG from '../../../assets/icons/low.svg';
import MediumSVG from '../../../assets/icons/medium.svg';
import HighSVG from '../../../assets/icons/high.svg';
import VeryHighSVG from '../../../assets/icons/very-high.svg';
import { BsHeart } from 'react-icons/bs';
import { FaCommentDots, FaPinterest } from 'react-icons/fa';
import Modal from '../../../components/modal';
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

export default function Comparison(){
    const [competitors, setCompetitors] = useState([]);
    const [username, setUsername] = useState();
    const [picture, setPicture] = useState();
    const [facebook, setFacebook] = useState();
    const [instagram, setInstagram] = useState();
    const [twitter, setTwitter] = useState();
    const [pinterest, setPinterest] = useState();
    const [youtube, setYoutube] = useState();
    const token = useSelector(state => state.auth.token);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        api.post(api.defaults.baseURL + '/competitors/list/' + token).then(res => {
            if(res){
                setCompetitors(res.data);
                setLoading(false);

                setUsername('');
                setPicture('');
                setFacebook('');
                setInstagram('');
                setTwitter('');
                setPinterest('');
                setYoutube('');
            }
        })
    }, [])

    function submit(e){
        e.preventDefault();
        api.post(api.defaults.baseURL + '/competitors/create/' + token, {
            name: username,
            picture,
            facebook,
            instagram,
            twitter,
            pinterest,
            youtube,
        }).then(res => {
            if(res == 0){
                return toast.warning('Please check the fields');
            }

            setCompetitors([res.data.competitor, ...competitors]);
        })
    }
    return(
        <>
            <Header/>
            <DashboardPageLayout
                title='Competitor Analysis'
                subtitle='Competitor'
                subContent={<div></div>}
                headerColor='blue-header'
                subContent={
                    <Modal
                        buttonClass='dashboard-button white-button-blue'
                        buttonText={
                            <>
                                <span className="mr-2 d-none d-lg-inline">Add Competitor</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16.194" height="16.194" viewBox="0 0 16.194 16.194">
                                    <path id="Icon_awesome-plus" data-name="Icon awesome-plus" d="M15.038,8.612H9.832V3.407A1.157,1.157,0,0,0,8.676,2.25H7.519A1.157,1.157,0,0,0,6.362,3.407V8.612H1.157A1.157,1.157,0,0,0,0,9.769v1.157a1.157,1.157,0,0,0,1.157,1.157H6.362v5.205a1.157,1.157,0,0,0,1.157,1.157H8.676a1.157,1.157,0,0,0,1.157-1.157V12.082h5.205a1.157,1.157,0,0,0,1.157-1.157V9.769A1.157,1.157,0,0,0,15.038,8.612Z" transform="translate(0 -2.25)" fill="#0670e1"/>
                                </svg> 
                            </>
                        }
                        title={'Add Competitor'}
                        size={'lg'}
                        form
                        submit={submit}
                    >
                        <div className="row justify-content-center">
                            <div className="col-12 col-lg-6 row-reverse d-flex align-items-center mb-3">
                                <div className="add-lead-form-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17.577" height="17.577" viewBox="0 0 17.577 17.577">
                                        <path id="Icon_material-person" data-name="Icon material-person" d="M14.788,14.788a4.394,4.394,0,1,0-4.394-4.394A4.393,4.393,0,0,0,14.788,14.788Zm0,2.2C11.855,16.985,6,18.457,6,21.38v2.2H23.577v-2.2C23.577,18.457,17.721,16.985,14.788,16.985Z" transform="translate(-6 -6)" fill="#dbdbdb"/>
                                    </svg>
                                </div>
                                <input required type="text" className="add-lead-form-input add-lead-form-input-required" id="add-lead-name" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Username"/>
                            </div>
                        </div>
    
                        <div className="row">
                            <div className="col-12 col-lg-6 d-flex align-items-center mb-3">
                                <div className="add-lead-form-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="19.18" height="14.385" viewBox="0 0 19.18 14.385">
                                        <path id="Icon_awesome-image" data-name="Icon awesome-image" d="M17.382,18.885H1.8a1.8,1.8,0,0,1-1.8-1.8V6.3A1.8,1.8,0,0,1,1.8,4.5H17.382a1.8,1.8,0,0,1,1.8,1.8V17.087A1.8,1.8,0,0,1,17.382,18.885ZM4.2,6.6a2.1,2.1,0,1,0,2.1,2.1A2.1,2.1,0,0,0,4.2,6.6Zm-1.8,9.89H16.783v-4.2L13.5,9.014a.45.45,0,0,0-.636,0L7.792,14.09l-2.08-2.08a.45.45,0,0,0-.636,0L2.4,14.69Z" transform="translate(0 -4.5)" fill="#dbdbdb"/>
                                    </svg>
                                </div>
                                <input type="text" className="add-lead-form-input" value={picture} onChange={(e) => setPicture(e.target.value)} placeholder="Picture url"/>
                            </div>
                            <div className="col-12 col-lg-6 d-flex align-items-center mb-3">
                                <div className="add-lead-form-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="19.828" height="16.104" viewBox="0 0 19.828 16.104">
                                        <path id="Icon_awesome-twitter" data-name="Icon awesome-twitter" d="M17.79,7.394c.013.176.013.352.013.528A11.483,11.483,0,0,1,6.24,19.485,11.484,11.484,0,0,1,0,17.66a8.407,8.407,0,0,0,.981.05,8.138,8.138,0,0,0,5.045-1.736,4.071,4.071,0,0,1-3.8-2.818,5.125,5.125,0,0,0,.767.063,4.3,4.3,0,0,0,1.069-.138A4.064,4.064,0,0,1,.805,9.093v-.05a4.093,4.093,0,0,0,1.837.516A4.07,4.07,0,0,1,1.384,4.123,11.551,11.551,0,0,0,9.763,8.375a4.587,4.587,0,0,1-.1-.931A4.068,4.068,0,0,1,16.7,4.664a8,8,0,0,0,2.579-.981,4.053,4.053,0,0,1-1.787,2.239,8.147,8.147,0,0,0,2.34-.629,8.735,8.735,0,0,1-2.038,2.1Z" transform="translate(0 -3.381)" fill="#dbdbdb"/>
                                    </svg>
                                </div>
                                <input type="text" className="add-lead-form-input" value={facebook} onChange={(e) => setFacebook(e.target.value)} placeholder="Facebook"/>
                            </div>
    
                            <div className="col-12 col-lg-6 d-flex align-items-center mb-3">
                                <div className="add-lead-form-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18.117" height="18.117" viewBox="0 0 18.117 18.117">
                                        <path id="Icon_awesome-facebook-square" data-name="Icon awesome-facebook-square" d="M16.176,2.25H1.941A1.941,1.941,0,0,0,0,4.191V18.426a1.941,1.941,0,0,0,1.941,1.941h5.55V14.208H4.944v-2.9H7.492V9.1A3.539,3.539,0,0,1,11.28,5.2a15.435,15.435,0,0,1,2.245.2V7.86H12.26a1.45,1.45,0,0,0-1.635,1.566v1.882h2.781l-.445,2.9H10.626v6.159h5.55a1.941,1.941,0,0,0,1.941-1.941V4.191A1.941,1.941,0,0,0,16.176,2.25Z" transform="translate(0 -2.25)" fill="#dbdbdb"/>
                                    </svg>
                                </div>
                                <input type="text" className="add-lead-form-input" value={instagram} onChange={(e) => setInstagram(e.target.value)} placeholder="Instagram"/>
                            </div>
                            <div className="col-12 col-lg-6 d-flex align-items-center mb-3">
                                <div className="add-lead-form-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="19.937" height="19.933" viewBox="0 0 19.937 19.933">
                                        <path id="Icon_awesome-instagram" data-name="Icon awesome-instagram" d="M9.966,7.094A5.111,5.111,0,1,0,15.076,12.2,5.1,5.1,0,0,0,9.966,7.094Zm0,8.433A3.323,3.323,0,1,1,13.288,12.2,3.329,3.329,0,0,1,9.966,15.527Zm6.512-8.642a1.192,1.192,0,1,1-1.192-1.192A1.189,1.189,0,0,1,16.477,6.885Zm3.385,1.21a5.9,5.9,0,0,0-1.61-4.176,5.938,5.938,0,0,0-4.176-1.61c-1.646-.093-6.578-.093-8.224,0A5.929,5.929,0,0,0,1.675,3.913,5.918,5.918,0,0,0,.065,8.09c-.093,1.646-.093,6.578,0,8.224a5.9,5.9,0,0,0,1.61,4.176A5.945,5.945,0,0,0,5.851,22.1c1.646.093,6.578.093,8.224,0a5.9,5.9,0,0,0,4.176-1.61,5.938,5.938,0,0,0,1.61-4.176c.093-1.646.093-6.574,0-8.22ZM17.736,18.08a3.364,3.364,0,0,1-1.895,1.895c-1.312.52-4.426.4-5.876.4s-4.568.116-5.876-.4A3.364,3.364,0,0,1,2.2,18.08c-.52-1.312-.4-4.426-.4-5.876s-.116-4.568.4-5.876A3.364,3.364,0,0,1,4.09,4.434c1.312-.52,4.426-.4,5.876-.4s4.568-.116,5.876.4a3.364,3.364,0,0,1,1.895,1.895c.52,1.312.4,4.426.4,5.876S18.256,16.772,17.736,18.08Z" transform="translate(0.005 -2.238)" fill="#dbdbdb"/>
                                    </svg>
                                </div>
                                <input type="text" className="add-lead-form-input" value={twitter} onChange={(e) => setTwitter(e.target.value)} placeholder="Twitter"/>
                            </div>
    
                            <div className="col-12 col-lg-6 d-flex align-items-center mb-3">
                                <div className="add-lead-form-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="21.398" height="21.398" viewBox="0 0 21.398 21.398">
                                        <path id="Icon_awesome-pinterest" data-name="Icon awesome-pinterest" d="M21.4,11.261A10.7,10.7,0,0,1,7.532,21.482a12.027,12.027,0,0,0,1.329-2.8c.129-.5.664-2.545.664-2.545a2.865,2.865,0,0,0,2.45,1.23c3.227,0,5.552-2.968,5.552-6.657a6.28,6.28,0,0,0-6.6-6.178c-4.616,0-7.071,3.1-7.071,6.475,0,1.57.837,3.525,2.17,4.146.2.095.311.052.358-.142.035-.147.216-.876.3-1.212a.32.32,0,0,0-.073-.306,4.213,4.213,0,0,1-.789-2.442A4.63,4.63,0,0,1,10.656,6.4a4.224,4.224,0,0,1,4.469,4.353c0,2.895-1.462,4.9-3.365,4.9a1.552,1.552,0,0,1-1.583-1.933,21.5,21.5,0,0,0,.884-3.563A1.344,1.344,0,0,0,9.707,8.656c-1.074,0-1.937,1.109-1.937,2.6a3.855,3.855,0,0,0,.319,1.588s-1.057,4.478-1.251,5.315A10.356,10.356,0,0,0,6.8,21.227a10.7,10.7,0,1,1,14.6-9.966Z" transform="translate(0 -0.563)" fill="#dbdbdb"/>
                                    </svg>
                                </div>
                                <input type="text" className="add-lead-form-input" value={pinterest} onChange={(e) => setPinterest(e.target.value)} placeholder="Pinterest"/>
                            </div>
                            <div className="col-12 col-lg-6 d-flex align-items-center mb-3">
                                <div className="add-lead-form-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22.903" height="16.104" viewBox="0 0 22.903 16.104">
                                        <path id="Icon_awesome-youtube" data-name="Icon awesome-youtube" d="M23.475,7.02A2.878,2.878,0,0,0,21.45,4.982C19.664,4.5,12.5,4.5,12.5,4.5s-7.162,0-8.948.482A2.878,2.878,0,0,0,1.529,7.02a30.189,30.189,0,0,0-.479,5.548,30.189,30.189,0,0,0,.479,5.548,2.835,2.835,0,0,0,2.025,2.005C5.34,20.6,12.5,20.6,12.5,20.6s7.162,0,8.948-.482a2.835,2.835,0,0,0,2.025-2.005,30.189,30.189,0,0,0,.479-5.548,30.189,30.189,0,0,0-.479-5.548ZM10.159,15.974V9.163l5.986,3.405-5.986,3.405Z" transform="translate(-1.05 -4.5)" fill="#dbdbdb"/>
                                    </svg>
                                </div>
                                <input type="text" className="add-lead-form-input" value={youtube} onChange={(e) => setYoutube(e.target.value)} placeholder="Youtube"/>
                            </div>
                        </div>
                    </Modal>
                }
            >
                <div className="comparison-scroll mt-3">
                    <div className="comparison-grid">
                        <div className="comparison-column-2">
                            <div className="comparison-column comparison-heading">
                                <div className="comparison-row"></div>
                                <div className="comparison-row">
                                    Followers
                                </div>
                                <div className="comparison-row">
                                    Likes
                                </div>
                                <div className="comparison-row">
                                    Comments
                                </div>
                                <div className="comparison-row">
                                    Shares
                                </div>
                                <div className="comparison-row">
                                    Engagement Power
                                </div>
                                <div className="comparison-row">
                                    Engagement Average
                                </div>
                                <div className="comparison-row">
                                    Most Popular SM
                                </div>
                                <div className="comparison-row">
                                    Highest Engagement SM
                                </div>
                                <div className="comparison-row">
                                    Most Used Hashtag
                                </div>
                                <div className="comparison-row">
                                    Best Post on Facebook
                                    <img src={FacebookSVG} className="comparison-icon"/>
                                </div>
                                <div className="comparison-row">
                                    Best Post on Instagram
                                    <img src={InstagramSVG} className="comparison-icon"/>
                                </div>
                                <div className="comparison-row">
                                    Best Post on Twitter
                                    <img src={TwitterSVG} className="comparison-icon"/>
                                </div>
                                <div className="comparison-row">
                                    Best Post on Youtube
                                    <img src={YoutubeSVG} className="comparison-icon"/>
                                </div>
                                <div className="comparison-row">
                                    Best Post on Pinterest
                                    <FaPinterest size="20" style={{marginLeft: 5, color: 'red'}}/>
                                </div>
                            </div>

                            <Column user/>
                        </div>

                        <Column/>
                        <Column/>
                    </div>
                </div>
            </DashboardPageLayout>
        </>
    )
}

const Column = (props) => {
    return (          
        <div className={`comparison-column ${props.user ? 'comparison-user' : ''}`}>
            <div className="comparison-row">
                <img src="https://www.adscientiam.com/fileadmin/user_upload/logo-microsoft.jpg" className="comparison-logo"/>
            </div>
            <div className="comparison-row">
                <div className="comparison-metric-primary">
                    <div className="metric-value">
                        937K
                    </div>
                    <div className="metric-label">
                        Total Followers
                    </div>
                </div>
            </div>
            <div className="comparison-row">
                <div className="comparison-inner-flex-50">
                    <div className="comparison-metric-primary">
                        <div className="metric-value">
                            937K
                        </div>
                        <div className="metric-label">
                            Total Likes
                        </div>
                    </div>
                </div>
                <div className="comparison-inner-flex-50">
                    <div className="comparison-metric-secondary">
                        <div className="metric-value">
                            937
                        </div>
                        <div className="metric-label">
                            Average per Post
                        </div>
                    </div>
                </div>
            </div>
            <div className="comparison-row">
                <div className="comparison-inner-flex-50">
                    <div className="comparison-metric-primary">
                        <div className="metric-value">
                            937K
                        </div>
                        <div className="metric-label">
                            Total Comments
                        </div>
                    </div>
                </div>
                <div className="comparison-inner-flex-50">
                    <div className="comparison-metric-secondary">
                        <div className="metric-value">
                            937
                        </div>
                        <div className="metric-label">
                            Average per Post
                        </div>
                    </div>
                </div>
            </div>
            <div className="comparison-row">
                <div className="comparison-inner-flex-50">
                    <div className="comparison-metric-primary">
                        <div className="metric-value">
                            937K
                        </div>
                        <div className="metric-label">
                            Total Shares
                        </div>
                    </div>
                </div>
                <div className="comparison-inner-flex-50">
                    <div className="comparison-metric-secondary">
                        <div className="metric-value">
                            937
                        </div>
                        <div className="metric-label">
                            Average per Post
                        </div>
                    </div>
                </div>
            </div>
            <div className="comparison-row">
                <div className="comparison-engagement">
                    <img src={MediumSVG}/> <span>Medium</span>
                </div>
            </div>
            <div className="comparison-row">
                <div className="comparison-metric-primary">
                    <div className="metric-value">
                        2.6%
                    </div>
                </div>
            </div>
            <div className="comparison-row">
                <div className="comparison-sm">
                    <img src={InstagramSVG}/>
                    <div className="comparison-sm-text">
                        12M Instagram Interactions
                    </div>
                </div>
            </div>
            <div className="comparison-row">
                <div className="comparison-sm">
                    <img src={YoutubeSVG}/>
                    <div className="comparison-sm-text">
                        5.21% Youtube Avg. Engagement
                    </div>
                </div>
            </div>
            <div className="comparison-row">
                <div className="comparison-metric-primary">
                    <div className="metric-text">
                        #microsoft
                    </div>
                </div>
            </div>
            <div className="comparison-row">
                <BestPost/>
            </div>
            <div className="comparison-row">
                <BestPost/>
            </div>
            <div className="comparison-row">
                <BestPost/>
            </div>
            <div className="comparison-row">
                <BestPost/>
            </div>
            <div className="comparison-row">
                <BestPost/>
            </div>
        </div>
    )
}

const BestPost = (props) => {
    return (
        <div className="comparison-post">
            <div className="comparison-post-info">
                <div className="comparison-post-image" style={{backgroundImage: 'url(https://bit.ly/3tQS5Ij)'}}></div>
                <div className="comparison-post-content">
                    <div className="comparison-post-text">2021 put us all to the test. Check out these highlights…</div>
                    <div className="comparison-post-reactions">
                        <div className="comparison-post-likes"><BsHeart size="12"/> <span>92k</span></div>
                        <div className="comparison-post-comments"><FaCommentDots size="12"/> <span>922</span></div>
                    </div>
                </div>
            </div>
            <div className="comparison-post-action">
                <div className="comparison-post-value">2.43%</div>
                <a href="#" className="comparison-post-link">OPEN LINK</a>
            </div>
        </div>
    )
}