export function setWeather(weather) {
    return {
        type: '@auth/SETWEATHER',
        payload: { weather }
    }
}

export function setName(name) {
    return {
        type: '@auth/SETNAME',
        payload: { name }
    }
}

export function setLogo(logo) {
    return {
        type: '@auth/SETLOGO',
        payload: { logo }
    }
}