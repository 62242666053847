import React, { useEffect, useState } from "react";
//import { Line } from '@reactchartjs/react-chart.js'
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';

import api from '../../../services/api';
import { useSelector } from 'react-redux';

import FacebookIcon from '../../../assets/images/icon-facebook.png';
import InstagramIcon from '../../../assets/images/icon-instagram.png';
import PinterestIcon from '../../../assets/images/icon-pinterest.png';
import TwitterIcon from '../../../assets/images/icon-twitter.png';
import YoutubeIcon from '../../../assets/images/icon-youtube.png';
import Card from '../../card';
import Skeleton from 'react-loading-skeleton';

export default function Reports (props) { 

    const [option, setOption] = useState(false);

    const [loading, setLoading] = useState(true);

    const [data, setData] = useState([]);

    const [loadedSocialMedias, setLoadedSocialMedias] = useState({ facebook: false, instagram: false, pinterest: false, twitter: false, youtube: false });

    const token = useSelector(state => state.auth.token);

    useEffect(() => {
        if(props.data){
            return orderDatas(props.data);
        }

        api.post(api.defaults.baseURL + '/dashboard/reports/' + token).then(res => {
            if(res.data){
                orderDatas(res.data);
            }
        });
    }, [])

    function orderDatas(data){
        const socialMediasMap = ['facebook', 'instagram','twitter','pinterest','youtube'];
    
        const days = [];
        data[0].days.map(day => {
            days.push(day);
        })

        const finalData = {};
        socialMediasMap.map(socialMedia => {
            finalData[socialMedia] = [];
        })

        socialMediasMap.map(socialMedia => {
            days.map((day, index) => {
                if(data[0][socialMedia][index])
                    finalData[socialMedia].push({
                        day: day,
                        followers: data[0][socialMedia][index],
                        likes: data[1][socialMedia][index],
                        shares: data[2][socialMedia][index],
                        comments: data[3][socialMedia][index],
                    });
            })
        })

        socialMediasMap.map(socialMedia => {
            if(finalData[socialMedia].length > 0){
                const aux = loadedSocialMedias;
                aux[socialMedia] = true;
                setLoadedSocialMedias(aux);
                setOption(socialMedia);
            }
        })

        setData(finalData);
        setLoading(false);
    }

    function maxValue(arr, type){
        let max = 0;
        for(let i = 0; i < arr.length; i++){
            if(parseInt(arr[i][type]) > max){
                max = parseInt(arr[i][type]);
            }
        }

        return max;
    }

    function minValue(arr, type){
        let min = maxValue(arr, type);
        for(let i = 0; i < arr.length; i++){
            if(parseInt(arr[i][type]) < min){
                min = parseInt(arr[i][type]);
            }
        }

        return min;
    }

    return (
        <Card order={9} title={'Reports'} icon={'fas fa-search'} color={'pink'} id="reports" headerRight={
            <div className="d-flex justify-content-center justify-content-lg-end mt-3 mt-lg-0 locked-content">
                {
                    loading ?
                        <Skeleton width={200} height={32}/>
                    :
                    <>
                    {
                        loadedSocialMedias.facebook && 
                        <button className={`icon-button icon-button-facebook reports-icon-button-facebook ${option == 'facebook' && 'icon-button-facebook-active'}`} onClick={() => setOption('facebook')}>
                            <i className="fab fa-facebook-f"></i>
                        </button>
                    }
                    {
                        loadedSocialMedias.twitter && 
                        <button className={`icon-button icon-button-twitter ${option == 'twitter' && 'icon-button-twitter-active'}`} onClick={() => setOption('twitter')}>
                            <i className="fab fa-twitter"></i>
                        </button>
                    }
                    {
                        loadedSocialMedias.youtube &&
                        <button className={`icon-button icon-button-youtube ${option == 'youtube' && 'icon-button-youtube-active'}`} onClick={() => setOption('youtube')}>
                            <i className="fab fa-youtube"></i>
                        </button>
                    }
                    {
                        loadedSocialMedias.instagram &&
                        <button className={`icon-button icon-button-instagram ${option == 'instagram' && 'icon-button-instagram-active'}`} onClick={() => setOption('instagram')}>
                            <i className="fab fa-instagram"></i>
                        </button>
                    }
                    {
                        loadedSocialMedias.pinterest &&
                        <button className={`icon-button icon-button-pinterest ${option == 'pinterest' && 'icon-button-pinterest-active'}`} onClick={() => setOption('pinterest')}>
                            <i className="fab fa-pinterest"></i>
                        </button>
                    }
                    </>
                }
            </div>
        }>
            {
                
                !loading &&
                <ResponsiveContainer width={'100%'} height={props.height ? props.height : 400}>
                    <LineChart
                        data={data[option]}
                        margin={{
                            top: 90, right: 10, left: -230, bottom: 0,
                        }}
                    >
                        <Legend wrapperStyle={{top: 0, left: 0}}/>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="day" stroke="#9A9A9A"/>
                        <YAxis yAxisId="followers" type="number" domain={[minValue(data[option], 'followers'), maxValue(data[option], 'followers')]} stroke="#9A9A9A" tick={false}/>
                        <YAxis yAxisId="likes" type="number" domain={[minValue(data[option], 'likes'), maxValue(data[option], 'likes')]} stroke="#9A9A9A" axisLine={false} tick={false}/>
                        <YAxis yAxisId="shares" type="number" domain={[minValue(data[option], 'shares'), maxValue(data[option], 'shares')]} stroke="#9A9A9A" axisLine={false} tick={false}/>
                        <YAxis yAxisId="comments" type="number" domain={[minValue(data[option], 'comments'), maxValue(data[option], 'comments')]} stroke="#9A9A9A" axisLine={false} tick={false}/>
                        <Tooltip />
                        <Line yAxisId="followers" type="monotone" dataKey="followers" stroke="#5837FF" activeDot={{ r: 8 }} strokeWidth={2}/>
                        <Line yAxisId="likes" type="monotone" dataKey="likes" stroke="#EB5DA5" strokeWidth={2} />
                        <Line yAxisId="shares" type="monotone" dataKey="shares" stroke="#FAC246" strokeWidth={2} />
                        <Line yAxisId="comments" type="monotone" dataKey="comments" stroke="#9965EC" strokeWidth={2} />
                    </LineChart>
                </ResponsiveContainer>
            }
        </Card>
    );
}