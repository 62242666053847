import React, { useEffect, useState } from "react";
import $ from 'jquery';

import FeedPageIMG from '../../../assets/images/pages/feed-page.png';
import GridPageIMG from '../../../assets/images/pages/grid-page.png';
import InstagramPageIMG from '../../../assets/images/pages/instagram-page.png';
import MansoryPageIMG from '../../../assets/images/pages/mansory-page.png';
import NewsPageIMG from '../../../assets/images/pages/news-page.png';
import SlidePageIMG from '../../../assets/images/pages/slide-page.png';
import api from '../../../services/api';
import { useSelector } from 'react-redux';

export default function HomeLatestPosts (props) { 

    const token = useSelector(state => state.auth.token);

    const [feed, setFeed] = useState();
    const [instagram, setInstagram] = useState();
    const [masonry, setMasonry] = useState();
    const [news, setNews] = useState();
    const [slide, setSlide] = useState();
    const [grid, setGrid] = useState();

    useEffect(() => {
        $("#pages-left-arrow").click(function () {
            var leftPos = $('#scrollquestion').scrollLeft();
            $("#scrollquestion").animate({
                scrollLeft: leftPos - ($('.page-type-preview-wrapper').width() + 15)
            }, 800);
        });
    
        $("#pages-right-arrow").click(function () {
            var leftPos = $('#scrollquestion').scrollLeft();
            $("#scrollquestion").animate({
                scrollLeft: leftPos + ($('.page-type-preview-wrapper').width() + 15)
            }, 800);
        });

        api.post(api.defaults.baseURL + '/grid_pages/get/' + token).then(res => {
            setFeed('https://' + res.data.url + '/feed');
            setGrid('https://' + res.data.url);
            setInstagram('https://' + res.data.url + '/instagram');
            setMasonry('https://' + res.data.url + '/masonry');
            setSlide('https://' + res.data.url + '/slide');
            setNews('https://' + res.data.url + '/news');
        });
    
        /*
        $.post(baseUrl + '/grid_pages/get/' + adminCode, {
        }, function (data) {
            code = data;
            if (code != 0) {
                code = JSON.parse(code);
                if (typeof code === 'object') {
                    url = code.url;
                    $('#feed-page-link').attr('href', 'https://' + url + '/feed');
                    $('#instagram-page-link').attr('href', 'https://' + url + '/instagram');
                    $('#mansory-page-link').attr('href', 'https://' + url + '/masonry');
                    $('#news-page-link').attr('href', 'https://' + url + '/news');
                    $('#slide-page-link').attr('href', 'https://' + url + '/slide');
                    $('#grid-page-link').attr('href', 'https://' + url + '/feed');
                }
            }
        });
        */
    }, []);

    return (
        <div id="home-pages">
            <div className="card-body locked-content">
                <div className="page-type">
                    <div className="page-types-slide" id="scrollquestion">
                        <div className="page-type-preview-wrapper">
                            <a href={feed} id="feed-page-link" target="_blank">
                                <img src={FeedPageIMG} className="page-type-preview" />
                                <h2>Feed Page</h2>
                            </a>
                        </div>
                        <div className="page-type-preview-wrapper">
                            <a href={grid} id="grid-page-link" target="_blank">
                                <img src={GridPageIMG} className="page-type-preview" />
                                <h2>Grid Page</h2>
                            </a>
                        </div>
                        <div className="page-type-preview-wrapper">
                            <a href={instagram} id="instagram-page-link" target="_blank">
                                <img src={InstagramPageIMG} className="page-type-preview" />
                                <h2>Instagram Page</h2>
                            </a>
                        </div>
                        <div className="page-type-preview-wrapper">
                            <a href={masonry} id="mansory-page-link" target="_blank">
                                <img src={MansoryPageIMG} className="page-type-preview" />
                                <h2>Masonry Page</h2>
                            </a>
                        </div>
                        <div className="page-type-preview-wrapper">
                            <a href={news} id="news-page-link" target="_blank">
                                <img src={NewsPageIMG} className="page-type-preview" />
                                <h2>News Page</h2>
                            </a>
                        </div>
                        <div className="page-type-preview-wrapper">
                            <a href={slide} id="slide-page-link" target="_blank">
                                <img src={SlidePageIMG} className="page-type-preview" />
                                <h2>Slide Page</h2>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}