import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import $ from "jquery";
import DashboardPageLayout from "../../../layouts/dashboard-page";
import api from "../../../services/api";
import Sha256 from "../../../services/sha256";
import { useSelector, useDispatch } from "react-redux";
import MultistateInput from "../../../components/multistate-input";
import FacebookSVG from "../../../assets/icons/FacebookSVG.svg";
import InstagramSVG from "../../../assets/icons/InstagramSVG.svg";
import YoutubeSVG from "../../../assets/icons/YoutubeSVG.svg";
import TwitterSVG from "../../../assets/icons/TwitterSVG.svg";
import PinterestSVG from "../../../assets/icons/PinterestSVG.svg";
import LinkedinSVG from "../../../assets/icons/LinkedinSVG.svg";
import Header from "../../../components/header";
import {
     Heading,
     Input,
     Grid,
     Textarea,
     Box,
     Avatar,
     Text,
     Button,
     Flex,
     Stack,
     Checkbox,
     Image,
     Modal,
     ModalOverlay,
     ModalContent,
     ModalHeader,
     ModalFooter,
     ModalBody,
     ModalCloseButton,
     useDisclosure,
     Select,
} from "@chakra-ui/react";
import { FaFilePdf, FaPinterest, FaLinkedin } from "react-icons/fa";

export default function View(props) {
     const token = useSelector((state) => state.auth.token);

     const [name, setName] = useState("Name Lastname");

     const updateData = () => {};

     const { isOpen, onOpen, onClose } = useDisclosure();

     const [selectedFace, setSelectedFace] = useState();

     const [faces, setFaces] = useState([]);

     const selectFace = (url) => {
          setSelectedFace(url);
          onClose();
     };

     useEffect(() => {
          getFaces();
     }, []);

     const getFaces = () => {
          api.get(api.defaults.baseURL + "/persona/list_faces/" + token).then(
               (res) => {
                    if (res) {
                         setFaces(res.data);
                    }
               }
          );
     };

     const [age, setAge] = useState();
     const [gender, setGender] = useState();
     const [color, setColor] = useState();

     return (
          <>
               <Header
                    left={
                         <Heading fontWeight="700" fontFamily="poppins">
                              Persona Generator
                         </Heading>
                    }
               />
               <DashboardPageLayout headerColor={"purple-header"}>
                    <Grid
                         templateColumns={{
                              base: "1fr",
                              lg: "220px 1fr 1fr",
                              "2xl": "300px 1fr 1fr",
                         }}
                         gap="5"
                    >
                         <Box textAlign="center" mr={{ "2xl": 5 }}>
                              <Box pos="relative">
                                   <Avatar
                                        src={selectedFace}
                                        w={{ base: "120px", xl: "180px" }}
                                        h={{ base: "120px", xl: "180px" }}
                                        border="5px solid white"
                                        boxShadow="0px 0px 53px -16px rgba(0,0,0,0.2)"
                                   />
                                   <Button
                                        size="xs"
                                        pos="absolute"
                                        left="50%"
                                        bottom="20px"
                                        transform="translate(-50%)"
                                        color="#0E2032"
                                        bg="white"
                                        onClick={onOpen}
                                        boxShadow="sm"
                                   >
                                        <svg
                                             id="Group_191"
                                             data-name="Group 191"
                                             xmlns="http://www.w3.org/2000/svg"
                                             width="7.267"
                                             height="7.534"
                                             viewBox="0 0 7.267 7.534"
                                        >
                                             <ellipse
                                                  id="Ellipse_275"
                                                  data-name="Ellipse 275"
                                                  cx="0.982"
                                                  cy="1.018"
                                                  rx="0.982"
                                                  ry="1.018"
                                                  fill="#633a84"
                                             />
                                             <ellipse
                                                  id="Ellipse_276"
                                                  data-name="Ellipse 276"
                                                  cx="0.982"
                                                  cy="1.018"
                                                  rx="0.982"
                                                  ry="1.018"
                                                  transform="translate(2.7)"
                                                  fill="#ee558e"
                                             />
                                             <ellipse
                                                  id="Ellipse_277"
                                                  data-name="Ellipse 277"
                                                  cx="0.982"
                                                  cy="1.018"
                                                  rx="0.982"
                                                  ry="1.018"
                                                  transform="translate(5.303)"
                                                  fill="#f49521"
                                             />
                                             <ellipse
                                                  id="Ellipse_278"
                                                  data-name="Ellipse 278"
                                                  cx="0.982"
                                                  cy="1.018"
                                                  rx="0.982"
                                                  ry="1.018"
                                                  transform="translate(0 2.749)"
                                                  fill="#fad00a"
                                             />
                                             <ellipse
                                                  id="Ellipse_279"
                                                  data-name="Ellipse 279"
                                                  cx="0.982"
                                                  cy="1.018"
                                                  rx="0.982"
                                                  ry="1.018"
                                                  transform="translate(2.7 2.749)"
                                                  fill="#3fa060"
                                             />
                                             <ellipse
                                                  id="Ellipse_280"
                                                  data-name="Ellipse 280"
                                                  cx="0.982"
                                                  cy="1.018"
                                                  rx="0.982"
                                                  ry="1.018"
                                                  transform="translate(5.303 2.749)"
                                                  fill="#84b54b"
                                             />
                                             <ellipse
                                                  id="Ellipse_281"
                                                  data-name="Ellipse 281"
                                                  cx="0.982"
                                                  cy="1.018"
                                                  rx="0.982"
                                                  ry="1.018"
                                                  transform="translate(0 5.498)"
                                                  fill="#1ac0e2"
                                             />
                                             <ellipse
                                                  id="Ellipse_282"
                                                  data-name="Ellipse 282"
                                                  cx="0.982"
                                                  cy="1.018"
                                                  rx="0.982"
                                                  ry="1.018"
                                                  transform="translate(2.7 5.498)"
                                                  fill="#80d2ef"
                                             />
                                             <ellipse
                                                  id="Ellipse_283"
                                                  data-name="Ellipse 283"
                                                  cx="0.982"
                                                  cy="1.018"
                                                  rx="0.982"
                                                  ry="1.018"
                                                  transform="translate(5.303 5.498)"
                                                  fill="#a29098"
                                             />
                                        </svg>

                                        <span
                                             style={{
                                                  marginLeft: 5,
                                                  marginRight: 5,
                                             }}
                                        >
                                             UI Faces
                                        </span>

                                        <svg
                                             xmlns="http://www.w3.org/2000/svg"
                                             width="8.667"
                                             height="8.661"
                                             viewBox="0 0 8.667 8.661"
                                        >
                                             <path
                                                  id="Icon_awesome-undo"
                                                  data-name="Icon awesome-undo"
                                                  d="M3.649,3.855H.206A.206.206,0,0,1,0,3.649V.206A.206.206,0,0,1,.206,0h.825a.206.206,0,0,1,.206.206V1.549a4.262,4.262,0,1,1,.306,6.009.207.207,0,0,1-.008-.3l.584-.584A.206.206,0,0,1,2.4,6.665,3.024,3.024,0,1,0,1.96,2.618H3.649a.206.206,0,0,1,.206.206v.825a.206.206,0,0,1-.206.206Z"
                                                  fill="#8963f3"
                                             />
                                        </svg>
                                   </Button>

                                   <Modal
                                        isOpen={isOpen}
                                        onClose={onClose}
                                        size="5xl"
                                   >
                                        <ModalOverlay />
                                        <ModalContent>
                                             <ModalHeader>UI Faces</ModalHeader>
                                             <ModalCloseButton />
                                             <ModalBody>
                                                  <Grid
                                                       templateColumns={{
                                                            base: "1fr",
                                                            lg: "1fr 1fr 1fr",
                                                       }}
                                                       gap="2"
                                                       mb="5"
                                                  >
                                                       <Select
                                                            placeholder="Age"
                                                            bg="gray.100"
                                                            border="0"
                                                            onChange={(e) =>
                                                                 setAge(e.target.value)
                                                            }
                                                       >
                                                            <option value="20">
                                                                 18-20
                                                            </option>
                                                            <option value="30">
                                                                 20-30
                                                            </option>
                                                            <option value="40">
                                                                 30-40
                                                            </option>
                                                            <option value="50">
                                                                 40-50
                                                            </option>
                                                            <option value="60">
                                                                 50-60
                                                            </option>
                                                            <option value="70">
                                                                 60-70
                                                            </option>
                                                            <option value="80">
                                                                 70-80
                                                            </option>
                                                       </Select>
                                                       <Select
                                                            placeholder="Gender"
                                                            bg="gray.100"
                                                            border="0"
                                                            onChange={(e) =>
                                                                 setGender(e.target.value)
                                                            }
                                                       >
                                                            <option value="male">
                                                                 Male
                                                            </option>
                                                            <option value="female">
                                                                 Female
                                                            </option>
                                                       </Select>
                                                       <Select
                                                            placeholder="Color"
                                                            bg="gray.100"
                                                            border="0"
                                                            onChange={(e) =>
                                                                 setColor(e.target.value)
                                                            }
                                                       >
                                                            <option value="white">
                                                                 White
                                                            </option>
                                                            <option value="black">
                                                                 Black
                                                            </option>
                                                       </Select>
                                                  </Grid>
                                                  <Grid
                                                       templateColumns={{
                                                            base: "1fr 1fr 1fr",
                                                            xl:
                                                                 "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
                                                       }}
                                                       mb="5"
                                                  >
                                                       {faces.map(
                                                            (face) =>
                                                            (!age || (face.age >= age - 10 && face.age <= age)) &&
                                                            (!gender || (face.gender == gender)) &&
                                                            (!color || (face.color == color)) && (
                                                                      <Avatar
                                                                           src={
                                                                                face.url
                                                                           }
                                                                           size="lg"
                                                                           m="2"
                                                                           cursor="pointer"
                                                                           transition="0.3s"
                                                                           _hover={{
                                                                                transform:
                                                                                     "scale(1.1)",
                                                                           }}
                                                                           onClick={() =>
                                                                                selectFace(
                                                                                     face.url
                                                                                )
                                                                           }
                                                                      />
                                                                 ))
                                                       }
                                                  </Grid>
                                             </ModalBody>
                                        </ModalContent>
                                   </Modal>
                              </Box>

                              <Box mt="4" mb="5">
                                   <Text>Persona Name</Text>
                                   <MultistateInput
                                        value={name}
                                        changeFunction={setName}
                                        updateFunction={updateData}
                                        order={1}
                                        wrapperClass="persona-value-wrapper"
                                        valueClass="persona-placeholder"
                                   />
                              </Box>

                              <Box
                                   my="5"
                                   py={{ base: 4, "2xl": 7 }}
                                   px={{ base: 2, "2xl": 4 }}
                                   bg="gray.100"
                                   textAlign="left"
                                   borderRadius="7px"
                              >
                                   <Heading
                                        size="xs"
                                        ml="2"
                                        fontFamily="poppins"
                                        mb="1"
                                   >
                                        About
                                   </Heading>
                                   <Textarea
                                        p="2"
                                        border="0"
                                        height={{ base: "200px", lg: "400px" }}
                                        fontSize={{
                                             base: "12px",
                                             "2xl": "15px",
                                        }}
                                        value="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua"
                                   />
                              </Box>

                              <Button
                                   fontSize={{ base: "md", "2xl": "lg" }}
                                   fontWeight="bold"
                                   color="white"
                                   w={{ base: "80%", "2xl": "70%" }}
                                   h="70px"
                                   borderRadius="10px"
                                   bg="linear-gradient(141deg, rgba(137,99,243,1) 0%, rgba(206,91,203,1) 100%)"
                                   transition="0.3s"
                                   _hover={{
                                        bg:
                                             "linear-gradient(141deg, rgba(137,99,243,1) 0%, rgba(206,91,203,1) 100%)",
                                        opacity: 0.8,
                                   }}
                              >
                                   <FaFilePdf
                                        size="30"
                                        style={{ marginRight: 10 }}
                                   />{" "}
                                   Get the PDF
                              </Button>
                         </Box>
                         <Box
                              d="flex"
                              flexDirection="column"
                              justifyContent="space-between"
                         >
                              <Box>
                                   <Grid mb="3">
                                        <MyInput placeholder="Carreer" />
                                   </Grid>
                                   <Grid templateColumns="1fr 1fr 2fr" gap="3">
                                        <MyInput placeholder="Age" />
                                        <MyInput placeholder="Location" />
                                        <MyInput placeholder="Business" />
                                   </Grid>

                                   <Box
                                        w="100%"
                                        h="1px"
                                        bg="#8963F3"
                                        my="5"
                                   ></Box>

                                   <Grid templateColumns="1fr 1.3fr" gap="3">
                                        <MyInput placeholder="Primary language" />
                                        <MyInput placeholder="When they are online" />
                                   </Grid>
                              </Box>

                              <Box>
                                   <Heading
                                        mt="5"
                                        size="sm"
                                        fontFamily="poppins"
                                        mb="3"
                                   >
                                        Interests
                                   </Heading>
                                   <Grid gap="3">
                                        <CustomInput />
                                        <CustomInput />
                                        <CustomInput />
                                        <CustomInput />
                                   </Grid>
                              </Box>

                              <Box>
                                   <Heading
                                        mt="5"
                                        size="sm"
                                        fontFamily="poppins"
                                        mb="3"
                                   >
                                        Brands they love
                                   </Heading>
                                   <Grid gap="3" templateColumns="1fr 1fr">
                                        <Input
                                             type="text"
                                             size="lg"
                                             h={{ base: "48px", "2xl": "60px" }}
                                             fontSize="sm"
                                             bg="gray.100"
                                             border="0"
                                             m="0!important"
                                        />
                                        <Input
                                             type="text"
                                             size="lg"
                                             h={{ base: "48px", "2xl": "60px" }}
                                             fontSize="sm"
                                             bg="gray.100"
                                             border="0"
                                             m="0!important"
                                        />
                                        <Input
                                             type="text"
                                             size="lg"
                                             h={{ base: "48px", "2xl": "60px" }}
                                             fontSize="sm"
                                             bg="gray.100"
                                             border="0"
                                             m="0!important"
                                        />
                                        <Input
                                             type="text"
                                             size="lg"
                                             h={{ base: "48px", "2xl": "60px" }}
                                             fontSize="sm"
                                             bg="gray.100"
                                             border="0"
                                             m="0!important"
                                        />
                                   </Grid>
                              </Box>
                         </Box>
                         <Box
                              d="flex"
                              flexDirection="column"
                              justifyContent="space-between"
                         >
                              <Box
                                   bg="gray.100"
                                   p={{ base: 3, "2xl": 7 }}
                                   borderRadius="7px"
                              >
                                   <Heading
                                        size="xs"
                                        fontFamily="poppins"
                                        mb="3"
                                   >
                                        Platforms they use
                                   </Heading>

                                   <Box id="persona-sms">
                                        <Stack
                                             direction="row"
                                             justifyContent="space-between"
                                        >
                                             <Checkbox>
                                                  <Image
                                                       src={FacebookSVG}
                                                       h={{
                                                            base: "15px",
                                                            md: "20px",
                                                            xl: "25px",
                                                            "2xl": "40px",
                                                       }}
                                                  />
                                             </Checkbox>
                                             <Checkbox>
                                                  <Image
                                                       src={InstagramSVG}
                                                       h={{
                                                            base: "15px",
                                                            md: "20px",
                                                            xl: "25px",
                                                            "2xl": "40px",
                                                       }}
                                                  />
                                             </Checkbox>
                                             <Checkbox>
                                                  <Image
                                                       src={TwitterSVG}
                                                       h={{
                                                            base: "15px",
                                                            md: "20px",
                                                            xl: "25px",
                                                            "2xl": "40px",
                                                       }}
                                                  />
                                             </Checkbox>
                                             <Checkbox>
                                                  <Image
                                                       src={YoutubeSVG}
                                                       h={{
                                                            base: "15px",
                                                            md: "20px",
                                                            xl: "25px",
                                                            "2xl": "40px",
                                                       }}
                                                  />
                                             </Checkbox>
                                             <Checkbox>
                                                  <Image
                                                       src={LinkedinSVG}
                                                       h={{
                                                            base: "15px",
                                                            md: "20px",
                                                            xl: "25px",
                                                            "2xl": "40px",
                                                       }}
                                                  />
                                             </Checkbox>
                                             <Checkbox>
                                                  <Image
                                                       src={PinterestSVG}
                                                       h={{
                                                            base: "15px",
                                                            md: "20px",
                                                            xl: "25px",
                                                            "2xl": "40px",
                                                       }}
                                                  />
                                             </Checkbox>
                                        </Stack>
                                   </Box>
                              </Box>

                              <Box>
                                   <Heading
                                        mt="5"
                                        size="sm"
                                        fontFamily="poppins"
                                        mb="3"
                                   >
                                        Goals
                                   </Heading>
                                   <Grid gap="3">
                                        <CustomInput />
                                        <CustomInput />
                                        <CustomInput />
                                   </Grid>
                              </Box>

                              <Box>
                                   <Heading
                                        mt="5"
                                        size="sm"
                                        fontFamily="poppins"
                                        mb="3"
                                   >
                                        Frustrations
                                   </Heading>
                                   <Grid gap="3">
                                        <CustomInput />
                                        <CustomInput />
                                        <CustomInput />
                                        <CustomInput />
                                   </Grid>
                              </Box>

                              <Box>
                                   <Heading
                                        mt="5"
                                        size="sm"
                                        fontFamily="poppins"
                                        mb="3"
                                   >
                                        Influencers they follow
                                   </Heading>
                                   <Grid gap="3">
                                        <Textarea
                                             type="text"
                                             size="lg"
                                             fontSize="sm"
                                             bg="gray.100"
                                             border="0"
                                        />
                                   </Grid>
                              </Box>
                         </Box>
                    </Grid>
               </DashboardPageLayout>
          </>
     );
}

const MyInput = (props) => {
     return (
          <Input
               type="text"
               size="lg"
               h={{ base: "48px", "2xl": "60px" }}
               fontSize="sm"
               bg="gray.100"
               border="0"
               m="0!important"
               placeholder={props.placeholder}
          />
     );
};

const CustomInput = (props) => {
     return (
          <Flex>
               <Box w="15px" h="100%" bg="#8963F3"></Box>
               <Input
                    type="text"
                    borderTopLeftRadius="0"
                    borderBottomLeftRadius="0"
                    size="lg"
                    h={{ base: "48px", "2xl": "60px" }}
                    fontSize="sm"
                    bg="gray.100"
                    border="0"
                    m="0!important"
               />
          </Flex>
     );
};
