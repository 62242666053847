import React, { useEffect, useState } from "react";

import api from '../../../services/api';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';

import { FaArrowCircleRight } from 'react-icons/fa';

export default function HomeLatestPosts (props) { 
    const token = useSelector(state => state.auth.token);

    const [deliveries, setDeliveries] = useState([]);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        api.post(api.defaults.baseURL + '/my_projects/logs/' + token).then(res => {
            setDeliveries(res.data);
        });
        setLoading(false);
    }, []);

    const animation = {
        in: index => ({
            opacity: 1,
            y: 0,
            transition: { type: 'linear', delay: index * 0.1 },
        }),
        out: index => ({
            opacity: 0,
            y: 20,
            transition: { type: 'linear', delay: index * 0.05 },
        }),
    }

    return (
        !loading &&
        <table className="table logs-table mt-2 mb-2" id="home-logs-table">
            <thead>
                <tr>
                    <th>Description</th>
                    <th className="text-center">Access</th>
                </tr>
            </thead>
            <tbody id="home-logs-table-body">
                {
                    deliveries.map((delivery, index) => (
                        <motion.tr 
                            initial="out"
                            animate="in"
                            exit="out"
                            variants={animation} 
                            custom={index} 
                            key={'delivery-'+delivery.id}
                        >
                            <td>{delivery.title}</td>
                            <td> <a href={delivery.url} target="_blank" className="see-delivery"><FaArrowCircleRight size={20} style={{marginLeft: 'auto', marginRight: 'auto'}}/></a></td>
                        </motion.tr>
                    ))
                }
            </tbody>
        </table>
    );
}