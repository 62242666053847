import React, { useEffect, useState } from "react";
import {
  Link,
  Redirect
} from "react-router-dom";

import { Form, FormGroup, Input, Label, Row, Col, Container, CustomInput  } from 'reactstrap';
import sha256 from 'sha256';

import { useDispatch, useSelector } from 'react-redux';
import { loginAction } from '../../store/modules/auth/actions';
import { setPermissions } from '../../store/modules/permissions/actions';

import DashboardLogo from '../../assets/images/dashboard-logo.svg';

import api from '../../services/api';
import Sha256 from '../../services/sha256';

import { motion, AnimatePresence } from 'framer-motion';
import { toast } from 'react-toastify';

export default function Layout(props) {

    const [loading, setLoading] = useState(false);
    const [verifying, setVerifying] = useState(true);

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();

    const [logged, setLogged] = useState(false);
    const [error, setError] = useState(false);

    const dispatch = useDispatch();

    const token = useSelector(state => state.auth.token);

    useEffect(() => {
        if(token){
            api.post(`user/verify`, {
                session: token
            }).then(res => {
                setLogged(true);
                setVerifying(false);
            }).catch(err => {
                setVerifying(false);
            });
        } else {
            setVerifying(false);
        }
    }, []);

    function submit(e){
        e.preventDefault();
        setLoading(true);
        api.post('user/login', {
            mail: email,
            password: Sha256.hash(password),
        }).then(res => {
            if(res.data != 0){
                const tokenReceived = res.data;
                api.post(api.defaults.baseURL + '/application/permissions/' + tokenReceived).then(res => {
                    dispatch(loginAction(tokenReceived));
                    dispatch(setPermissions(res.data));
                    setLogged(true);
                    setLoading(false);
                });
            } else {
                setError(true);
                toast.error('Wrong email or password.');
                setLoading(false);
            }
        });
    }

    const imageAnimation = {
        in: {
            opacity: 1,
            x: 0,
            transition: { type: 'linear' },
        },
        out: {
            opacity: 0,
            x: 0,
            transition: { type: 'linear' },
        },
    }

    const formAnimation = {
        init: {
            opacity: 0,
            y: 0,
            transition: 'linear',
            duration: 0.3,
        },
        loaded: {
            opacity: 1,
            y: 0,
            transition: { type: "spring", damping: 50}
        },
        logged: {
            opacity: 0,
            y: -1000,
            transition: 'linear',
            duration: 5,
        },
    }

    const loginAnimation = {
        initial: {
            opacity: 0,
            transition: { type: 'ease' },
        },
        in: {
            opacity: 1,
            y: 0,
            transition: { type: 'ease' },
        },
        out: {
            opacity: 0,
            transition: { type: 'ease' },
        },
    }

    return (
        !verifying &&
        (logged ?
        <Redirect to='/dashboard'/>
        :
        <div className="dashboard-login-wrapper">
            <motion.div 
                initial="out"
                animate="in"
                exit="out"
                variants={imageAnimation} 
                className="dashboard-login-image"
            ></motion.div>
            <motion.div 
                initial="init"
                animate="loaded"
                exit="logged"
                variants={formAnimation} 
                className="dashboard-login-form-wrapper"
            >
                <img src={DashboardLogo} className="c180-login-logo" />
                <h1 className="login-welcome">Welcome back</h1>
                <form id="login-form" onSubmit={submit}>
                    <div className="d-flex align-items-center mb-3">
                        <div className="mr-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="23.325" height="25.99" viewBox="0 0 23.325 25.99">
                                    <g id="Icon_feather-user" data-name="Icon feather-user" transform="translate(1 1)">
                                        <path id="Path_203" data-name="Path 203" d="M27.325,30.5V27.831A5.331,5.331,0,0,0,21.993,22.5H11.331A5.331,5.331,0,0,0,6,27.831V30.5" transform="translate(-6 -6.507)" fill="none" stroke="#949494" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                                        <path id="Path_204" data-name="Path 204" d="M22.662,9.831A5.331,5.331,0,1,1,17.331,4.5,5.331,5.331,0,0,1,22.662,9.831Z" transform="translate(-6.669 -4.5)" fill="none" stroke="#949494" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                                    </g>
                                </svg>
                        </div>
                        <input type="email" id="mail" className="login-input" placeholder="Email" required onChange={(e) => setEmail(e.target.value)}/>
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="mr-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20.972" height="27.526" viewBox="0 0 20.972 27.526">
                                    <path id="Icon_material-lock-outline" data-name="Icon material-lock-outline" d="M16.486,22.472a2.622,2.622,0,1,0-2.622-2.622A2.629,2.629,0,0,0,16.486,22.472Zm7.865-11.8H23.04V8.054a6.554,6.554,0,0,0-13.108,0v2.622H8.622A2.629,2.629,0,0,0,6,13.3V26.4a2.629,2.629,0,0,0,2.622,2.622H24.351A2.629,2.629,0,0,0,26.972,26.4V13.3A2.629,2.629,0,0,0,24.351,10.675ZM12.423,8.054a4.063,4.063,0,0,1,8.127,0v2.622H12.423ZM24.351,26.4H8.622V13.3H24.351Z" transform="translate(-6 -1.5)" fill="#949494"/>
                                </svg>
                        </div>
                        <input type="password" id="password" className="login-input" placeholder="Password" required onChange={(e) => setPassword(e.target.value)}/>
                    </div>

                    <div className="text-center">
                        <AnimatePresence exitBeforeEnter>
                        {
                            loading ?
                            <motion.div 
                                initial="initial"
                                animate="in"
                                exit="out"
                                variants={loginAnimation} 
                                className="spinner-border text-primary login-loading" 
                                role="status" 
                                id="logging"
                            >
                                <span className="sr-only">Loading...</span>
                            </motion.div>
                            :
                            <motion.button 
                                initial="initial"
                                animate="in"
                                exit="out"
                                variants={loginAnimation} 
                                type="submit" 
                                id="login-button" 
                                className="dashboard-login-button"
                            >
                                LOGIN
                            </motion.button>
                        }
                        </AnimatePresence>
                    </div>
                </form>

                <a href="#" className="dashboard-login-link" style={{opacity: 0}}>Forgot your password?</a>

                <div className="dashboard-help" style={{opacity: 0}}>
                    Having trouble acessing?
                    <a href="#" className="trouble-link">Click here.</a>
                </div>
            </motion.div>
        </div>
        )
    );
}