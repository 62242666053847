const INITIAL_STATE = {
    name: null,
    weather: {},
    logo: null,
};

export default function auth(state = INITIAL_STATE, action) {
    switch (action.type) {
        case '@auth/SETNAME': {
            return {
                ...state,
                name: action.payload.name
            }
        }
        case '@auth/SETWEATHER': {
            return {
                ...state,
                weather: action.payload.weather
            }
        }
        case '@auth/SETLOGO': {
            return {
                ...state,
                logo: action.payload.logo
            }
        }
        default:
            return state;
    }
}