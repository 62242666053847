import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaCreditCard, FaPen, FaCheck } from 'react-icons/fa';
import DashboardPageLayout from '../../../layouts/dashboard-page';
import api from '../../../services/api';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import CardChipSVG from '../../../assets/images/billing/card-chip.svg';
import MasterCardSVG from '../../../assets/images/billing/mastercard.svg';
import StarSVG from '../../../assets/images/plans/star.svg';
import MegaSVG from '../../../assets/images/plans/mega.svg';
import RocketSVG from '../../../assets/images/plans/rocket.svg';
import Header from '../../../components/header';
import { Heading } from '@chakra-ui/react';

export default function View(props) {
    const token = useSelector(state => state.auth.token);

    const [name, setName] = useState('');
    const [email, setEmail] = useState();
    const [phone, setPhone] = useState();
    const [company, setCompany] = useState();
    const [country, setCountry] = useState();
    const [logo, setLogo] = useState();

    const [loading, setLoading] = useState(false);

    const plans = [
        {
            image: StarSVG,
            name: 'Basic',
            price: '00',
        },
        {
            image: MegaSVG,
            name: 'Standard',
            price: '90',
        },
        {
            image: RocketSVG,
            name: 'Premium',
            price: '120',
        },
    ]

    useEffect(() => {
        api.get(api.defaults.baseURL + '/client_profile/get/' + token).then(res => {
            if(res){
                const { data } = res;
    
                setName(data.name);
                setEmail(data.email);
                setPhone(data.phone);
                setCompany(data.company);
                setCountry(data.address);
                setLogo(data.logo);
    
                setLoading(false);
            }
        });
    }, []);

    return (
        <>
        <Header left={
            <Heading fontWeight="700" fontFamily="poppins">Dashboard Plans</Heading>
        }/>
        <DashboardPageLayout notBoxed>
            <div className="plans-grid">
                <div id="plans-header">
                    <Link to="/dashboard/billing" className="back-button">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14.665" height="25.33" viewBox="0 0 14.665 25.33">
                            <g id="Icon_feather-arrow-right" data-name="Icon feather-arrow-right" transform="translate(11.837 22.502) rotate(180)">
                                <path id="Path_16" data-name="Path 16" d="M0,0,9.837,9.837,0,19.673" transform="translate(0)" fill="none" stroke="#0676ed" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"/>
                            </g>
                        </svg>
                    </Link>
                    <div className="hero purple-header">
                        <div>
                            <span>CURRENT SUBSCRIPTION PLAN:</span>
                            <span className="plan">STANDARD</span>
                        </div>
                        <div className="value">
                            <span>VALUE:</span>
                            <h1 className="price">$90</h1>
                        </div>
                    </div>
                </div>
                <div id="plans-body">
                    {
                        plans.map(plan => (
                            <Plan plan={plan}/>
                        ))
                    }
                </div>
                <div id="plans-aside">
                    <div className="aside-card payment-information">
                        <div className="header">
                            <div className="title">
                                <FaCreditCard size={25}/>
                                <h2>Payment</h2>
                            </div>
                            <div>
                                <motion.button whileTap={{ scale: 0.8 }}><FaPen size={12}/></motion.button>
                                <motion.button className="checked" whileTap={{ scale: 0.8 }}><FaCheck size={15}/></motion.button>
                            </div>
                        </div>
                        <div className="content">
                            <div className="credit-card">
                                <div className="header">
                                    <img src={CardChipSVG}/>
                                    <img src={MasterCardSVG}/>
                                </div>
                                <p className="card-number">**** **** **** 0000</p>
                                <div className="footer">
                                    <p className="text">{name ? name.toUpperCase() : ''}</p>
                                    <div className="text-left">
                                        <span className="date-label">DATE</span>
                                        <div className="text">00/2000</div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-data">
                                <p><span>Mastercard</span> 0000</p>
                                <p><span>Exp. Date</span> 00/2000</p>
                            </div>
                        </div>
                    </div>

                    <div className="aside-card aside-profile">
                        <div className="header">
                            <div className="image" style={{backgroundImage: `url(${logo})`}}></div>
                            <h3 className="name">{name}</h3>
                            <p className="email">{email}</p>
                        </div>
                        <div>
                            <p><strong>Company:</strong> {company}</p>
                            <p><strong>Address:</strong> {country}</p>
                            <p><strong>Phone:</strong> {phone}</p>
                        </div>
                        <div className="billing-period">
                            <div className="mb-3">
                                <p><strong>Billing Period Start</strong></p>
                                <p>January 12th 2021, 11:28:29 pm</p>
                            </div>
                            <div>
                                <p><strong>Billing Period End</strong></p>
                                <p>February 12th 2021, 11:28:29 pm</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </DashboardPageLayout>
        </>
    );
}

function Plan({plan}){
    return (
        <Link to='/' className="plan-block">
            <img src={plan.image}/>
            <div className="plan">{plan.name}</div>
            <div className="price">
                <div className="price-flex">
                    {
                        plan.price == 0 ?
                            <span className="value">FREE</span>
                        :
                        <>
                            <span className="dolar">$</span>
                            <span className="value">{plan.price}</span>
                        </>
                    }
                </div>
                {
                    plan.price != 0 &&
                    <span className="period">/month</span>
                }
            </div>
            <div className="title">
                One
            </div>
            <p className="subtitle">Lorem ipsum</p>
            <p className="benefit">Lorem ipsum</p>
            <p className="benefit">Lorem ipsum</p>
            <p className="benefit">Lorem ipsum</p>

            <Link className="button" to='/'>GET STARTED</Link>
        </Link>
    )
}