import React, { useEffect, useState } from "react";
import { MdNotifications } from "react-icons/md";
import { IoClose } from "react-icons/io5";
import { BsPauseFill, BsPlayFill } from "react-icons/bs";
import { AnimatePresence, motion, AnimateSharedLayout } from "framer-motion";
import useOnclickOutside from "react-cool-onclickoutside";
import { Link } from "react-router-dom";
import api from "../../services/api";
import { useSelector } from "react-redux";

import "./style.css";

export default function SocialProof(props) {
     const token = useSelector((state) => state.auth.token);
     const permissions = useSelector(state => state.permissions);

     const [data, setData] = useState([]);

     const [closed, setClosed] = useState(true);
     const [changeClosed, setChangeClosed] = useState(0);
     const [panel, setPanel] = useState(false);
     const [paused, setPaused] = useState(false);
     const [current, setCurrent] = useState(0);
     const [mouseOver, setMouseOver] = useState(false);

     useEffect(() => {
          if(permissions.social_proof){
               api.post(
                    api.defaults.baseURL + "/user_logs/notifications/" + token
               ).then((res) => {
                    if (res) {
                         setData(res.data);
                         console.log(res.data);
                    }
               });
          }
     }, [token]);

     useEffect(() => {
          if (current < data.length) openNotif();
     }, [data, current, paused]);

     const ref = useOnclickOutside(() => {
          setPanel(false);
     });

     const delay = (ms) => new Promise((res) => setTimeout(res, ms));

     useEffect(() => {
          if (mouseOver) {
               setClosed(false);
          } else {
               setClosed(true);
               setCurrent(current + 1);
          }
     }, [changeClosed, mouseOver]);

     const openNotif = async () => {
          if (!paused) {
               await delay(5000);
               setClosed(false);
               await delay(5000);

               if (!mouseOver) {
                    setChangeClosed(changeClosed + 1);
               }
          }
     };

     async function mouseOut() {
          setMouseOver(false);

          await delay(5000);
          setClosed(true);
     }

     const animation = {
          in: {
               opacity: 0.9,
               x: 0,
               transition: { type: "ease" },
               duration: 5,
          },
          out: {
               opacity: 0,
               x: 350,
               transition: { type: "ease" },
               duration: 5,
          },
     };

     const animationNotif = {
          in: {
               opacity: 1,
               y: 0,
               transition: { type: "ease" },
          },
          out: {
               opacity: 0,
               y: 50,
               transition: { type: "ease" },
          },
     };

     return (
          <div className="social-proof-wrapper" ref={ref}>
               {
                    permissions.social_proof &&
                    <>
                         <div className="social-proof-control-wrapper">
                              <motion.button
                                   onClick={() => setPanel(!panel)}
                                   whileTap={{ scale: 0.8 }}
                                   className="social-proof-control-button"
                              >
                                   <MdNotifications size="25" />
                              </motion.button>
                              <motion.button
                                   onClick={() => setPaused(!paused)}
                                   whileTap={{ scale: 0.8 }}
                                   className="social-proof-pause-play"
                              >
                                   {paused ? (
                                        <BsPlayFill color="#534FFF" />
                                   ) : (
                                        <BsPauseFill color="#534FFF" />
                                   )}
                              </motion.button>
                         </div>
                         <AnimatePresence>
                              {panel && (
                                   <motion.div
                                        initial="out"
                                        animate="in"
                                        exit="out"
                                        variants={animationNotif}
                                        className="social-proof-notifications"
                                   >
                                        <div className="social-proof-notifications-header">
                                             <MdNotifications size="20" />
                                             <h5 className="social-proof-title">
                                                  Notifications
                                             </h5>
                                        </div>
                                        <motion.button
                                             onClick={() => setPanel(false)}
                                             whileTap={{ scale: 0.8 }}
                                             className="social-proof-close-button"
                                        >
                                             <IoClose />
                                        </motion.button>
                                        <div className="social-proof-notifications-body">
                                             <AnimateSharedLayout>
                                                  {data.map(
                                                       (notif, index) =>
                                                            index < current && (
                                                                 <div className="social-proof-flex">
                                                                      <div
                                                                           className="social-proof-image"
                                                                           style={{
                                                                                backgroundImage:
                                                                                     "url(https://bit.ly/3qban55)",
                                                                           }}
                                                                      ></div>
                                                                      <div className="social-proof-content">
                                                                           {notif.name}
                                                                      </div>
                                                                 </div>
                                                            )
                                                  )}
                                             </AnimateSharedLayout>
                                        </div>
                                   </motion.div>
                              )}
                         </AnimatePresence>
                         <AnimatePresence>
                              {!closed && !panel && (
                                   <>
                                        {data.map(
                                             (notif, index) =>
                                                  index == current && (
                                                       <motion.div
                                                            initial="out"
                                                            animate="in"
                                                            exit="out"
                                                            variants={animation}
                                                            className="social-proof"
                                                            key={`notification${index}`}
                                                            onMouseEnter={() =>
                                                                 setMouseOver(true)
                                                            }
                                                            onMouseLeave={mouseOut}
                                                       >
                                                            <div className="social-proof-notifications-header">
                                                                 <MdNotifications size="20" />
                                                                 <h5 className="social-proof-title">
                                                                      New notification
                                                                 </h5>
                                                            </div>
                                                            <motion.button
                                                                 onClick={() => {
                                                                      setClosed(true);
                                                                      setCurrent(
                                                                           current + 1
                                                                      );
                                                                 }}
                                                                 whileTap={{ scale: 0.8 }}
                                                                 className="social-proof-close-button"
                                                            >
                                                                 <IoClose />
                                                            </motion.button>
                                                            <div className="social-proof-flex">
                                                                 <div
                                                                      className="social-proof-image"
                                                                      style={{
                                                                           backgroundImage:
                                                                                "url(https://bit.ly/3qban55)",
                                                                      }}
                                                                 ></div>
                                                                 <div className="social-proof-content">
                                                                      {notif.name}
                                                                 </div>
                                                            </div>
                                                       </motion.div>
                                                  )
                                        )}
                                   </>
                              )}
                         </AnimatePresence>
                    </>
               }
          </div>
     );
}
