import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { FaInstagram } from 'react-icons/fa';
import Header from '../../../components/header';
import { Heading } from '@chakra-ui/react';
import DashboardPageLayout from '../../../layouts/dashboard-page';
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from 'react-loading-skeleton';
import LeadCard from '../../../components/lead-card';
import LeadRow from '../../../components/lead-row';
import Modal from '../../../components/modal';

import api from '../../../services/api';

import { useSelector } from 'react-redux';

export default function View(props) {
    const token = useSelector(state => state.auth.token);

    const [loading, setLoading] = useState(false);

    const [viewType, setViewType] = useState('cards');

    const [leads, setLeads] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [limitPage, setLimitPage] = useState(1);

    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if(searchText.length == 0){
            setCurrentPage(1);
            fetchData(true);
        }
    }, [searchText]);

    function fetchData(reset = false){
        api.post(api.defaults.baseURL + '/user_leads/list/' + token, {
            page: reset ? 1 : currentPage,
            search: searchText ? searchText : null,
            all: searchText ? true : false,
        }).then(res => {
            if(res){
                if(res.data != 0){
                    if(currentPage == 1 || reset){
                        setLeads(res.data.data);
                        setCurrentPage(2);
                    } else {
                        setLeads([...leads, ...res.data.data]);
                        setCurrentPage(currentPage + 1);
                    }
                    setLoading(false);
                    setLimitPage(res.data.page.total);
                } else {
                    setCurrentPage(limitPage + 1);
                }
            }
        });
    }

    const animation = {
        in: order => ({
            opacity: 1,
            y: 0,
            transition: { type: 'linear', delay: order * 0.1 },
        }),
        out: order => ({
            opacity: 0,
            y: 20,
            transition: { type: 'linear', delay: order * 0.05 },
        }),
    }

    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [twitter, setTwitter] = useState();
    const [facebook, setFacebook] = useState();
    const [instagram, setInstagram] = useState();
    const [linkedin, setLinkedin] = useState();

    function submit(e, _callback){
        e.preventDefault();
        api.post(api.defaults.baseURL + '/user_leads/create/' + token, {
            name,
            email,
            facebook, 
            instagram, 
            twitter,
            linkedin
        }).then(res => {
            setLeads([res.data, ...leads]);
            toast.success('Lead added with success')
            _callback();
        })
    }

    function search(e){
        e.preventDefault();
        api.post(api.defaults.baseURL + '/user_leads/list/' + token, {
            search: searchText,
            all: false,
        }).then(res => {
            if(res){
                if(res.data != 0){
                    setLeads([...res.data.data]);
                    setLoading(false);
                    setCurrentPage(1);
                    setLimitPage(res.data.page.limit);
                } else {
                    setCurrentPage(limitPage + 1);
                }
            }
        });
    }

    return (
        <>
        <Header left={
            <Heading fontWeight="700" fontFamily="poppins">Leads</Heading>
        }/>
        <DashboardPageLayout 
            title={'Manage your leads'} 
            headerColor={'pink-header'}
        >
            <div className="d-flex justify-content-between">
                <div className="leads-page-selector">
                    <button className={`leads-page-selector-button ${viewType === 'cards' && 'leads-page-selector-button-active'}`} onClick={() => setViewType('cards')}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                            <g id="Group_204" data-name="Group 204" transform="translate(-0.28 -0.128)">
                                <rect id="Rectangle_329" data-name="Rectangle 329" width="5" height="5" rx="2" transform="translate(0.28 0.128)" fill="#D0D2E1"></rect>
                                <rect id="Rectangle_334" data-name="Rectangle 334" width="5" height="5" rx="2" transform="translate(0.28 7.128)" fill="#D0D2E1"></rect>
                                <rect id="Rectangle_337" data-name="Rectangle 337" width="5" height="5" rx="2" transform="translate(0.28 13.128)" fill="#D0D2E1"></rect>
                                <rect id="Rectangle_330" data-name="Rectangle 330" width="4" height="5" rx="2" transform="translate(7.28 0.128)" fill="#D0D2E1"></rect>
                                <rect id="Rectangle_333" data-name="Rectangle 333" width="4" height="5" rx="2" transform="translate(7.28 7.128)" fill="#D0D2E1"></rect>
                                <rect id="Rectangle_336" data-name="Rectangle 336" width="4" height="5" rx="2" transform="translate(7.28 13.128)" fill="#D0D2E1"></rect>
                                <rect id="Rectangle_331" data-name="Rectangle 331" width="5" height="5" rx="2" transform="translate(13.28 0.128)" fill="#D0D2E1"></rect>
                                <rect id="Rectangle_332" data-name="Rectangle 332" width="5" height="5" rx="2" transform="translate(13.28 7.128)" fill="#D0D2E1"></rect>
                                <rect id="Rectangle_335" data-name="Rectangle 335" width="5" height="5" rx="2" transform="translate(13.28 13.128)" fill="#D0D2E1"></rect>
                            </g>
                        </svg>
                    </button>
                    <button className={`leads-page-selector-button ${viewType === 'table' && 'leads-page-selector-button-active'}`} onClick={() => setViewType('table')}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="17.963" height="15.623" viewBox="0 0 17.963 15.623">
                            <g id="Group_235" data-name="Group 235" transform="translate(-864.481 -359.019)">
                                <path id="Path_176" data-name="Path 176" d="M3408.084,1560.262h17.963" transform="translate(-2543.603 -1200.243)" fill="none" stroke="#d0d2e1" strokeWidth="2"></path>
                                <path id="Path_177" data-name="Path 177" d="M3408.084,1560.262h17.963" transform="translate(-2543.603 -1193.432)" fill="none" stroke="#d0d2e1" strokeWidth="2"></path>
                                <path id="Path_178" data-name="Path 178" d="M3408.084,1560.262h17.963" transform="translate(-2543.603 -1186.62)" fill="none" stroke="#d0d2e1" strokeWidth="2"></path>
                            </g>
                        </svg>
                    </button>
                </div>

                <div className="d-flex">
                    <form id="leads-page-search-form" onSubmit={search} className="mr-1">
                        <input type="text" id="leads-page-search" placeholder="Search" onChange={(e) => setSearchText(e.target.value)}/>
                        <button id="leads-page-search-button">
                            <svg xmlns="http://www.w3.org/2000/svg" width="17.999" height="18.002" viewBox="0 0 17.999 18.002" id="leads-page-search-icon">
                                <path id="Icon_awesome-search" data-name="Icon awesome-search" d="M17.754,15.564l-3.505-3.505a.843.843,0,0,0-.6-.246h-.573a7.309,7.309,0,1,0-1.266,1.266v.573a.843.843,0,0,0,.246.6l3.505,3.505a.84.84,0,0,0,1.192,0l.995-.995a.848.848,0,0,0,0-1.2ZM7.312,11.812a4.5,4.5,0,1,1,4.5-4.5A4.5,4.5,0,0,1,7.312,11.812Z" fill="#d0d2e1"></path>
                            </svg>
                        </button>
                    </form>
                    <Modal
                        buttonClass={'dashboard-button primary-button mb-3 add-lead-button'}
                        buttonText={'Add lead'}
                        title={'Add lead'}
                        size={'lg'}
                        icon={
                            <div></div>
                        }
                        submit={submit}
                        form
                    >
                        <div className="row">
                            <div className="col-12 col-lg-6 d-flex align-items-center mb-3">
                                <div className="add-lead-form-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                        <path id="Icon_material-person" data-name="Icon material-person" d="M21,21a7.5,7.5,0,1,0-7.5-7.5A7.5,7.5,0,0,0,21,21Zm0,3.75c-5.006,0-15,2.512-15,7.5V36H36V32.25C36,27.262,26.006,24.75,21,24.75Z" transform="translate(-6 -6)" fill="#33323d"></path>
                                    </svg>
                                </div>
                                <input type="text" className="add-lead-form-input add-lead-form-input-required" onChange={(e) => setName(e.target.value)} placeholder="John Doe"/>
                            </div>
                            <div className="col-12 col-lg-6 d-flex align-items-center mb-3">
                                <div className="add-lead-form-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="24" viewBox="0 0 30 24">
                                        <path id="Icon_material-email" data-name="Icon material-email" d="M30,6H6A3,3,0,0,0,3.015,9L3,27a3.009,3.009,0,0,0,3,3H30a3.009,3.009,0,0,0,3-3V9A3.009,3.009,0,0,0,30,6Zm0,6L18,19.5,6,12V9l12,7.5L30,9Z" transform="translate(-3 -6)" fill="#33323d"></path>
                                    </svg>
                                </div>
                                <input type="text" className="add-lead-form-input add-lead-form-input-required" onChange={(e) => setEmail(e.target.value)} placeholder="johndoe@c180.agency"/>
                            </div>
                            <div className="col-12 col-lg-6 d-flex align-items-center mb-3">
                                <div className="add-lead-form-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="29.256" viewBox="0 0 36 29.256">
                                        <path id="Icon_metro-twitter" data-name="Icon metro-twitter" d="M38.57,8.28a14.767,14.767,0,0,1-4.242,1.163,7.408,7.408,0,0,0,3.247-4.086,14.786,14.786,0,0,1-4.69,1.792A7.393,7.393,0,0,0,20.3,13.886,20.968,20.968,0,0,1,5.077,6.169,7.394,7.394,0,0,0,7.363,16.03a7.356,7.356,0,0,1-3.345-.924c0,.031,0,.062,0,.093a7.39,7.39,0,0,0,5.925,7.242,7.4,7.4,0,0,1-3.335.127,7.393,7.393,0,0,0,6.9,5.129,14.818,14.818,0,0,1-9.173,3.162,14.98,14.98,0,0,1-1.762-.1,20.9,20.9,0,0,0,11.322,3.318c13.585,0,21.014-11.254,21.014-21.014q0-.48-.021-.956A15,15,0,0,0,38.57,8.28Z" transform="translate(-2.571 -4.817)" fill="#00aced"></path>
                                    </svg>
                                </div>
                                <input type="text" className="add-lead-form-input" onChange={(e) => setTwitter(e.target.value)} placeholder="twitter.com/johndoe"/>
                            </div>
                            <div className="col-12 col-lg-6 d-flex align-items-center mb-3">
                                <div className="add-lead-form-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15.557" height="28.915" viewBox="0 0 15.557 28.915">
                                        <path id="Icon_zocial-facebook" data-name="Icon zocial-facebook" d="M13.257,15.528V10.006h4.454V7.229a7.267,7.267,0,0,1,1.951-5.118A6.206,6.206,0,0,1,24.389,0h4.424V5.523H24.389A.974.974,0,0,0,23.61,6a1.937,1.937,0,0,0-.347,1.171V10h5.551v5.522H23.262V28.915H17.71V15.528Z" transform="translate(-13.257)" fill="#3b5998"></path>
                                    </svg>
                                </div>
                                <input type="text" className="add-lead-form-input" onChange={(e) => setFacebook(e.target.value)} placeholder="facebook.com/johndoe"/>
                            </div>
                            <div className="col-12 col-lg-6 d-flex align-items-center mb-3">
                                <div className="add-lead-form-icon">
                                    <FaInstagram size={35}/>
                                </div>
                                <input type="text" className="add-lead-form-input" onChange={(e) => setInstagram(e.target.value)} placeholder="instagram.com/johndoe"/>
                            </div>
                            <div className="col-12 col-lg-6 d-flex align-items-center mb-3">
                                <div className="add-lead-form-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="23.505" height="23.505" viewBox="0 0 23.505 23.505">
                                        <path id="Icon_awesome-linkedin-in" data-name="Icon awesome-linkedin-in" d="M5.261,23.505H.388V7.813H5.261ZM2.822,5.672A2.836,2.836,0,1,1,5.644,2.823,2.846,2.846,0,0,1,2.822,5.672ZM23.5,23.505H18.637V15.866c0-1.821-.037-4.155-2.534-4.155-2.534,0-2.922,1.978-2.922,4.024v7.77H8.314V7.813h4.674V9.953h.068a5.121,5.121,0,0,1,4.611-2.534c4.932,0,5.839,3.248,5.839,7.466v8.62Z" transform="translate(0 -0.001)" fill="#0a66c2"></path>
                                    </svg>
                                </div>
                                <input type="text" className="add-lead-form-input" onChange={(e) => setLinkedin(e.target.value)} placeholder="linkedin.com/in/johndoe"/>
                            </div>
                            </div>
                    </Modal>
                </div>
            </div>


            <InfiniteScroll
                dataLength={leads.length}
                next={fetchData}
                hasMore={currentPage < limitPage ? true : false}
                loader={
                    <div className="skeleton-wrapper">
                        <Skeleton width={'100%'} height={'100%'}/>
                    </div>
                }
                className="infinite-scroll"
            >
            {
                viewType === 'cards' ?
                <div className="grid-4 mt-3">
                    {
                        leads.map((lead, index) => (
                            <LeadCard lead={lead} order={index} leads={leads} setLeads={setLeads} key={'lead-card-'+index}/>
                        ))
                    }
                </div>
                :
                <table className="table table-responsive table-list-social-medias table-list-leads mt-3">
                    <thead>
                        <tr>
                            <th className="table-list-social-medias-radius-left">Image</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Gender</th>
                            <th>Age</th>
                            <th>Location</th>
                            <th>Organization</th>
                            <th className="table-list-social-medias-button-column">Social medias</th>
                            <th className="table-list-social-medias-radius-right"></th>
                        </tr>
                    </thead>
                    <tbody id="social-medias-table-body">
                    {
                        leads.map((lead, index) => (
                            <LeadRow lead={lead} order={index} leads={leads} setLeads={setLeads} key={'lead-row-'+index} />
                        ))
                    }
                    </tbody>
                </table>
            }
            </InfiniteScroll>
            
        </DashboardPageLayout>
        </>
    );
}