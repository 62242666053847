import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import api from '../../../services/api';
import { motion } from 'framer-motion';
import DashboardPageLayout from '../../../layouts/dashboard-page';
import Header from '../../../components/header';
import { Heading } from '@chakra-ui/react';

export default function Posts(props){

    const token = useSelector(state => state.auth.token);

    const [service, setService] = useState({});

    useEffect(() => {
        api.post(api.defaults.baseURL + '/services/list/' + token).then(res => {
            setService(res.data[0]);
        });
    }, []);

    return (
        <DashboardPageLayout title={'Campaign Management'} headerColor={'purple-header'}>
           <ServiceInfo service={service}/>
        </DashboardPageLayout>
    );
}

function ServiceInfo(props){
    const animation = {
        in: order => ({
            opacity: 1,
            x: 0,
            transition: { type: 'linear' },
        }),
        out: order => ({
            opacity: 0,
            x: 20,
            transition: { type: 'linear' },
        }),
    }

    const [selectedPlan, selectPlan] = useState(0);

    const [period, setPeriod] = useState('year');

    return (
        <>
        <Header left={
            <Heading fontWeight="700" fontFamily="poppins">Add-on</Heading>
        }/>
        <motion.div 
            initial="out"
            animate="in"
            exit="out"
            variants={animation}
            className="service-info">
            <img src={props.service.picture}/>

            <div className="d-flex align-items-center justify-content-center">
                <div className="form-group form-inline period-change">
                    <input className="period-change" type="radio" id="year" name="plan_type" checked={period == 'year'} onClick={() => setPeriod('year')}/> <label for="year" className="period-change">Yearly</label>
                </div>
                <div className="form-group form-inline period-change">
                    <input className="period-change" type="radio" id="month" name="plan_type" checked={period == 'month'} onClick={() => setPeriod('month')}/> <label for="month" className="period-change">Monthly</label>
                </div>
            </div>

            <div className="plans-buttons mt-3">
                {
                    props.service.plans &&
                    props.service.plans.map((plan, index) => (
                        <button onClick={() => selectPlan(index)} className={selectedPlan == index? 'active' : ''}>{plan.name}</button>
                    ))
                }
            </div>

            {
                props.service.plans &&
                props.service.plans.map((plan, index) => (
                    selectedPlan == index &&
                    <PlanContent plan={plan} key={'plan-content-'+index} period={period}/>
                ))
            }

            <p>{props.service.description}</p>

            <div className="cta">
                <button>Ask a Question</button>
                <button className="fill">Make an appointment</button>
            </div>
        </motion.div>
        </>
    )
}

function PlanContent({plan, period}){
    
    const animation = {
        in: order => ({
            opacity: 1,
            x: 0,
            transition: { type: 'ease' },
        }),
        out: order => ({
            opacity: 0,
            x: 20,
            transition: { type: 'ease' },
        }),
    }

    return (
        <motion.div
            initial="out"
            animate="in"
            exit="out"
            variants={animation}
        >

            <div className="text-center">
                <div className="price">
                        {
                            period == 'year' ?
                            <span>$290</span>
                            :
                            <span>$236</span>
                        }
                    /month
                </div>
            </div>

            <div className="plan-qtd">
                <div className="qtd">
                    <svg xmlns="http://www.w3.org/2000/svg" width="6.751" height="11.808" viewBox="0 0 6.751 11.808">
                        <path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward" d="M15.963,12.1,11.494,7.633a.84.84,0,0,1,0-1.192.851.851,0,0,1,1.2,0L17.752,11.5a.842.842,0,0,1,.025,1.164l-5.084,5.094a.844.844,0,1,1-1.2-1.192Z" transform="translate(-11.246 -6.196)"/>
                    </svg>
                    <span className="ml-2"><strong>15 Posts</strong> /month</span>
                </div>
                <div className="qtd">
                    <svg xmlns="http://www.w3.org/2000/svg" width="6.751" height="11.808" viewBox="0 0 6.751 11.808">
                        <path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward" d="M15.963,12.1,11.494,7.633a.84.84,0,0,1,0-1.192.851.851,0,0,1,1.2,0L17.752,11.5a.842.842,0,0,1,.025,1.164l-5.084,5.094a.844.844,0,1,1-1.2-1.192Z" transform="translate(-11.246 -6.196)"/>
                    </svg>
                    <span className="ml-2"><strong>3</strong> Social Media Accounts</span>
                </div>
            </div>
            <div className="benefits">
                {
                    plan.items &&
                    plan.items.map(benefit => (
                        <div className="benefit">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                <g id="Group_343" data-name="Group 343" transform="translate(-1277 -889)">
                                    <circle id="Ellipse_212" data-name="Ellipse 212" cx="10" cy="10" r="10" transform="translate(1277 889)" fill="#ecebf9"/>
                                    <path id="Icon_awesome-check" data-name="Icon awesome-check" d="M4.212,13.643.182,9.613a.62.62,0,0,1,0-.877l.877-.877a.62.62,0,0,1,.877,0L4.65,10.574l5.815-5.815a.62.62,0,0,1,.877,0l.877.877a.62.62,0,0,1,0,.877l-7.13,7.13A.62.62,0,0,1,4.212,13.643Z" transform="translate(1280.6 890)" fill="#9188d8"/>
                                </g>
                            </svg>
                            <span className="ml-1">{benefit.name}</span>
                        </div>
                    ))
                }
                <div className="adds">
                    <div>
                        Add the Ads <strong>+$200</strong>
                    </div>
                    <div>
                        Number of Boost up to 8 posts
                    </div>
                </div>
            </div>
        </motion.div>
    )
}