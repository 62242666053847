import React, { useEffect, useState } from "react";

import { AnimatePresence, motion } from 'framer-motion';
import useOnclickOutside from "react-cool-onclickoutside";
import ProfilePlaceholder from '../../assets/images/profile-placeholder.jpg';
import LeadsActions from '../lead-actions';

export default function Component(props) {

    const [options, setOptions] = useState(false);

    useEffect(() => {

    }, [])

    const animation = {
        in: {
            opacity: 1,
            y: 0,
            transition: { type: 'linear', delay: 0 },
            duration: 5,
        },
        out: {
            opacity: 0,
            y: 50,
            transition: { type: 'linear', delay: 0 },
            duration: 5,
        },
    }

    const optionsAnimation = {
        in: {
            opacity: 1,
            x: 0,
            transition: { type: 'linear', delay: 0 },
            duration: 5,
        },
        out: {
            opacity: 0,
            x: 20,
            transition: { type: 'linear', delay: 0 },
            duration: 5,
        },
    }

    const ref = useOnclickOutside(() => {
        setOptions(false);
    });

    return (
        <motion.div 
            initial="out"
            animate="in"
            exit="out"
            variants={animation} 
            className={'leads-page-card mb-3 mb-lg-0'}
        >
            <LeadsActions lead={props.lead} leads={props.leads} setLeads={props.setLeads}/>
            <div className="mt-3 leads-page-card-image-wrapper" id="lead-image-1630">
                <img src={props.lead.image ? props.lead.image : ProfilePlaceholder} id="lead-image-element-1630"/>
            </div>
            <h3 className="leads-page-card-title">{props.lead.name}</h3>
            <div className="text-center">
                {
                    props.lead.email &&
                    <div className="leads-page-card-info">{props.lead.email}</div>
                }
                {
                    props.lead.gender &&
                    <div className="leads-page-card-info">{props.lead.gender}</div>
                }
                {
                    props.lead.location &&
                    <div className="leads-page-card-info">{props.lead.location}</div>
                }
                {
                    props.lead.organization &&
                    <div className="leads-page-card-info">{props.lead.organization}</div>
                }
                {
                    props.lead.phone &&
                    <div className="leads-page-card-info">{props.lead.phone}</div>
                }
                <div className="mt-3">
                    {
                        props.lead.twitter &&
                        <a href={props.lead.twitter} target="_blank" className="leads-page-card-social-media-icon">
                            <i className="fab fa-twitter" aria-hidden="true"></i>
                        </a>
                    }
                    {
                        props.lead.facebook &&
                        <a href={props.lead.facebook} target="_blank" className="leads-page-card-social-media-icon">
                            <i className="fab fa-facebook" aria-hidden="true"></i>
                        </a>
                    }
                    {
                        props.lead.linkedin &&
                        <a href={props.lead.linkedin} target="_blank" className="leads-page-card-social-media-icon">
                            <i className="fab fa-linkedin" aria-hidden="true"></i>
                        </a>
                    }
                    {
                        props.lead.url &&
                        <a href={props.lead.url} target="_blank" className="leads-page-card-social-media-icon">
                            <i className="fas fa-globe"></i>
                        </a>
                    }
                </div>
            </div>
        </motion.div>
    );
}