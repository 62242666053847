import React, { useState, useEffect } from 'react';
import 'dhtmlx-scheduler';
import 'dhtmlx-scheduler/codebase/dhtmlxscheduler_material.css';

import api from '../../services/api';
import Skeleton from 'react-loading-skeleton';

import { useSelector } from 'react-redux';
 
export default function SchedulerComponent(props) {
    const Scheduler = window.Scheduler;

    const token = useSelector(state => state.auth.token);

    useEffect(() => {
        var scheduler  = Scheduler.getSchedulerInstance();
        scheduler.skin = 'material';
        scheduler.config.header = [
            'date',
            'prev',
            'today',
            'next'
        ];
        scheduler.config.show_loading = true;
        scheduler.setLoadMode("month");
        scheduler.config.details_on_dblclick = true;
        if(props.readOnly)
            scheduler.config.dblclick_create = false;

        scheduler.init(props.id, new Date(), "month");
        //scheduler.clearAll();
        //scheduler.parse(events);

        function block_readonly(id) {
            if (!id) return true;
            return !this.getEvent(id).readonly;
        }
        scheduler.attachEvent("onBeforeDrag", block_readonly)
        scheduler.attachEvent("onClick", function (id, e){
            const event = scheduler.getEvent(id);
            if(props.openParam)
                window.open(event[props.openParam])

            if(props.openAction)
                props.openAction(event)
       })

        if(props.readOnly)
            scheduler.attachEvent("onDblClick", function(){ return false; });

        scheduler.load(api.defaults.baseURL + props.endPoint + token);
        //var dp = new dataProcessor(api.defaults.baseUrl + '/calendars/list/' + token);
        //dp.init(scheduler);
        //dp.setTransactionMode("POST", false);
    })
 
    return (
        <div className='scheduler-container' style={{width: '100%', borderRadius: 10, overflow: 'hidden'}}>
            <div
                style={ { width: '100%', height: '88vh' } }
                id={props.id}
            >
                <Skeleton width={'100%'} height={'100%'}/>
            </div>
        </div>
    );
}