import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import DashboardPageLayout from '../../../layouts/dashboard-page';
import api from '../../../services/api';
import Header from '../../../components/header';
import { useSelector } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';
import { Heading } from '@chakra-ui/react';
import Modal from '../../../components/modal';

export default function View(props) {
    const token = useSelector(state => state.auth.token);

    const [socialMedias, setSocialMedias] = useState([]);

    const [addModal, setAddModal] = useState(false);
    const toggle = () => {
        setNewEmail('');
        setNewName('');
        setNewType('4');
        setAddModal(!addModal);
    }

    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [selected, setSelected] = useState(false);

    const [newName, setNewName] = useState();
    const [newEmail, setNewEmail] = useState();
    const [newType, setNewType] = useState('4');

    useEffect(() => {
        api.get(api.defaults.baseURL + '/client_user/list/' + token).then(res => {
            if(res){
                setUsers(res.data);
            }
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        if(selected){
            setNewName(selected.name);
            setNewEmail(selected.email);
            setNewType(selected.type);
        } else {
            setNewEmail('');
            setNewName('');
            setNewType('4');
        }
    }, [selected]);

    function remove(id){
        setUsers(users.filter(user => user.id != id));
        api.post(api.defaults.baseURL + '/client_user/delete/' + token, { id });
    }

    function submit(e, _callback){
        e.preventDefault();
        api.post(api.defaults.baseURL + '/client_user/create/' + token, {
            name: newName,
            email: newEmail,
            type: newType,
        }).then(res => {
            if (res.data != 0) {
                setUsers([...users, res.data]);
                setNewName('');
                setNewEmail('');
                setNewType('1');
            } else {
                toast.error('Not able to create this user.')
            }

            _callback();
        });
    }

    const animation = {
        in: index => ({
            opacity: 1,
            y: 0,
            transition: { type: 'linear', delay: index * 0.1 },
        }),
        out: index => ({
            opacity: 0,
            y: 20,
            transition: { type: 'linear', delay: index * 0.05 },
        }),
    }

    return (
        !loading &&
        <>
        <Header left={
            <Heading fontWeight="700" fontFamily="poppins">Social Medias</Heading>
        }/>
        <DashboardPageLayout 
            title={'Users'} 
            subtitle={'Manage your users'} 
            headerColor={'purple-header'} 
            subContent={
                <Modal
                    buttonClass={'dashboard-button primary-button mb-3'}
                    buttonText={'New user'}
                    toggle={toggle}
                    title={'New user'}
                    icon={
                        <div></div>
                    }
                    submit={submit}
                    form
                >
                    <div className="form-inline mb-4">
                        <label className="user-label">Name</label>
                        <input type="text" className="add-lead-form-input mr-0" style={{width: '100%'}} placeholder="John Doe" value={newName} onChange={(e) => setNewName(e.target.value)}/>
                    </div>

                    <div className="form-inline mb-4">
                        <label className="user-label">Email</label>
                        <input type="text" className="add-lead-form-input mr-0" style={{width: '100%'}} placeholder="john@c180.com.br" value={newEmail} onChange={(e) => setNewEmail(e.target.value)}/>
                    </div>

                    <div className="form-inline mb-4">
                        <label className="user-label">Type</label>
                        <select className="add-lead-form-input" style={{width: '100%'}} placeholder="linkedin.com/in/johndoe" value={newType} onChange={(e) => setNewType(e.target.value)}>
                            <option value="4">Admin</option>
                            <option value="5">Guest</option>
                        </select>
                    </div>
                </Modal>
            }
        >
            <table className="table table-list-social-medias">
                <thead>
                    <tr>
                        <th className="table-list-social-medias-radius-left" style={{width: '20%', whiteSpace: 'nowrap'}}>Name</th>
                        <th style={{width: '20%', whiteSpace: 'nowrap'}}>Email</th>
                        <th className="text-center" style={{width: '10%', whiteSpace: 'nowrap'}}>Type</th>
                        <th className="table-list-social-medias-button-column table-list-social-medias-radius-right"></th>
                    </tr>
                </thead>
                <tbody id="social-medias-table-body">
                    <AnimatePresence>
                        {
                            users.map((user, index) => (
                                <motion.tr 
                                    initial="out"
                                    animate="in"
                                    exit="out"
                                    variants={animation} 
                                    custom={index}
                                    key={`social-medias-table-item-`+index}
                                >
                                    <td>
                                        {user.name}
                                    </td>
                                    <td>{user.email}</td>
                                    <td className="text-center">
                                        {{
                                            '4': <span>Admin</span>,
                                            '5': <span>Guest</span>,
                                        }[user.type] || <div>Uknown type</div>}
                                    </td>
                                    <td className="text-right">
                                        <EditModal user={user} users={users} setUsers={setUsers}/>
                                        <button className="dashboard-button danger-button" onClick={() => remove(user.id)}>Delete</button>
                                    </td>
                                </motion.tr>
                            ))
                        }
                    </AnimatePresence>
                </tbody>
            </table>
        </DashboardPageLayout>
        </>
    );
}

function EditModal(props){
    const token = useSelector(state => state.auth.token);

    const [newName, setNewName] = useState(props.user.name);
    const [newEmail, setNewEmail] = useState(props.user.email);
    const [newType, setNewType] = useState(props.user.type);

    const [loading, setLoading] = useState(false);

    function submit(e, _callback){
        e.preventDefault();
        setLoading(true);
        api.post(api.defaults.baseURL + '/client_user/update/' + token, {
            id: props.user.id,
            name: newName,
            email: newEmail,
            type: newType,
        }).then(res => {
            if (res.data != 0) {
                var aux = props.users;

                for(let i = 0; i < aux.length; i++){
                    if(aux[i] == props.user.id){
                        aux.name = newName;
                        aux.email = newEmail;
                        aux.type = newType;
                    }
                }

                props.setUsers([...aux]);
            }
            setLoading(false);
            _callback();
        });
    }

    return (
        <Modal
            buttonClass={'dashboard-button success-button mr-1'}
            buttonText={'Edit'}
            title={'Edit user'}
            icon={
                <div></div>
            }
            submit={submit}
            form
        >
            <div className="form-inline mb-4">
                <label className="user-label">Name</label>
                <input type="text" className="add-lead-form-input mr-0" style={{width: '100%'}} placeholder="John Doe" value={newName} onChange={(e) => setNewName(e.target.value)}/>
            </div>

            <div className="form-inline mb-4">
                <label className="user-label">Email</label>
                <input type="text" className="add-lead-form-input mr-0" style={{width: '100%'}} placeholder="john@c180.com.br" value={newEmail} onChange={(e) => setNewEmail(e.target.value)}/>
            </div>

            <div className="form-inline mb-4">
                <label className="user-label">Type</label>
                <select className="add-lead-form-input" style={{width: '100%'}} placeholder="linkedin.com/in/johndoe" defaultValue={newType} onChange={(e) => setNewType(e.target.value)}>
                    <option value="4">Admin</option>
                    <option value="5">Guest</option>
                </select>
            </div>
        </Modal>
    )

}